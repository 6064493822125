/* eslint-disable max-len */
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import React from 'react';

export const ANNOUNCEMENTS_LS_PREFIX = 'choice-announcement';

export const ANNOUNCEMENTS = [
  {
    id: 'welcome-to-inmobi_sept-2023',
    end: '2023-11-01T00:00:00.000Z',
    getModalInfo: actions => ({
      children: (
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={3}>
            Welcome to InMobi CMP.
          </Typography.Title>
          <Typography.Text>
            For instructions on getting started, see the
            {' '}
            <a
              href="https://eng3e.seismic.com/i/tBrtHsIaiEyD2zQCiufPe4m185uAWajZmwPLUSSIGNCoj4kiddqALEWMeKpUIgNn2i9hnFA6fm2cVqm5OCIH7WpFPi6uWzA0rX8nePLUSSIGN0Voj7CXgk20yMTRNsNZPLUSSIGN___vGQq1LBnwQTJ"
              target="_blank"
              rel="noopener noreferrer"
            >
              User Guide
            </a>
            .
          </Typography.Text>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            <Button onClick={actions.handleDismiss} type="primary">Got it!</Button>
          </div>
        </div>
      ),
      footer: null,
    })
  }
];
