import React from 'react';
import Button from 'antd/es/button';
import { connect } from 'react-redux';
import withPcode from '../../components/hoc/withPcode';
import Result from '../../components/ui/result';

export function NotFoundPage() {
  return (
    <Result
      status="404"
      title="Page not found"
      subTitle={
        `If you feel you have reached this page in error,
        please try again or contact your InMobi representative.`
      }
      extra={
        <Button data-testid="NotFound" href="/protect" type="primary">Back Home</Button>
      }
    />
  );
}

const MapStateToProps = ({ app }) => ({ app });

export default connect(MapStateToProps)(withPcode(NotFoundPage));
