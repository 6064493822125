import { Select, Space } from 'antd/es';
import React, { useState, useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import classNames from 'classnames';
import Style from '../premiumReports.module.scss';
import { getGraphOptions } from '../utils';

// @flow
interface IGraphsCardProps {
  graphHeader: String;
  graphOptions?: Record<string, any>;
  type: "bar" | "line" | "pie";
  graphData: any;
  labels: Record<string, string>;
}

const GraphsCard = ({
  graphHeader, graphOptions, type,
  graphData, labels, granularity = 'none', isFullScreen = false
}: IGraphsCardProps) => {
  const [graphType, setGraphType] = useState(type);
  const options = getGraphOptions(graphType, graphData, granularity, labels);

  const dataPresent = graphData && graphData.length > 0;
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update({ chart: { width: isFullScreen ? 1200 : null, } });
    }
  }, [isFullScreen, graphData]);

  return (
    <div className={Style.graphCard}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space className={Style.header}>
          <p className={Style.subTitle}>{graphHeader}</p>
          {
            graphOptions && (
              <Select
                required
                options={graphOptions}
                value={graphType}
                onChange={val => setGraphType(val)}
                style={{ width: '80px' }}
              />
            )
          }
        </Space>
        <div className={graphType === 'pie' ? classNames(Style.content, Style.pieChart) : Style.content}>
          {dataPresent ? (
            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
          ) : (
            <Space direction="vertical" className={Style.noData}>
              <p> No Data Available </p>
            </Space>
          )}
        </div>
      </Space>
    </div>
  );
};

export default GraphsCard;
