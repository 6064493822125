import { Col, Row, Space, DatePicker, Divider } from 'antd/es';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MetricsCardItem from './metricsCardItem';
import Style from '../premiumReports.module.scss';
import AnalyticsViewer from './analyticsViewer';
import { useAnalyticsMetaData, useSummaryData } from '../../../data/hooks/analytics';
import {
  customDateFormat,
  getCompairionsDefaultDateRange,
  getCurrentDefaultDateRange,
  getDateRanges,
  getMetricFieldNames,
  getSummaryMetrics,
  shouldDateBeDisabled
} from '../utils';
import Loader from '../../../components/ui/loader';
import { DATE_FORMAT, DATE_RANGE } from '../constants';
import TEXT from '../text';

// @flow
dayjs.extend(utc);
const { RangePicker } = DatePicker;

interface IDashboardProps {
  propertyId: Array<string>;
  pCode: string;
}

const Dashboard = ({ propertyId, pCode }: IDashboardProps) => {
  const [summaryDate, setSummaryDate] = React.useState(
    getCurrentDefaultDateRange().map(d => d.format(DATE_FORMAT))
  );
  const [comparisonDate, setComparisonDate] = React.useState(
    getCompairionsDefaultDateRange().map(d => d.format(DATE_FORMAT))
  );

  const { isLoading: isMetaDataLoading, isError, data: metaData } = useAnalyticsMetaData();
  const metricsArray = isMetaDataLoading || isError ? [] : getMetricFieldNames(metaData);

  const { isLoading: isSummaryDataLoading, data: summaryData } = useSummaryData({
    summaryDateRange: summaryDate,
    comparisonDateRange: comparisonDate,
    propertyId,
    metricFields: metricsArray,
    pCode
  });

  const summaryMetrics = summaryData
    ? getSummaryMetrics(metaData, summaryData.filter(({ fieldName }) => fieldName !== 'totalTraffic')) : [];

  const handleDateChange = (dates, setStateFunc) => {
    if (!dates) return;

    const datesArr = dates.map(date => dayjs(date).format(DATE_FORMAT));
    setStateFunc(datesArr);
  };

  return (
    <Space direction="vertical" size={10} style={{ display: 'flex' }}>
      <section className="summarySection">
        <Row gutter={[0, 5]}>
          <Col span={24}>
            <Space direction="horizontal" size="middle">
              <p className={Style.title}>{TEXT.SUMMARY}</p>
              <RangePicker
                onChange={dates => handleDateChange(dates, setSummaryDate)}
                format={customDateFormat}
                disabledDate={d => shouldDateBeDisabled(d, DATE_RANGE)}
                defaultValue={getCurrentDefaultDateRange()}
                ranges={getDateRanges()}
                allowClear={false}
              />
              <p>{TEXT.COMPARE}</p>
              <RangePicker
                onChange={dates => handleDateChange(dates, setComparisonDate)}
                format={customDateFormat}
                disabledDate={d => shouldDateBeDisabled(d, DATE_RANGE)}
                defaultValue={getCompairionsDefaultDateRange()}
                ranges={getDateRanges()}
                allowClear={false}
              />
            </Space>
          </Col>
          <Col span={24}>
            {isSummaryDataLoading ? (
              <Loader />
            ) : (
              <Row justify="center" gutter={[32, 0]} className={Style.metricsCard}>
                {summaryMetrics.map(metric => (
                  <Col span={4}>
                    <MetricsCardItem
                      metricName={metric.metricName}
                      metricValue={metric.metricValue}
                      comparisonPercentage={metric.comparisonPercentage}
                      comparisonStatus={metric.comparisonStatus}
                      metricValueType={metric.valueType}
                      iconImage={metric.iconImage}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Row>
      </section>
      <Divider />
      <AnalyticsViewer propertyId={propertyId} pCode={pCode} />
    </Space>
  );
};

export default Dashboard;
