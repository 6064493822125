import { Button, Checkbox, Col, Divider, Drawer, Row, Select, Space } from 'antd/es';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Style from '../premiumReports.module.scss';
import { COUNTRY_CODES } from '../../../utils/constants';
import {
  DEFAULT_APPLIED_FILTERS,
  DEVICE_TYPE_OPTIONS,
  FIELD_NAMES,
  OS_OPTIONS,
  PAGE_OPTIONS,
  REGULATION_OPTIONS
} from '../constants';
import { getFilterTags } from '../utils';
import TEXT from '../text';

// @flow
interface IFilterDrawerProps {
  filterOpen: boolean;
  onClose: Function;
  onApplyFilter: Function;
  savedFilters: Object;
}

const FilterDrawer = ({ filterOpen, onClose, onApplyFilter, savedFilters }: IFilterDrawerProps) => {
  const [filterData, setFilterData] = useState(DEFAULT_APPLIED_FILTERS);

  const handleFilterChange = (fieldName, val) => {
    setFilterData({ ...filterData, [fieldName]: val });
  };

  const deleteFilterItem = (fieldName, item) => {
    const updatedFilters = filterData[fieldName].filter(filterItem => filterItem !== item);
    setFilterData({ ...filterData, [fieldName]: updatedFilters });
  };

  useEffect(() => {
    if (savedFilters) {
      setFilterData(savedFilters);
    }
  }, [savedFilters]);

  const removeAllFilters = () => {
    setFilterData(DEFAULT_APPLIED_FILTERS);
  };

  const handleCancel = () => {
    setFilterData(savedFilters);
    onClose();
  };

  return (
    <Drawer
      placement="top"
      closable={false}
      onClose={onClose}
      open={filterOpen}
      maskClosable
      height={500}
      className={Style.filterDrawer}
    >
      <Row className={Style.container} gutter={62}>
        <Col span={5} className={Style.header}>
          <Space direction="vertical" size="large">
            <p>{TEXT.FILTER}</p>
            <p className={Style.title}>{TEXT.DASHBOARD}</p>
          </Space>
        </Col>
        <Col span={19} className={Style.content}>
          <Row>
            <Col span={24}>
              <Space className={Style.appliedFilters}>
                <Space style={{ flexWrap: 'wrap' }}>{getFilterTags(filterData, deleteFilterItem)}</Space>
                <Button onClick={removeAllFilters} className={classNames(Style.button, Style.removeBtn)}>
                  {TEXT.REMOVE_ALL}
                </Button>
              </Space>
            </Col>
          </Row>
          <Row className={Style.filters}>
            <Col span={24}>
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <p className={Style.subTitle}>{TEXT.FILTERS}</p>
                <Row>
                  <Col span={3}>
                    <Space direction="vertical">
                      <p className={Style.filterLabel}>{TEXT.REGULATION}</p>
                      <Checkbox.Group
                        value={filterData.regulation}
                        className={Style.checkboxItem}
                        options={REGULATION_OPTIONS}
                        onChange={val => handleFilterChange(FIELD_NAMES.REGULATION, val)}
                      />
                    </Space>
                  </Col>
                  <Col span={3}>
                    <Space direction="vertical">
                      <p className={Style.filterLabel}>{TEXT.PAGES}</p>
                      <Checkbox.Group
                        value={filterData.pageFormat}
                        className={Style.checkboxItem}
                        options={PAGE_OPTIONS}
                        onChange={val => handleFilterChange(FIELD_NAMES.PAGE_FORMAT, val)}
                      />
                    </Space>
                  </Col>
                  <Col span={3}>
                    <Space direction="vertical">
                      <p className={Style.filterLabel}>{TEXT.DEVICE_TYPE}</p>
                      <Checkbox.Group
                        value={filterData.deviceType}
                        className={Style.checkboxItem}
                        options={DEVICE_TYPE_OPTIONS}
                        onChange={val => handleFilterChange(FIELD_NAMES.DEVICE_TYPE, val)}
                      />
                    </Space>
                  </Col>
                  <Col span={3}>
                    <Space direction="vertical">
                      <p className={Style.filterLabel}>{TEXT.OS}</p>
                      <Checkbox.Group
                        value={filterData.os}
                        className={Style.checkboxItem}
                        options={OS_OPTIONS}
                        onChange={val => handleFilterChange(FIELD_NAMES.OS, val)}
                      />
                    </Space>
                  </Col>
                  <Col span={4}>
                    <Space direction="vertical">
                      <p className={Style.filterLabel}>{TEXT.COUNTRIES}</p>
                      <Select
                        value={filterData.country}
                        mode="multiple"
                        style={{ width: '330px' }}
                        options={COUNTRY_CODES}
                        allowClear
                        onChange={val => handleFilterChange(FIELD_NAMES.COUNTRY, val)}
                        maxTagCount="responsive"
                        optionFilterProp="label"
                      />
                    </Space>
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider className={Style.footerDivider} />
              <Space className={Style.actionButtons}>
                <Button onClick={handleCancel}>{TEXT.CANCEL}</Button>
                <Button type="primary" onClick={() => onApplyFilter(filterData)}>
                  {TEXT.APPLY_FILTER}
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
};

export default FilterDrawer;
