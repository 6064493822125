/* eslint-disable import/prefer-default-export */
export const ANALYTICS = {
  GET_ALL: {
    FETCH: 'ANALYTICS_GET_ALL_FETCH',
    PENDING: 'ANALYTICS_GET_ALL_PENDING',
    FULFILLED: 'ANALYTICS_GET_ALL_FULFILLED',
    REJECTED: 'ANALYTICS_GET_ALL_REJECTED',
  },
  GET_BY_URL: {
    FETCH: 'ANALYTICS_GET_BY_URL_FETCH',
    PENDING: 'ANALYTICS_GET_BY_URL_PENDING',
    FULFILLED: 'ANALYTICS_GET_BY_URL_FULFILLED',
    REJECTED: 'ANALYTICS_GET_BY_URL_REJECTED',
    CLEANED: 'ANALYTICS_GET_BY_URL_CLEANED',
  },
  GET_AB_TEST_BY_SITE: {
    FETCH: 'GET_AB_TEST_BY_SITE_FETCH',
    PENDING: 'GET_AB_TEST_BY_SITE_PENDING',
    FULFILLED: 'GET_AB_TEST_BY_SITE_FULFILLED',
    REJECTED: 'GET_AB_TEST_BY_SITE_REJECTED'
  },
  GET_BY_APP: {
    FETCH: 'ANALYTICS_GET_BY_APP_FETCH',
    PENDING: 'ANALYTICS_GET_BY_APP_PENDING',
    FULFILLED: 'ANALYTICS_GET_BY_APP_FULFILLED',
    REJECTED: 'ANALYTICS_GET_BY_APP_REJECTED',
  }
};

export const APP = {
  GET_INFO: {
    FETCH: 'APP_GET_INFO_FETCH',
    PENDING: 'APP_GET_INFO_PENDING',
    FULFILLED: 'APP_GET_INFO_FULFILLED',
    REJECTED: 'APP_GET_INFO_REJECTED'
  },
  EVENT_STREAM: {
    FETCH: 'APP_EVENT_STREAM_FETCH',
    PENDING: 'APP_EVENT_STREAM_PENDING',
    SUBSCRIBED: 'APP_EVENT_STREAM_SUBSCRIBED',
    REJECTED: 'APP_EVENT_STREAM_REJECTED',
    INCOMING_MESSAGE: 'APP_EVENT_STREAM_INCOMING_MESSAGE'
  },
  CURRENT_USER: { SET: 'APP_CURRENT_USER_SET' },
  ACCOUNT: { SET: 'APP_ACCOUNT_SET', CLEAR: 'APP_ACCOUNT_CLEAR' },
  CI_PERMISSION: { SET: 'APP_CI_PERMISSION_SET' },
  PERMISSION: { SET: 'APP_PERMISSION_SET' },
  PRIVACYREGULATIONS: { SET: 'APP_PRIVACYREGULATIONS_SET' },
};

export const ALERTS = {
  ADD: 'ALERTS_ADD',
  REMOVE: 'ALERTS_REMOVE',
  CLEAR: 'ALERTS_CLEAR'
};

export const CMP_VERSIONS_V2 = {
  GET: {
    FETCH: 'CMP_VERSIONS_V2_GET_FETCH',
    PENDING: 'CMP_VERSIONS_V2_GET_PENDING',
    FULFILLED: 'CMP_VERSIONS_V2_GET_FULFILLED',
    REJECTED: 'CMP_VERSIONS_V2_GET_REJECTED',
  }
};

export const SITES = {
  EDIT: {
    FETCH: 'SITES_EDIT_FETCH',
    PENDING: 'SITES_EDIT_PENDING',
    FULFILLED: 'SITES_EDIT_FULFILLED',
    REJECTED: 'SITES_EDIT_REJECTED'
  },
  DELETE: {
    FETCH: 'SITES_DELETE_FETCH',
    PENDING: 'SITES_DELETE_PENDING',
    FULFILLED: 'SITES_DELETE_FULFILLED',
    REJECTED: 'SITES_DELETE_REJECTED'
  },
  CREATE: {
    FETCH: 'SITES_CREATE_FETCH',
    PENDING: 'SITES_CREATE_PENDING',
    FULFILLED: 'SITES_CREATE_FULFILLED',
    REJECTED: 'SITES_CREATE_REJECTED'
  },
  GET_BY_ID: {
    FETCH: 'SITES_GET_BY_ID_FETCH',
    PENDING: 'SITES_GET_BY_ID_PENDING',
    FULFILLED: 'SITES_GET_BY_ID_FULFILLED',
    REJECTED: 'SITES_GET_BY_ID_REJECTED',
    CLEANED: 'SITES_GET_BY_ID_CLEANED',
  },
  GET_ALL: {
    FETCH: 'SITES_GET_ALL_FETCH',
    PENDING: 'SITES_GET_ALL_PENDING',
    FULFILLED: 'SITES_GET_ALL_FULFILLED',
    REJECTED: 'SITES_GET_ALL_REJECTED'
  },
  INIT_DATA: {
    FETCH: 'SITES_INIT_DATA_FETCH',
    PENDING: 'SITES_INIT_DATA_PENDING',
    FULFILLED: 'SITES_INIT_DATA_FULFILLED',
    REJECTED: 'SITES_INIT_DATA_REJECTED',
    USEFILTERDATA: 'SITES_USERFILTERDATA'
  },
  PERMISSIONS: {
    GRANT: 'SITES.PERMISSIONS.GRANT',
    DENY: 'SITES.PERMISSIONS.DENY'
  },
  GENERATE_NON_IAB_LIST: {
    FETCH: 'SITES_GENERATE_NON_IAB_LIST_FETCH',
    PENDING: 'SITES_GENERATE_NON_IAB_LIST_PENDING',
    FULFILLED: 'SITES_GENERATE_NON_IAB_LIST_FULFILLED',
    REJECTED: 'SITES_GENERATE_NON_IAB_LIST_REJECTED'
  },
  GET_AMP_TAG: {
    FETCH: 'SITES_GET_AMP_FETCH',
    PENDING: 'SITES_GET_AMP_PENDING',
    FULFILLED: 'SITES_GET_AMP_FULLFILLED',
    REJECTED: 'SITES_GET_AMP_REJECTED',
    CLEANED: 'SITES_GET_AMP_CLEANED',
  },
  GET_SUPPORT_CUSTOM_VENDORS: {
    FETCH: 'GET_SUPPORT_CUSTOM_VENDORS_FETCH',
    PENDING: 'GET_SUPPORT_CUSTOM_VENDORS_PENDING',
    FULFILLED: 'GET_SUPPORT_CUSTOM_VENDORS_FULFILLED',
    REJECTED: 'GET_SUPPORT_CUSTOM_VENDORS_REJECTED'
  }
};

export const THEMES = {
  GET_ALL: {
    FETCH: 'THEMES_GET_ALL_FETCH',
    PENDING: 'THEMES_GET_ALL_PENDING',
    FULFILLED: 'THEMES_GET_ALL_FULFILLED',
    REJECTED: 'THEMES_GET_ALL_REJECTED',
    USEFILTERDATA: 'THEMES_USERFILTERDATA'
  },
  EDIT: {
    FETCH: 'THEMES_EDIT_FETCH',
    PENDING: 'THEMES_EDIT_PENDING',
    FULFILLED: 'THEMES_EDIT_FULFILLED',
    REJECTED: 'THEMES_EDIT_REJECTED',
  },
  CREATE: {
    FETCH: 'THEMES_CREATE_FETCH',
    PENDING: 'THEMES_CREATE_PENDING',
    FULFILLED: 'THEMES_CREATE_FULFILLED',
    REJECTED: 'THEMES_CREATE_REJECTED',
  },
  GET_BY_ID: {
    FETCH: 'THEMES_GET_BY_ID_FETCH',
    PENDING: 'THEMES_GET_BY_ID_PENDING',
    FULFILLED: 'THEMES_GET_BY_ID_FULFILLED',
    REJECTED: 'THEMES_GET_BY_ID_REJECTED',
    CLEANED: 'THEMES_GET_BY_ID_CLEANED',
  },
  DELETE: {
    FETCH: 'THEMES_DELETE_FETCH',
    PENDING: 'THEMES_DELETE_PENDING',
    FULFILLED: 'THEMES_DELETE_FULFILLED',
    REJECTED: 'THEMES_DELETE_REJECTED',
    CLEANED: 'THEMES_DELETE_CLEANED',
  }
};

export const AUDIT_LOGS = {
  PREPARE_DOWNLOAD: {
    FETCH: 'PREPARE_DOWNLOAD_FETCH',
    PENDING: 'PREPARE_DOWNLOAD_PENDING',
    FULFILLED: 'PREPARE_DOWNLOAD_FULFILLED',
    REJECTED: 'PREPARE_DOWNLOAD_REJECTED',
  },
  DOWNLOAD: {
    FETCH: 'AUDIT_LOGS_DOWNLOAD_FETCH',
    PENDING: 'AUDIT_LOGS_DOWNLOAD_PENDING',
    FULFILLED: 'AUDIT_LOGS_DOWNLOAD_FULFILLED',
    REJECTED: 'AUDIT_LOGS_DOWNLOAD_REJECTED',
  },
  DOWNLOAD_ALL: {
    FETCH: 'AUDIT_LOGS_DOWNLOAD_ALL_FETCH',
    PENDING: 'AUDIT_LOGS_DOWNLOAD_ALL_PENDING',
    FULFILLED: 'AUDIT_LOGS_DOWNLOAD_ALL_FULFILLED',
    REJECTED: 'AUDIT_LOGS_DOWNLOAD_ALL_REJECTED',
  },
  STATUS: {
    FETCH: 'AUDIT_LOGS_STATUS_FETCH',
    FULFILLED: 'AUDIT_LOGS_STATUS_FULFILLED',
    TOGGLE_SUCCESS_MODAL: 'AUDIT_LOGS_STATUS_TOGGLE_SUCCESS_MODAL',
    TOGGLE_FAILED_MODAL: 'AUDIT_LOGS_STATUS_TOGGLE_FAILED_MODAL',
    HIDE_MODALS: 'AUDIT_LOGS_STATUS_HIDE_MODALS',
    REMOVE_OLDER_LOGS: 'AUDIT_LOGS_STATUS_REMOVE_OLDER_LOGS'
  }
};

export const BULK_UPDATES = {
  STATUS: {
    FETCH: 'BULK_UPDATES_STATUS_FETCH',
    PENDING: 'BULK_UPDATES_STATUS_PENDING',
    FULFILLED: 'BULK_UPDATES_STATUS_FULFILLED',
    REJECTED: 'BULK_UPDATES_STATUS_REJECTED',
    INQUEUE: 'BULK_UPDATES_STATUS_INQUEUE',
  },
  UPDATE: {
    FETCH: 'BULK_UPDATES_UPDATE_FETCH',
    PENDING: 'BULK_UPDATES_UPDATE_PENDING',
    FULFILLED: 'BULK_UPDATES_UPDATE_FULFILLED',
    REJECTED: 'BULK_UPDATES_UPDATE_REJECTED',
    RESET: 'BULK_UPDATES_UPDATE_RESET',
    INQUEUE: 'BULK_UPDATES_STATUS_INQUEUE',
  },
};

export const AUTH = {
  INIT: 'AUTH_INIT',
  STATE_CHANGED: 'AUTH_STATE_CHANGED',
  ASYNC_STORE_START: 'AUTH_ASYNC_STORE_START',
  ASYNC_STORE_END: 'AUTH_ASYNC_STORE_END',
};

export const BUILD = {
  SITE: {
    FETCH: 'BUILD_SITE_FETCH',
    FULFILLED: 'BUILD_SITE_FULFILLED',
    REJECTED: 'BUILD_SITE_REJECTED',
  },

  PUB_VENDOR: {
    FETCH: 'BUILD_PUB_VENDOR_FETCH',
    PENDING: 'BUILD_PUB_VENDOR_PENDING',
    FULFILLED: 'BUILD_PUB_VENDOR_FULFILLED',
    REJECTED: 'BUILD_PUB_VENDOR_REJECTED',
  },
  NON_IAB_VENDOR: {
    FETCH: 'NON_IAB_VENDOR_FETCH',
    PENDING: 'NON_IAB_VENDOR_PENDING',
  },
  THEME: {
    FETCH: 'BUILD_THEME_FETCH',
    PENDING: 'BUILD_THEME_PENDING',
    FULFILLED: 'BUILD_THEME_FULFILLED',
    REJECTED: 'BUILD_THEME_REJECTED',
  },
  BATCH_UPDATE_CHOICEJS: {
    FETCH: 'BATCH_UPDATE_CHOICEJS_FETCH',
    PENDING: 'BATCH_UPDATE_CHOICEJS_PENDING',
    FULFILLED: 'BATCH_UPDATE_CHOICEJS_FULFILLED',
    REJECTED: 'BATCH_UPDATE_CHOICEJS_REJECTED',
  },
  APPS: {
    FETCH: 'BUILD_APPS_FETCH',
    PENDING: 'BUILD_APPS_PENDING',
    FULFILLED: 'BUILD_APPS_FULFILLED',
    REJECTED: 'BUILD_APPS_REJECTED',
  },
};

export const VENDORS = {
  // This toggle_block must be removed after v2 is deprecated
  TOGGLE_BLOCK: {
    FETCH: 'VENDORS_TOGGLE_BLOCK_FETCH',
    PENDING: 'VENDORS_TOGGLE_BLOCK_PENDING',
    FULFILLED: 'VENDORS_TOGGLE_BLOCK_FULFILLED',
    REJECTED: 'VENDORS_TOGGLE_BLOCK_REJECTED',
  },
  V3_TOGGLE_BLOCK: {
    FETCH: 'VENDORS_V3_TOGGLE_BLOCK_FETCH',
    PENDING: 'VENDORS_V3_TOGGLE_BLOCK_PENDING',
    FULFILLED: 'VENDORS_V3_TOGGLE_BLOCK_FULFILLED',
    REJECTED: 'VENDORS_V3_TOGGLE_BLOCK_REJECTED',
  },
  TOGGLE_GOOGLE_WHITE_LIST: {
    FETCH: 'VENDORS_TOGGLE_GOOGLE_WHITE_LIST_FETCH',
    PENDING: 'VENDORS_TOGGLE_GOOGLE_WHITE_LIST_PENDING',
    FULFILLED: 'VENDORS_TOGGLE_GOOGLE_WHITE_LIST_FULFILLED',
    REJECTED: 'VENDORS_TOGGLE_GOOGLE_WHITE_LIST_REJECTED',
  },
  V2: {
    GET_ALL: {
      FETCH: 'VENDORS_V2_FETCH',
      PENDING: 'VENDORS_V2_PENDING',
      FULFILLED: 'VENDORS_V2_FULFILLED',
      REJECTED: 'VENDORS_V2_REJECTED'
    }
  },
  V3: {
    GET_ALL: {
      FETCH: 'VENDORS_V3_FETCH',
      PENDING: 'VENDORS_V3_PENDING',
      FULFILLED: 'VENDORS_V3_FULFILLED',
      REJECTED: 'VENDORS_V3_REJECTED'
    }
  },
  V3_TOGGLE_GOOGLE_WHITE_LIST: {
    FETCH: 'V3_VENDORS_TOGGLE_GOOGLE_WHITE_LIST_FETCH',
    PENDING: 'V3_VENDORS_TOGGLE_GOOGLE_WHITE_LIST_PENDING',
    FULFILLED: 'V3_VENDORS_TOGGLE_GOOGLE_WHITE_LIST_FULFILLED',
    REJECTED: 'V3_VENDORS_TOGGLE_GOOGLE_WHITE_LIST_REJECTED',
  },
};

export const NON_IABVENDORS = {
  CREATE: {
    FETCH: 'NONIABVENDORS_CREATE_FETCH',
    PENDING: 'NONIABVENDORS_CREATE_PENDING',
    FULFILLED: 'NONIABVENDORS_CREATE_FULFILLED',
    REJECTED: 'NONIABVENDORS_CREATE_REJECTED',
  },
  GET_ALL: {
    FETCH: 'NONIABVENDORS_GET_ALL_FETCH',
    PENDING: 'NONIABVENDORS_GET_ALL_PENDING',
    FULFILLED: 'NONIABVENDORS_GET_ALL_FULFILLED',
    REJECTED: 'NONIABVENDORS_GET_ALL_REJECTED',
  },
  DELETE: {
    FETCH: 'NONIABVENDORS_DELETE_FETCH',
    PENDING: 'NONIABVENDORS_DELETE_PENDING',
    FULFILLED: 'NONIABVENDORS_DELETE_FULFILLED',
    REJECTED: 'NONIABVENDORS_DELETE_REJEFTED'
  },
  EDIT: {
    FETCH: 'NONIABVENDORS_EDIT_FETCH',
    PENDING: 'NONIABVENDORS_EDIT_PENDING',
    FULFILLED: 'NONIABVENDORS_EDIT_FULFILLED',
    REJECTED: 'NONIABVENDORS_EDIT_REJECTED',
  },
  GET_BY_ID: {
    FETCH: 'NONIABVENDORS_GET_BY_ID_FETCH',
    PENDING: 'NONIABVENDORS_GET_BY_ID_PENDING',
    FULFILLED: 'NONIABVENDORS_GET_BY_ID_FULFILLED',
    REJECTED: 'NONIABVENDORS_GET_BY_ID_REJECTED',
    CLEANED: 'NONIABVENDORS_GET_BY_ID_CLEANED',
  },
  GET_GOOGLE_ATP: {
    FETCH: 'NONIABVENDORS_GET_GOOGLE_ATP_FETCH',
    PENDING: 'NONIABVENDORS_GET_GOOGLE_ATP_PENDING',
    FULFILLED: 'NONIABVENDORS_GET_GOOGLE_ATP_FULFILLED',
    REJECTED: 'NONIABVENDORS_GET_GOOGLE_ATP_REJECTED',
  }
};

export const PREVIEW = {
  SHOW: {
    FETCH: 'SHOW_FETCH',
    PENDING: 'SHOW_PENDING',
    FULFILLED: 'SHOW_FULFILLED',
    REJECTED: 'SHOW_REJECTED'
  },
  CLEAN: { FETCH: 'CLEAN_FETCH' }
};

export const STACKS = {
  GET_RECOMMENDED: {
    FETCH: 'STACKS_FETCH',
    PENDING: 'STACKS_PENDING',
    FULFILLED: 'STACKS_FULFILLED',
    REJECTED: 'STACKS_REJECTED'
  },
  CLEAN: { FETCH: 'CLEAN_FETCH' },
  INFORMATION: { SET: 'SET_STACKS_INFORMATION' }
};

export const APPS = {
  CREATE: {
    FETCH: 'APPS_CREATE_FETCH',
    PENDING: 'APPS_CREATE_PENDING',
    FULFILLED: 'APPS_CREATE_FULFILLED',
    REJECTED: 'APPS_CREATE_REJECTED',
    CLEAN: 'APPS_CREATE_CLEAN'
  },
  EDIT: {
    FETCH: 'APPS_EDIT_FETCH',
    PENDING: 'APPS_EDIT_PENDING',
    FULFILLED: 'APPS_EDIT_FULFILLED',
    REJECTED: 'APPS_EDIT_REJECTED',
    CLEAN: 'APPS_EDIT_CLEAN'
  },
  GET_BY_ID: {
    FETCH: 'APPS_GET_BY_ID_FETCH',
    PENDING: 'APPS_GET_BY_ID_PENDING',
    FULFILLED: 'APPS_GET_BY_ID_FULFILLED',
    REJECTED: 'APPS_GET_BY_ID_REJECTED',
    CLEAN: 'APPS_GET_BY_ID_CLEAN'
  },
  DELETE: {
    FETCH: 'APPS_DELETE_FETCH',
    PENDING: 'APPS_DELETE_PENDING',
    FULFILLED: 'APPS_DELETE_FULFILLED',
    REJECTED: 'APPS_DELETE_REJECTED'
  }
};

export const GBC_PURPOSES = {
  GET: {
    FETCH: 'GBC_PURPOSES_GET_FETCH',
    PENDING: 'GBC_PURPOSES_GET_PENDING',
    FULFILLED: 'GBC_PURPOSES_GET_FULFILLED',
    REJECTED: 'GBC_PURPOSES_GET_REJECTED',
  }
};

export const SURVEY_LINK = {
  GET: {
    FETCH: 'SURVEY_LINK_GET_FETCH',
    PENDING: 'SURVEY_LINK_GET_PENDING',
    FULFILLED: 'SURVEY_LINK_GET_FULFILLED',
    REJECTED: 'SURVEY_LINK_GET_REJECTED',
  }
};

export const MSPA_PURPOSES = {
  GET: {
    FETCH: 'MSPA_PURPOSES_GET_FETCH',
    PENDING: 'MSPA_PURPOSES_GET_PENDING',
    FULFILLED: 'MSPA_PURPOSES_GET_FULFILLED',
    REJECTED: 'MSPA_PURPOSES_GET_REJECTED',
  }
};
