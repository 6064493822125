/* eslint-disable import/prefer-default-export */
// @flow
import { requestCustomization, requestCustomizationV2, requestBuildV2 } from '../../utils/requests';
import { getAll as getAllThemes } from './themes';
import type { iSite } from '../../interfaces/sites';

const url = '/site';

export function getAll([pCode]) {
  return requestCustomization
    .get(url, { params: { pCode, sort: 'url,ASC' } })
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getById([id]) {
  return requestCustomizationV2
    .get(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function createSite([data]) {
  const cleanedData = { ...data };
  delete cleanedData.majorVersion;

  return requestCustomizationV2
    .post(url, cleanedData)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function editSite([id, data]) {
  const cleanedData = { ...data };
  delete cleanedData.majorVersion;

  return requestCustomizationV2
    .put(`${url}/${id}`, cleanedData)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function deleteSite([id]) {
  return requestCustomizationV2
    .delete(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function deleteSiteFromChoice([pCode, siteUrl]) {
  const siteUrlHost = siteUrl.includes('http') ? new URL(siteUrl).host : siteUrl;

  return requestBuildV2
    .delete(`choicejs${url}/${siteUrlHost}/pCode/${pCode}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getInitSitesLoad([isPremium, pCode, type]) {
  // Always load the data using the new v2 API
  const sitesReq = requestCustomizationV2.get(url, { params: { pCode, sort: 'url,ASC' } });
  const themesReq = getAllThemes([pCode, type]);
  const requests = [sitesReq, themesReq];

  return Promise.allSettled(requests)
    .then(([sitesResp, themesData]) => {
      if (sitesResp.status === 'rejected' || sitesResp.value.err) {
        throw sitesResp.reason || sitesResp.value.err;
      }
      if (themesData.status === 'rejected' || themesData.value.err) {
        throw themesData.reason || themesData.value.err;
      }
      const sites = [];
      if (isPremium) {
        sites.push(...sitesResp.value.data);
      } else if (sitesResp.value.data._embedded && 'sites' in sitesResp.value.data._embedded) {
        sites.push(...sitesResp.value.data._embedded.sites);
      }
      return {
        res: {
          sites,
          themes: themesData.value.res
        }
      };
    })
    .catch(err => ({ err }));
}

export function getSupportCustomVendors([pCode, customizedVendors = true]: [string, boolean]): Array<iSite> {
  return requestCustomizationV2
    .get(url, { params: { pCode, sort: 'url,ASC', supportsCustomizedVendors: customizedVendors } })
    .then((res) => {
      if ('data' in res) {
        return res.data;
      }
      return [];
    })
    .catch(err => ({ err }));
}
