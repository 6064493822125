import trafficIcon from '../../assets/images/analytics/traffic.svg';
import sessionsIcon from '../../assets/images/analytics/totalSessions.svg';
import optInIcon from '../../assets/images/analytics/optIn.svg';
import optOutIcon from '../../assets/images/analytics/optOut.svg';
import partialOptInIcon from '../../assets/images/analytics/partialOptIn.svg';
import bounceIcon from '../../assets/images/analytics/bounce.svg';
import { BROWSER_FILTERS, DEVICE_TYPE_FILTERS, FILTER_TYPE, OS_FILTERS, PAGE_FILTERS, REGULATION_FILTERS } from '../../utils/constants';
import TEXT from './text';

export const FILTER_DISPLAY_NAME = {
  regulation: 'Regulation',
  pageFormat: 'Pages',
  deviceType: 'Device Types',
  os: 'OS',
  country: 'Countries'
};

export const REGULATION_OPTIONS = [
  { label: 'GDPR', value: 1 },
  { label: 'CCPA', value: 2 },
  { label: 'MSPA', value: 3 }
];

export const PAGE_OPTIONS = [
  { label: 'HTTP', value: 'HTTP' },
  { label: 'AMP', value: 'AMP' }
];

export const DEVICE_TYPE_OPTIONS = [
  { label: 'Desktop', value: 'pc' },
  { label: 'Tablet', value: 'tablet' },
  { label: 'Phone', value: 'mobile' }
];

export const OS_OPTIONS = [
  { label: 'Android', value: 'Android' },
  { label: 'iOS', value: 'iOS' },
  { label: 'Windows', value: 'Windows' }
];

export const BAR_LINE_OPTIONS = [
  { label: 'Bar', value: 'bar' },
  { label: 'Line', value: 'line' }
];

export const REGULATION_OPTIN_STAT = 'regulation_wise_opt_in_percent';
export const REGULATION_TRAFFIC_STAT = 'regulation_wise_traffic';
export const REGULATION_SESSIONS_STAT = 'regulation_wise_total_sessions';
export const REGION_TRAFFIC_STAT = 'region_wise_traffic';
export const REGION_SESSIONS_STAT = 'region_wise_total_sessions';
export const PAGE_SESSIONS_STAT = 'page_wise_total_sessions';
export const REGULATION_CONSENT_STAT = 'regulation_wise_consent_distribution';
export const COUNTRY_TRAFFIC_OPTIN_STAT = 'country_wise_traffic_and_opt_in_percent';
export const COUNTRY_SESSIONS_OPTIN_STAT = 'country_wise_sessions_and_opt_in_percent';
export const GDPR_CONSENT_STAT = 'gdpr_consent_distribution';
export const CCPA_CONSENT_STAT = 'ccpa_consent_distribution';

export const DIMENSIONS_METRICS_MAP = {
  [REGULATION_OPTIN_STAT]: {
    dimensions: ['regulation'],
    metrics: ['optInPercent']
  },
  // [REGULATION_TRAFFIC_STAT]: {
  //   dimensions: ['regulation'],
  //   metrics: ['totalTraffic']
  // },
  [REGULATION_SESSIONS_STAT]: {
    dimensions: ['regulation'],
    metrics: ['totalSessions']
  },
  // [REGION_TRAFFIC_STAT]: {
  //   dimensions: ['country'],
  //   metrics: ['totalTraffic']
  // },
  [REGION_SESSIONS_STAT]: {
    dimensions: ['country'],
    metrics: ['totalSessions']
  },
  [PAGE_SESSIONS_STAT]: {
    dimensions: ['pageFormat'],
    metrics: ['totalSessions']
  },
  [REGULATION_CONSENT_STAT]: {
    dimensions: ['regulation'],
    metrics: ['optInPercent', 'optOutPercent', 'partialOptInPercent', 'bouncePercent']
  },
  // [COUNTRY_TRAFFIC_OPTIN_STAT]: {
  //   dimensions: ['country'],
  //   metrics: ['totalTraffic', 'optInPercent']
  // },
  [COUNTRY_SESSIONS_OPTIN_STAT]: {
    dimensions: ['country'],
    metrics: ['totalSessions', 'optInPercent']
  },
  [GDPR_CONSENT_STAT]: {
    dimensions: ['regulation'],
    metrics: ['optInPercent', 'optOutPercent', 'partialOptInPercent', 'bouncePercent']
  },
  [CCPA_CONSENT_STAT]: {
    dimensions: ['regulation'],
    metrics: ['optInPercent', 'optOutPercent', 'partialOptInPercent', 'bouncePercent']
  }
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const COLOR_MAP = {
  COLOR_1: 'rgba(47, 114, 244, 1)',
  COLOR_2: 'rgba(255, 152, 0, 1)',
  COLOR_3: 'rgba(0, 188, 212, 1)',
  COLOR_4: 'rgba(233, 30, 99, 1)'
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_APPLIED_FILTERS = {
  regulation: [],
  pageFormat: [],
  deviceType: [],
  os: [],
  country: []
};

export const SUMMARY_ICON_MAP = {
  totalTraffic: trafficIcon,
  totalSessions: sessionsIcon,
  optInPercent: optInIcon,
  optOutPercent: optOutIcon,
  bouncePercent: bounceIcon,
  partialOptInPercent: partialOptInIcon
};

export const FIELD_NAMES = {
  REGULATION: 'regulation',
  PAGE_FORMAT: 'pageFormat',
  COUNTRY: 'country',
  TRAFFIC: 'totalTraffic',
  SESSIONS: 'totalSessions',
  OPT_IN: 'optInPercent',
  OPT_OUT: 'optOutPercent',
  PARTIAL_OPT_IN: 'partialOptInPercent',
  BOUNCE: 'bouncePercent',
  DEVICE_TYPE: 'deviceType',
  OS: 'os',
  PROPERTY_ID: 'propertyId'
};

export const STATUS = {
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
  NEUTRAL: 'neutral'
};

export const VALUE_TYPE = {
  NUMBER: 'number',
  PERCENTAGE: 'percentage'
};

export const DATE_RANGE = 90;

export const checkbox_style = {
  margin: 0,
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'capitalize',
  color: '#5d6b89',
  letterSpacing: '0.3px',
  fontStyle: 'normal',
  marginBottom: '8px'
};

export const FILTERS = [
  { type: FILTER_TYPE.REGULATION, label: TEXT.REGULATION, filters: REGULATION_FILTERS },
  { type: FILTER_TYPE.DEVICE_TYPE, label: TEXT.DEVICE_TYPE, filters: DEVICE_TYPE_FILTERS },
  { type: FILTER_TYPE.OS, label: TEXT.OS, filters: OS_FILTERS },
  { type: FILTER_TYPE.PAGE_FORMAT, label: TEXT.PAGE_FORMAT, filters: PAGE_FILTERS },
  { type: FILTER_TYPE.BROWSER, label: TEXT.BROWSER, filters: BROWSER_FILTERS }
].sort((a, b) => a.label.localeCompare(b.label));

export const REACH_PCODE = 'JYWDqeLS64fbt';