// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'antd/es/button';
import PageHeader from 'antd/es/page-header';
import Tabs from 'antd/es/tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/fontawesome-pro-solid';
import styles from './details.module.scss';
import Result from '../../../../components/ui/result';
import { fetchByApp } from '../../../../store/duck/analytics';
import Loader from '../../../../components/ui/loader';
import ChoiceReport from '../../../site-detail/components/choice-reports';

type Props= {
  pCode: string;
};

function Details({ pCode }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { current } = useSelector(state => state.apps);
  const { analytics } = useSelector(state => state);
  const data = analytics.current ? analytics.current : null;
  const privacyModes = current.privacyModes || [];
  const { gbcEnabled } = current;

  useEffect(() => {
    if (current.siteId && current.siteId !== analytics.current.idProperty) {
      dispatch(fetchByApp(current.siteId, pCode, [current.siteId]));
    }
  },
  [current.siteId, pCode, analytics.current.idProperty]);

  const renderMetrics = () => {
    if (analytics.pending || Object.keys(current).length === 0) {
      return <Loader />;
    }
    if (current.siteId === analytics.current.idProperty) {
      return (
        <ChoiceReport
          analytics={data}
          privacyMode={privacyModes}
          dataTestid="choice-report-component"
          isGBCEnabled={gbcEnabled}
          isGPPEnabled
        />
      );
    }
    return (
      <Result
        title="Something went wrong"
        status="error"
        subTitle="This was an error in pulling data for this page.
        Please try again or contact your InMobi representative."
        extra={(
          <Button
            href={`/protect/p-${pCode}`}
            type="primary"
            style={{ color: '#fff' }}
          >
            Back to Properties
          </Button>
        )}
      />
    );
  };

  return (
    <>
      <PageHeader
        title={current.packageId}
        extra={[
          <Button
            key="edit"
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            data-testid="editAppsButton"
            type="text"
            onClick={() => { history.push(`/protect/p-${pCode}/apps/edit/${current.siteId}`); }}
          />
        ]}
      />
      <div className={styles.toolbar}>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: 'InMobi CMP Reports',
              children: (
                <>
                  {renderMetrics()}
                </>
              )
            }
          ]}
        />
      </div>
    </>
  );
}

export default Details;
