// @flow
import type { IRoute } from '../interfaces/routes';
import SiteDetailPage from '../pages/site-detail';
import SitesPage from '../pages/sites';
import ThemesPage from '../pages/themes';
import ThemesFormPage from '../pages/themes-form';
import vendorsPage from '../pages/vendors';
import AppsPage from '../pages/apps';
import BulkUpdatePage from '../pages/bulk-update';
import BulkUpdateStatusPage from '../pages/bulk-update-status/component';
import * as PATHS from './paths';
import premiumReportsPage from '../pages/premium-reports/premiumReports';
import ViewReports from '../pages/premium-reports/analytics/viewReports';

const routes: Array<IRoute> = [
  {
    exact: true,
    path: PATHS.VENDORS.INDEX,
    component: vendorsPage
  },
  {
    path: PATHS.SITE_DETAIL.INDEX,
    component: SiteDetailPage
  },
  {
    path: PATHS.SITE_REPORTS_ID.INDEX,
    component: premiumReportsPage
  },
  {
    path: PATHS.SITES.INDEX,
    component: SitesPage
  },
  {
    path: PATHS.THEMES.FORM,
    component: ThemesFormPage
  },
  {
    path: PATHS.THEMES.INDEX,
    component: ThemesPage
  },
  {
    path: PATHS.APPS.INDEX,
    component: AppsPage
  },
  {
    exact: true,
    path: PATHS.PROPERTIES.INDEX,
    component: SitesPage
  },
  {
    exact: true,
    path: PATHS.BULK_UPDATE.INDEX,
    component: BulkUpdatePage
  },
  {
    exact: true,
    path: PATHS.BULK_UPDATE.STATUS,
    component: BulkUpdateStatusPage
  },
  {
    exact: true,
    path: PATHS.SITE_REPORTS.INDEX,
    component: premiumReportsPage,
  },
  {
    path: PATHS.SITE_REPORTS.VIEW_REPORTS,
    component: ViewReports
  }
];

export default routes;
