import { MSPA_PURPOSES } from '../actions';
import { buildError } from '../../utils/reducer';

const defState = {
  purposes: [],
  error: {
    status: 200,
    message: ''
  },
  pending: false,
};

export function fetchMSPAPurposes() {
  return { type: MSPA_PURPOSES.GET.FETCH };
}

function mspaPurposesReducer(state = defState, action) {
  switch (action.type) {
    case MSPA_PURPOSES.GET.PENDING: {
      return { ...state, pending: true };
    }
    case MSPA_PURPOSES.GET.FULFILLED: {
      return { ...state, purposes: action.payload, pending: false };
    }
    case MSPA_PURPOSES.GET.REJECTED: {
      return { ...state, purposes: [], error: buildError(action.payload), pending: false };
    }
    default: {
      return state;
    }
  }
}

export default mspaPurposesReducer;
