// @flow
import React, { useState, useEffect } from 'react';
import Table from 'antd/es/table';
import Styles from './table.module.scss';

type Props = {
  data: Array<Object>,
  handleChangeGoogleList: Function,
  selectedGoogleVendors: Array<Number>
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'provider_id',
    key: 'provider_id',
  },
  {
    title: 'Vendor Name',
    dataIndex: 'provider_name',
    key: 'provider_name',
  },
  {
    title: 'Privacy Policy',
    dataIndex: 'policy_url',
    key: 'policy_url',
    render: (_txt, item) => {
      if (Array.isArray(item.policy_url)) {
        return item.policy_url.map(policy => (
          <>
            <a target="_blank" rel="noreferrer" href={policy}>
              {policy}
            </a>
            &nbsp;
          </>
        ));
      }
      return (
        <a target="_blank" rel="noreferrer" href={item.policy_url}>
          {item.policy_url}
        </a>
      );
    }
  }
];

const GoogleTable = ({ data, selectedGoogleVendors, handleChangeGoogleList }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Object[] | null>([]);
  const [pageSize, setPageSize] = useState(70);

  useEffect(() => setSelectedRowKeys(selectedGoogleVendors), [selectedGoogleVendors]);


  const paginationHandler = (_, noOfRows) => setPageSize(noOfRows);
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onSelect: (record, selected) => {
      if (selected) {
        handleChangeGoogleList([...selectedGoogleVendors, parseInt(record.provider_id, 10)]);
      } else {
        handleChangeGoogleList(selectedGoogleVendors.filter(key => key !== parseInt(record.provider_id, 10)));
      }
    },
    onSelectAll: (selected) => {
      if (selected) {
        handleChangeGoogleList(data.map(row => parseInt(row.provider_id, 10)));
      } else {
        handleChangeGoogleList([]);
      }
    },
    onSelectNone: () => {
      handleChangeGoogleList([]);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowSelection={rowSelection}
      pagination={{
        pageSize,
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        onShowSizeChange: paginationHandler,
      }}
      rowKey={record => parseInt(record.provider_id, 10)}
      className={Styles.table}
      size="middle"
    />
  );
};

export default GoogleTable;
