import { call, put, select } from 'redux-saga/effects';
import { BULK_UPDATES, ALERTS } from '../actions';
import { fetchStatus, requestUpdate, fetchBulkUpdateRequests } from '../requests/bulk-updates';
import { ALERT_IDENTIFIERS } from '../../utils/constants';
import { getStorage, removeStorage, updateStorage } from '../../utils/localStorage';

const REQUEST_IDS_KEY = 'qc-frontend-manager-request-ids';

function getStorageRequestIds() {
  const savedLogs = getStorage(REQUEST_IDS_KEY);
  return savedLogs ? JSON.parse(savedLogs) : [];
}

function removeStorageRequestIds() {
  removeStorage(REQUEST_IDS_KEY);
}

function updateStorageRequestIds(requestIds) {
  const savedLogs = getStorage(REQUEST_IDS_KEY);
  const newValue = savedLogs ? JSON.parse(savedLogs).concat(requestIds) : requestIds;
  updateStorage(REQUEST_IDS_KEY, newValue);
}

export function selectPcode(state) { return state.app.pCode; }

export function* fetchBulkUpdateStatus({ payload }) {
  const pCode = yield select(selectPcode);
  yield put({ type: BULK_UPDATES.STATUS.PENDING });
  yield put({
    type: ALERTS.REMOVE,
    payload: ALERT_IDENTIFIERS.BULK_UPDATES_SUCCESS_ALERT
  });
  yield put({
    type: ALERTS.REMOVE,
    payload: ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT
  });

  const { res, err } = yield call(fetchBulkUpdateRequests, [payload.pCode]);
  if (res && res.status === 200) {
    const { requestIds } = res.data;
    const savedRequestIds = getStorageRequestIds();
    const alreadyViewed = savedRequestIds.some(sReqId => sReqId === requestIds[0]);
    const { res: _res, err: _err } = yield call(fetchStatus, [payload.pCode, requestIds[0]]);
    if (payload.page === 'status' && (_res.data.status === 'SUCCESSFUL' || _res.data.status === 'FAILED')) {
      updateStorageRequestIds([requestIds[0]]);
    }
    if (alreadyViewed && payload.page !== 'status' && _res.data.status !== 'INQUEUE') {
      yield put({
        type: ALERTS.REMOVE,
        payload: ALERT_IDENTIFIERS.BULK_UPDATES_SUCCESS_ALERT
      });
      return;
    }
    if (_res && _res.status === 200 && _res.data.status === 'INQUEUE') {
      yield put({ type: BULK_UPDATES.STATUS.FULFILLED, payload: _res.data });
      yield put({
        type: ALERTS.ADD,
        payload: {
          id: ALERT_IDENTIFIERS.BULK_UPDATES_INQUEUE,
          type: 'warning',
          message: 'Bulk Update',
          description: 'A bulk update process is running',
          buttons: [{
            text: 'GO TO STATUS',
            size: 'small',
            type: 'link',
            href: `/protect/p-${pCode}/bulk-update-status`,
          }]
        }
      });
    }
    if (_res && _res.status === 200 && _res.data.status === 'SUCCESSFUL') {
      yield put({
        type: ALERTS.REMOVE,
        payload: ALERT_IDENTIFIERS.BULK_UPDATES_INQUEUE
      });
      yield put({ type: BULK_UPDATES.STATUS.FULFILLED, payload: _res.data });
      yield put({
        type: ALERTS.ADD,
        payload: {
          id: ALERT_IDENTIFIERS.BULK_UPDATES_SUCCESS_ALERT,
          type: 'success',
          message: 'Bulk Update',
          description: 'A bulk update finished successfully',
          buttons: [{
            text: 'GO TO STATUS',
            size: 'small',
            type: 'link',
            href: `/protect/p-${pCode}/bulk-update-status`,
          }]
        }
      });
    }
    if (_res && _res.status === 200 && _res.data.status === 'FAILED') {
      yield put({
        type: ALERTS.REMOVE,
        payload: ALERT_IDENTIFIERS.BULK_UPDATES_INQUEUE
      });
      yield put({ type: BULK_UPDATES.STATUS.REJECTED, payload: _res.data });
      yield put({
        type: ALERTS.ADD,
        payload: {
          id: ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT,
          type: 'error',
          message: 'Bulk Update',
          description: 'There was an error trying to update the properties selected...',
          closable: true,
        }
      });
    } else if (_err && _err.response) {
      yield put({ type: BULK_UPDATES.STATUS.REJECTED, payload: _err.response.data });
      yield put({
        type: ALERTS.ADD,
        payload: {
          id: ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT,
          type: 'error',
          message: 'Bulk Update',
          description: 'There was an error trying to get the bulk update status',
          closable: true,
        }
      });
    }
  } else if (err && err.response.status !== 404) {
    yield put({ type: BULK_UPDATES.STATUS.REJECTED, payload: err.response.data });
    yield put({
      type: ALERTS.ADD,
      payload: {
        id: ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT,
        type: 'error',
        message: 'Bulk Update',
        description: 'There was an error trying to get the bulk update status',
        closable: true,
      }
    });
  } else if (err && err.response.status === 404) {
    removeStorageRequestIds();
  }
}

export function* bulkUpdate({ payload }) {
  const pCode = yield select(state => state.app.pCode);
  yield put({ type: BULK_UPDATES.UPDATE.PENDING });

  const { res } = yield call(requestUpdate, [payload.pCode, payload.sites, payload.fields]);

  if (res) {
    yield put({ type: BULK_UPDATES.UPDATE.FULFILLED, payload: res });
    yield put({
      type: ALERTS.ADD,
      payload: {
        id: ALERT_IDENTIFIERS.BULK_UPDATES_SUCCESS_ALERT,
        type: 'success',
        message: 'Bulk Update',
        description: 'The bulk update process is running',
        buttons: [{
          text: 'GO TO STATUS',
          size: 'small',
          type: 'link',
          href: `/protect/p-${pCode}/bulk-update-status`,
        }]
      }
    });
  } else {
    yield put({ type: BULK_UPDATES.UPDATE.REJECTED, payload: res });
    yield put({
      type: ALERTS.ADD,
      payload: {
        id: ALERT_IDENTIFIERS.BULK_UPDATES_ERROR_ALERT,
        type: 'error',
        message: 'Bulk Update',
        description: 'There was an error trying to update the properties selected...',
        closable: true,
      }
    });
  }
}
