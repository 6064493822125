import { requestCustomization } from '../../utils/requests';

export function fetchBulkUpdateRequests([pCode]) {
  return requestCustomization
    .get(`/bulk-update/pcode/${pCode}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function fetchStatus([pCode, requestId]) {
  return requestCustomization
    .get(`/bulk-update/pcode/${pCode}/requestId/${requestId}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function requestUpdate([pCode, sites, fields]) {
  return requestCustomization
    .put(`/bulk-update/pcode/${pCode}`, {
      siteUpdatableFields: fields,
      siteIds: sites
    })
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
