/* eslint-disable import/prefer-default-export */
import { call, put, select } from 'redux-saga/effects';
import { APPS, BUILD, SITES, ALERTS } from '../actions';
import {
  createApp,
  editApp,
  getById,
  deleteApp
} from '../requests/apps';
import { buildError } from '../../utils/reducer';

export function* fetchCreateApp({ payload }) {
  yield put({ type: APPS.CREATE.PENDING });

  const { res, err } = yield call(createApp, [payload.appData]);

  if (res) {
    const pCode = yield select(state => state.app.pCode);

    yield put({
      type: BUILD.APPS.FETCH,
      payload: { appId: res.data.siteId }
    });
    yield put({ type: APPS.CREATE.FULFILLED, payload: res.data });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `The app ${res.data.packageId} was created successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    yield put({ type: APPS.CREATE.REJECTED, payload: err.response });
  }
}

export function* fetchEditApp({ payload }) {
  yield put({ type: APPS.EDIT.PENDING });

  const { res, err } = yield call(editApp, [payload.appId, payload.appData]);

  if (res) {
    yield put({
      type: BUILD.APPS.FETCH,
      payload: { appId: res.data.siteId }
    });
    yield put({ type: APPS.EDIT.FULFILLED, payload: { data: res.data, id: parseInt(payload.appId, 10) } });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `The app ${res.data.packageId} was edited successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    const message = err.response.data && err.response.data.message
      ? err.response.data.message : 'Error creating app';
    yield put({
      type: APPS.CREATE.REJECTED,
      payload: {
        status: err.response.data.status,
        message
      }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Fail to create app: ${message}`,
      }
    });
  }
}

export function* fetchAppById({ payload }) {
  if ('pCode' in payload) {
    yield put({ type: APPS.GET_BY_ID.PENDING });

    const { res, err } = yield call(getById, [payload.id]);

    if (res) {
      if (res.data.pCode === payload.pCode) {
        yield put({ type: APPS.GET_BY_ID.FULFILLED, payload: res.data });
      } else {
        yield put({ type: APPS.GET_BY_ID.REJECTED, payload: buildError({ status: 401, message: 'Unauthorized' }) });
      }
    } else {
      yield put({
        type: APPS.GET_BY_ID.REJECTED,
        payload: err.response
      });
    }
  }
}

export function* fetchDeleteApp({ payload }) {
  yield put({ type: APPS.DELETE.PENDING });

  const { res, err } = yield call(deleteApp, [payload.siteId]);

  if (res) {
    yield put({ type: APPS.DELETE.FULFILLED, payload: { id: payload.siteId } });
  } else {
    yield put({ type: APPS.DELETE.REJECTED, payload: err.response });
  }
}
