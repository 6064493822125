import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavbar from '../../components/hoc/with-navbar';
import * as ThemesActions from '../../store/duck/themes';
import * as VendorsActions from '../../store/duck/vendors';
import ThemesFormContainer from './themesFormContainer';
import * as AlertsActions from '../../store/duck/alerts';

const MapStateToProps = ({ app, form, themes, vendors, stacks }) => (
  { app, form: form.themes, themes, vendors, stacks }
);

const MapDispatchToProps = dispatch => ({
  actions: {
    themes: bindActionCreators(ThemesActions, dispatch),
    vendors: bindActionCreators(VendorsActions, dispatch),
    alerts: bindActionCreators(AlertsActions, dispatch)
  }
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withNavbar(ThemesFormContainer));
