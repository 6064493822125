// @flow
import React from 'react';
import uid from 'uid';
import Modal from '../../../components/ui/modal';

type Props = {
  action: 'block' | 'unblock',
  blockHandler: Function,
  open: boolean,
  onClose: Function
}

const COPIES = {
  block: {
    title: 'Block vendor(s)?',
    body: [
      <p key={uid()}>
        Selected vendor(s) will no longer be shown to your users in the CMP. The vendor(s) will receive a signal
        that they can no longer operate on your site.
      </p>,
    ]
  },
  unblock: {
    title: 'Unblock vendor(s)?',
    body: [
      <p key={uid()}>
        Selected vendor(s) will be shown again to your users in the CMP. The vendor(s) will receive a signal that they
        can operate on your site again.
      </p>
    ]
  }
};

const ConfirmationModal = ({ action = 'block', blockHandler, open, onClose }: Props) => (
  <Modal
    open={open}
    onCancel={onClose}
    title={COPIES[action].title}
    cancelText="CANCEL"
    okText={action.toUpperCase()}
    onOk={() => blockHandler(action)}
  >
    {COPIES[action].body}
  </Modal>
);

export default ConfirmationModal;
