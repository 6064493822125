/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { STACKS } from '../actions';
import { fetchStacksRequest } from '../requests/stacks';

export function* fetchStacks(
  {
    payload: {
      publisherPurposeConsentIds,
      publisherPurposeLegitimateInterestIds,
      publisherSpecialFeaturesIds
    }
  }
) {
  yield put({ type: STACKS.GET_RECOMMENDED.PENDING });

  const { res, err } = yield call(fetchStacksRequest,
    {
      publisherPurposeConsentIds,
      publisherPurposeLegitimateInterestIds,
      publisherSpecialFeaturesIds
    });

  if (res) {
    const responseData = [...res.data].sort((a, b) => a.weight - b.weight);

    yield put({
      type: STACKS.GET_RECOMMENDED.FULFILLED,
      payload: responseData
    });
  } else {
    yield put({ type: STACKS.GET_RECOMMENDED.REJECTED, payload: err.response });
  }
}
