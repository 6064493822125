import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import alertsReducer from './duck/alerts';
import analyticsReducer from './duck/analytics';
import appReducer from './duck/app';
import appsReducer from './duck/apps';
import auditLogsReducer from './duck/audit-logs';
import authReducer from './duck/authentication';
import bulkUpdatesReducer from './duck/bulk-updates';
import cmpVersionsV2Reducer from './duck/versionsV2';
import gbcPurposesReducer from './duck/gbc';
import mspaPurposesReducer from './duck/mspa';
import nonIabVendorReducer from './duck/non-iabvendors';
import previewReducer from './duck/preview';
import sitesReducer from './duck/sites';
import stacksReducer from './duck/stacks';
import themesReducer from './duck/themes';
import vendorsReducer from './duck/vendors';
import surveyLinkReducer from './duck/survey-link';

const reducers = combineReducers({
  form,
  app: appReducer,
  sites: sitesReducer,
  audit: auditLogsReducer,
  alerts: alertsReducer,
  themes: themesReducer,
  preview: previewReducer,
  vendors: vendorsReducer,
  analytics: analyticsReducer,
  cmpVersionV2: cmpVersionsV2Reducer,
  surveyLink: surveyLinkReducer,
  nonIabVendors: nonIabVendorReducer,
  stacks: stacksReducer,
  apps: appsReducer,
  bulkUpdates: bulkUpdatesReducer,
  auth: authReducer,
  gbcPurposes: gbcPurposesReducer,
  mspaPurposes: mspaPurposesReducer,
});

export default reducers;
