import { requestBuildV2, requestBatchBuild } from '../../utils/requests';

export function buildTheme([themeId, pCode]) {
  return requestBuildV2
    .patch(`/choicejs/theme/${themeId}/pCode/${pCode}`, {})
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function batchUpdateAllChoiceJsFiles([pCode]) {
  return requestBatchBuild
    .patch(`/choicejs/batch/pCode/${pCode}`, {})
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function buildAmpTag([id]) {
  return requestBuildV2
    .get(`/choicejs/amp/site/${id}`, {})
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
