import React, { useState } from 'react'

import commonStyles from './common.module.css'
import Spin from '../../components/ui/spin';

function errorMessage(error) {
  switch(error.code) {
    case 'UserNotFoundException':
    case 'InvalidParameterException':
    case 'NotAuthorizedException':
      return "Please check your email and password and try again";
    default:
      return error.message || error.log || error.code;
  }
}

function SignIn({ signIn, updateFormState, updateFormType, error }) {
  const [loading, setLoading] = useState(false);

  const signInNow = (e) => {
    setLoading(true);
    e.preventDefault(); 
    signIn().finally(() => {
      setLoading(false);
    });
    return false;
  }

  return (
    <>
      <div className={commonStyles.heading}>
        Log In
      </div>
      {error && (<span className={commonStyles.error}>{errorMessage(error)}</span>)}
        <form className={commonStyles.formContainer} onSubmit={signInNow}>
          <input 
            name='username'
            onChange={e => {e.persist(); updateFormState(e);}}
            placeholder='Email'
          />
          <input
            type='password'
            name='password'
            onChange={e => {e.persist(); updateFormState(e);}}
            placeholder='Password'
          />
          <button type='submit' className={commonStyles.primaryButton}>
            {loading ? (<Spin size='large' />) : (<>SIGN IN</>)}
          </button>
          <p style={{width: '100%', textAlign: 'center', paddingTop: '15px'}}>
            <a onClick={() => updateFormType('forgotPassword')}>Having Trouble Signing In?</a>
          </p>
      </form>
    </>
  )
}

export default SignIn