import { call, put } from 'redux-saga/effects';

import { MSPA_PURPOSES } from '../actions';
import { getMSPAPurposes } from '../requests/mspa';
/* eslint-disable import/prefer-default-export */


export function* fetchMSPAPurposes() {
  yield put({ type: MSPA_PURPOSES.GET.PENDING });

  const { res, err } = yield call(getMSPAPurposes);

  if (res) {
    yield put({ type: MSPA_PURPOSES.GET.FULFILLED, payload: res.data.purposes });
  } else {
    yield put({ type: MSPA_PURPOSES.GET.REJECTED, payload: err.response });
  }
}
