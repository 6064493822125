// @flow
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from 'antd/es/checkbox';
import Radio from 'antd/es/radio';
import Styles from './nonIabVendorsForm.module.scss';

type Props = {
  onChange: Function;
  purposeLegitimateIds: Array<number>;
  purposeIds: Array<number>;
  gvlVersion: Number;
}

function PurposeList(
  {
    onChange,
    purposeIds,
    purposeLegitimateIds,
    gvlVersion,
  }: Props
) {
  const mergedPurposeIds = purposeIds.concat(purposeLegitimateIds).sort();
  const [legitimatePurposes, setLegitimatePurposes] = useState(purposeLegitimateIds);
  const [purposesSource, setPurposesSource] = useState(purposeLegitimateIds);
  const [purposesConsent, setpurposesConsent] = useState(mergedPurposeIds);
  const purposesV2 = useSelector(state => state.vendors.v2.data.purposes);
  const purposesV3 = useSelector(state => state.vendors.v3.data.purposes);
  const handleChange = (id, isLegitimate = false) => {
    let newPurposes = [...purposesConsent];
    let newLegitimate = [...legitimatePurposes];

    if (!isLegitimate) {
      if (!newPurposes.includes(id)) {
        newPurposes.push(id);
      } else {
        newPurposes = newPurposes.filter(purposeId => purposeId !== id);
        newLegitimate = newLegitimate.filter(purposeId => purposeId !== id);
      }
    } else if (!newLegitimate.includes(id)) {
      newLegitimate.push(id);
    } else {
      newLegitimate = newLegitimate.filter(purposeId => purposeId !== id);
    }

    setpurposesConsent(newPurposes);
    setLegitimatePurposes(newLegitimate);
  };

  useEffect(() => {
    setPurposesSource(gvlVersion === 3 ? purposesV3 : purposesV2);
    if (gvlVersion === 2) {
      setLegitimatePurposes(legitimatePurposes.filter(purpose => purpose !== 11));
      setpurposesConsent(purposesConsent.filter(purpose => purpose !== 11));
    }
  }, [gvlVersion]);
  useEffect(() => {
    onChange({
      consentIds: purposesConsent,
      legitimateIds: legitimatePurposes
    });
  }, [purposesConsent, legitimatePurposes]);
  return (
    <div className={`${Styles.purposeSectionList}`}>
      {purposesSource.map(purpose => purpose.id && (
        <div key={`purpose-${purpose.id}`}>
          <Checkbox
            key={`checkbox-purpose.${purpose.id}`}
            name={`purpose.${purpose.id}`}
            checked={
              purposesConsent.includes(parseInt(purpose.id, 10))
              || legitimatePurposes.includes(parseInt(purpose.id, 10))
            }
            onChange={() => handleChange(purpose.id)}
            data-testid={`purpose.${purpose.id}`}
          >
            {purpose.name}
          </Checkbox>
          {(purposesConsent.includes(purpose.id)
            || legitimatePurposes.includes(purpose.id)) && (
            <Radio.Group
              key={`radio-purpose-${purpose.id}`}
              className={Styles.consentsRadios}
              name={`legitimate.${purpose.id}`}
              value={legitimatePurposes.includes(parseInt(purpose.id, 10))}
              onChange={() => handleChange(purpose.id, true)}
              data-testid={`legitimate.${purpose.id}`}
            >
              <Radio value={false}>
                Consent
              </Radio>
              <Radio
                value
                disabled={
                  purpose.id === 1
                  || (gvlVersion === 3 && purpose.id === 3)
                  || (gvlVersion === 3 && purpose.id === 4)
                  || (gvlVersion === 3 && purpose.id === 5)
                  || (gvlVersion === 3 && purpose.id === 6)
                }
              >
                Legitimate Interest
              </Radio>
            </Radio.Group>
          )}
        </div>
      ))}
    </div>
  );
}

export default PurposeList;
