// @flow
import React, { useState, useEffect } from 'react';
import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Styles from '../../../../components/forms/themes/themesForm.module.scss';
import Select from '../../../../components/ui/select';
import FormStyles from '../../../../scss/_forms.module.scss';
import UIStyles from './uiSettings.module.scss';
import { SUPPORTED_LANGUAGES } from '../../../../utils/language';

type Props = {
  disagreeButtonTextDisabled: boolean;
  customLinks: Array;
  translations: Object;
  validations: Object;
  setDataTestId: Function;
  onChange: Function;
  validateFields: Function;
  regulationEnabled: boolean;
}

function UISettings({
  disagreeButtonTextDisabled,
  customLinks,
  validations,
  translations,
  setDataTestId,
  onChange,
  validateFields,
  regulationEnabled
}: Props) {
  const [values, setValues] = useState({
    uxInitialScreenCustomLinksText0: '',
    uxInitialScreenCustomLinksUrl0: '',
    uxInitialScreenCustomLinksText1: '',
    uxInitialScreenCustomLinksUrl1: '',
    uxInitialScreenCustomLinksText2: '',
    uxInitialScreenCustomLinksUrl2: ''
  });
  const { Option } = Select;
  const [disableDisagree, setDisableDisagree] = useState(false);

  useEffect(() => {
    const links = {
      uxInitialScreenCustomLinksText0: '',
      uxInitialScreenCustomLinksUrl0: '',
      uxInitialScreenCustomLinksText1: '',
      uxInitialScreenCustomLinksUrl1: '',
      uxInitialScreenCustomLinksText2: '',
      uxInitialScreenCustomLinksUrl2: ''
    };

    if (customLinks && customLinks.length) {
      links.uxInitialScreenCustomLinksText0 = customLinks[0].text;
      links.uxInitialScreenCustomLinksUrl0 = customLinks[0].url;

      if (customLinks.length > 1) {
        links.uxInitialScreenCustomLinksText1 = customLinks[1].text;
        links.uxInitialScreenCustomLinksUrl1 = customLinks[1].url;

        if (customLinks.length > 2) {
          links.uxInitialScreenCustomLinksText2 = customLinks[2].text;
          links.uxInitialScreenCustomLinksUrl2 = customLinks[2].url;
        }
      }
    }

    setValues(links);
  }, []);

  useEffect(() => {
    setDisableDisagree(disagreeButtonTextDisabled);
  }, [disagreeButtonTextDisabled]);

  const handleCustomLinkChange = (input, event) => {
    setValues({
      ...values,
      [input]: event.target.value
    });
  };

  return (
    <Card title="UI settings">
      <Form.Item
        name="language"
        label="UI Language"
      >
        <Select
          showSearch
          filterOption={(input, option) => (
            option.props.children.toLowerCase().includes(input.toLowerCase())
            || option.props.value.toLowerCase().includes(input.toLowerCase())
          )}
          onChange={lang => onChange(preValues => ({ ...preValues, language: lang }))}
        >
          {SUPPORTED_LANGUAGES && SUPPORTED_LANGUAGES.map(opt => (
            <Option key={opt.value} value={opt.value} disabled={opt.disabled}>{opt.label}</Option>
          ))}
        </Select>
      </Form.Item>
      {regulationEnabled && (
        <>
          <Form.Item
            name="initScreenRejectButtonShowing"
            valuePropName="checked"
            className={Styles.disagreeButton}
          >
            <Checkbox
              data-testid={setDataTestId('initScreenRejectButtonShowing')}
              onChange={({ target }) => setDisableDisagree(!target.checked)}
            >
              SHOW DISAGREE BUTTON ON THE SUMMARY VIEW
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="initScreenRejectButton"
            label="DISAGREE BUTTON TEXT"
            rules={validations.textInput}
          >
            <Input
              disabled={disableDisagree}
              placeholder={translations ? translations.disagree : 'DISAGREE'}
            />
          </Form.Item>
          <section className={`
      ${UIStyles.customLinks}
      ${Styles.disagreeButton}
      ${Styles.customLinks}
      ${Styles.form}
      ${FormStyles['wrapper-inline']}
    `}
          >
            <h5>
              CUSTOM FOOTER LINKS &nbsp;
              <span>The first entry in the custom footer list is reserved for your privacy policy.</span>
            </h5>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText0"
                dependencies={['uxInitialScreenCustomLinksUrl0']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl0')}
              >
                <Input
                  placeholder="Link text"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText0', event);
                    validateFields(['uxInitialScreenCustomLinksUrl0']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl0"
                dependencies={['uxInitialScreenCustomLinksText0']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText0')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl0', event);
                    validateFields(['uxInitialScreenCustomLinksText0']);
                  }}
                />
              </Form.Item>
            </section>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText1"
                dependencies={['uxInitialScreenCustomLinksUrl1']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl1')}
              >
                <Input
                  placeholder="Link text"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText1', event);
                    validateFields(['uxInitialScreenCustomLinksUrl1']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl1"
                dependencies={['uxInitialScreenCustomLinksText1']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText1')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl1', event);
                    validateFields(['uxInitialScreenCustomLinksText1']);
                  }}
                />
              </Form.Item>
            </section>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText2"
                dependencies={['uxInitialScreenCustomLinksUrl2']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl2')}
              >
                <Input
                  placeholder="Link text"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText2', event);
                    validateFields(['uxInitialScreenCustomLinksUrl2']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl2"
                dependencies={['uxInitialScreenCustomLinksText2']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText2')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={(event) => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl2', event);
                    validateFields(['uxInitialScreenCustomLinksText2']);
                  }}
                />
              </Form.Item>
            </section>
          </section>
        </>
      )}
    </Card>
  );
}

export default UISettings;
