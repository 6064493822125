/* eslint-disable import/prefer-default-export */

export const PAGE_TITLE = 'Bulk Update';
export const BACK_TO_ALL_PROPERTIES_LABEL = 'Back to all properties';
export const BACK_TO_FILTER_LABEL = 'Back to filter';
export const BACK_TO_DETAILS_LABEL = 'Back to details';
export const TYPES_FILER = [
  // 'App',
  'Site',
  'Site & Subdomains',
  'Site AMP'
];
export const YES_NO_FILTER = ['Yes', 'No'];

export const CONSENT_LOCATIONS_MAPPING = {
  inEU: 'EEA',
  never: 'NEVER',
  always: 'WORLDWIDE'
};

export const CONSENT_LOCATIONS = [
  { value: 'EEA', label: 'EEA & UK' },
  { value: 'WORLDWIDE', label: 'Worldwide' },
  { value: 'USA', label: 'USA' },
  { value: 'NEVER', label: 'Never' },
];

export const encodingOptions = [
  { label: 'GPP', value: 'GPP' },
  { label: 'TCF', value: 'TCF' },
  { label: 'Both', value: 'TCF_AND_GPP' }
];
