import { Checkbox } from "antd/es";
import React from "react";
import { checkbox_style } from "../constants";

const DetailsSummary = ({ label, type, filters, selectedFilters, handleFiltersChange }) => {
    return (
        <details>
          <summary>{label}</summary>
          <div style={{ marginLeft: "16px", display: "flex", flexDirection: "column" }}>
            {filters.map((filter, index) => {
                const selectedFilter = selectedFilters.find(el => el.type === type);
                const isChecked = selectedFilter ? selectedFilter.values.includes(filter.name) : false;

                return (
                <Checkbox
                  style={{ ...checkbox_style }}
                  value={filter.name}
                  checked={isChecked}
                  onChange={e => handleFiltersChange(e, type)}
                  key={filter.name + index}
                >
                  {filter.label}
                </Checkbox>
                );
            })}
          </div>
        </details>
    )
};

export default DetailsSummary;

