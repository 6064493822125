// @flow
/* eslint-disable import/prefer-default-export */

export function buildTableData(data: any): Array<any> {
  return Object.keys(data).length
    ? data.vendors.map(vendor => ({
      ...vendor,
      blocked: data.blockedVendors.indexOf(vendor.id) > -1,
      children: [{
        id: `c-${vendor.id}`,
        policyUrl: vendor.policyUrl,
        purposes: data.purposes.filter(item => vendor.purposeIds.includes(item.id)),
        features: data.features.filter(item => vendor.featureIds.includes(item.id)),
        legitimate: data.purposes.filter(item => vendor.legIntPurposeIds.includes(item.id))
      }]
    }))
    : [];
}

export function getLegitimateConsentIds(vendorsData: Array<Record<String, any>>) {
  const keysToMap = [
    { gvlKey: 'purposes', vendorKey: 'vendorPurposeIds' },
    { gvlKey: 'legIntPurposes', vendorKey: 'vendorPurposeLegitimateInterestIds' },
    { gvlKey: 'features', vendorKey: 'vendorFeaturesIds' },
    { gvlKey: 'specialFeatures', vendorKey: 'vendorSpecialFeaturesIds' },
    { gvlKey: 'specialPurposes', vendorKey: 'vendorSpecialPurposesIds' },
    { gvlKey: 'flexiblePurposes', vendorKey: 'vendorFlexiblePurposesIds' }
  ];

  return vendorsData.vendors
    ? vendorsData.vendors.filter(vendor => !vendorsData.blockedVendors.includes(vendor.id))
      .reduce((acc, current) => {
        keysToMap.forEach((key) => {
          if (current[key.gvlKey]) {
            current[key.gvlKey].forEach((item) => {
              if (!acc[key.vendorKey].includes(item)) acc[key.vendorKey].push(item);
            });
          }
        });
        return acc;
      }, {
        vendorPurposeIds: [],
        vendorPurposeLegitimateInterestIds: [],
        vendorFeaturesIds: [],
        vendorSpecialFeaturesIds: [],
        vendorSpecialPurposesIds: [],
        vendorFlexiblePurposesIds: []
      }) : [];
}
