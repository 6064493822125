import { STACKS } from '../actions';
import { buildError } from '../../utils/reducer';

const initialState = {
  list: [],
  error: {
    status: 200,
    message: ''
  },
  pending: true,
  stacksInformation: null
};

export const cleanStacks = () => ({ type: STACKS.CLEAN.FETCH });

export const setStacksInformation = stackSelectedHtml => (
  {
    type: STACKS.INFORMATION.SET,
    payload: { stackSelectedHtml }
  }
);

export const fetchStacks = (
  publisherPurposeConsentIds,
  publisherPurposeLegitimateInterestIds,
  publisherSpecialFeaturesIds
) => (
  {
    type: STACKS.GET_RECOMMENDED.FETCH,
    payload: {
      publisherPurposeConsentIds,
      publisherPurposeLegitimateInterestIds,
      publisherSpecialFeaturesIds
    }
  }
);

const stacksReducer = (state = initialState, action) => {
  switch (action.type) {
    case STACKS.GET_RECOMMENDED.PENDING:
      return { ...state, pending: true };
    case STACKS.GET_RECOMMENDED.FULFILLED:
      return { ...state, list: action.payload, pending: false };
    case STACKS.GET_RECOMMENDED.REJECTEDs:
      return { ...state, error: buildError(action.payload), pending: false };
    case STACKS.CLEAN.FETCH:
      return { ...state, list: [], pending: true };
    case STACKS.INFORMATION.SET:
      return { ...state, stacksInformation: action.payload.stackSelectedHtml };

    default:
      return state;
  }
};

export default stacksReducer;
