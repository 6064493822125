// This component checks wheather or not a user is signed in
// It will allow to view private routes or force the user to log in
// When a user signs in, they will be redirectd to their profile page

import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const redirectToFirstAccount = (Comp) => (props) => {
    var { user, isAuthenticated } = useSelector((s) => s.auth);
    var history = useHistory();
    useEffect(() => {
    if(isAuthenticated === true) {        
        history.push(`/protect/p-${user.attributes['custom:pcode'].split(",")[0]}/properties`);
    }
    }, [isAuthenticated])
    return <Comp {...props} />
}

export default redirectToFirstAccount

