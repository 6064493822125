// @flow
import React, { useState } from 'react';
import DatePicker from 'antd/es/date-picker';
import { Row, Col } from 'antd/es/grid';
import Form from 'antd/es/form';
import moment from 'moment';
import Modal from '../../../../components/ui/modal';
import Select from '../../../../components/ui/select';
import Styles from '../../sites.module.scss';

type Props = {
  infoAuditSite: Object;
  toggleOpen: Function;
}

function ModalAuditLogs({ infoAuditSite, toggleOpen }: Props) {
  const { site, show, siteHasAnalytics } = infoAuditSite;
  const { Option } = Select;
  const [endDate, setEndDate] = useState('');
  const [periodTime, setPeriodTime] = useState('');
  const onChange = (date) => {
    setEndDate(date);
  };

  const onSubmit = () => {
    const startDate = moment(endDate).subtract(periodTime, 'days').format('YYYY-MM-DD');
    infoAuditSite.prepareDownloadLog({
      ...site,
      siteHasAnalytics,
      startDate,
      endDate: endDate.format('YYYY-MM-DD'),
    });
    toggleOpen();
  };

  const disabled = !endDate || !periodTime;

  return (
    <Modal
      className={Styles.modal}
      title="Download Site logs"
      id="dowload-site-logs"
      onCancel={toggleOpen}
      open={show}
      closable
      cancelText="CANCEL"
      okText="DOWNLOAD"
      onOk={onSubmit}
      centered
      okButtonProps={{
        datatestid: 'dowload_site_logs_modal',
        disabled
      }}
    >
      <Form
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span="12">
            <Form.Item
              name="endDate"
              label="Pick an end Date"
              colon={false}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={onChange}
                value={endDate}
                data-testid="pick-time"
                id="pick-time"
                style={{ width: '100%' }}
                disabledDate={current => current && (current > moment() || current < moment().subtract(13, 'month'))}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              colon={false}
              name="rangeDays"
              label="Days back between dates"
            >
              <Select
                allowClear
                id="range-time"
                value={periodTime}
                data-testid="range-time"
                onChange={value => setPeriodTime(value)}
              >
                <Option value="30" data-testid="select-option">30 Days</Option>
                <Option value="60" data-testid="select-option">60 Days</Option>
                <Option value="90" data-testid="select-option">90 Days</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalAuditLogs;
