import React, { useEffect, useState } from 'react'

import commonStyles from './common.module.css'
import Spin from '../../components/ui/spin';

function errorMessage(error) {
  return error.message || error.log || error.code;
}

function ForgotPassword({ forgotPassword, updateFormState, error }) {
  const [loading, setLoading] = useState(false);

  const submitForgotPassword = (e) => {
    setLoading(true);
    e.preventDefault(); 
    forgotPassword().finally(() => {
      setLoading(false);
    });
    return false;
  }

  return (
    <>
      <div className={commonStyles.heading}>
        Forgot Password
      </div>
      {error && (<span className={commonStyles.error}>{errorMessage(error)}</span>)}
      <form className={commonStyles.formContainer} onSubmit={submitForgotPassword}>
        <input
          name='username'
          placeholder='Email'
          onChange={e => {e.persist();updateFormState(e)}}
        />
        <button type='submit' className={commonStyles.primaryButton}>
          {loading ? (<Spin size='large' />) : (<>Reset password</>)}
        </button>
      </form>
    </>
  )
}

export default ForgotPassword