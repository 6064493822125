import dayjs from "dayjs";
import { TIME, TIME_FILTERS, TIME_PERIOD_TYPE } from "../utils/constants";
import moment from 'moment';

export const formatDate = date => {
  if (!date) return null; // Handle null dates
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const TIME_FILTERS_WITH_START_END_DATES = TIME_FILTERS.map(filter => {
  const today = new Date(); // Local time
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())); // Convert to UTC
  let startDate, endDate;

  switch (filter) {
    case "This Week":
      const dayOfWeek = todayUTC.getUTCDay();
      startDate = new Date(todayUTC);
      startDate.setUTCDate(todayUTC.getUTCDate() - dayOfWeek);
      endDate = new Date(todayUTC);
      break;

    case TIME.MONTH_TILL_DATE:
      startDate = new Date(Date.UTC(todayUTC.getUTCFullYear(), todayUTC.getUTCMonth(), 1));
      endDate = new Date(todayUTC);
      break;

    case TIME.LAST_7_DAYS:
      startDate = new Date(todayUTC);
      startDate.setUTCDate(todayUTC.getUTCDate() - 7);
      endDate = new Date(todayUTC);
      break;

    case TIME.LAST_30_DAYS:
      startDate = new Date(todayUTC);
      startDate.setUTCDate(todayUTC.getUTCDate() - 30);
      endDate = new Date(todayUTC);
      break;

    case TIME.LAST_3_MONTHS:
      startDate = new Date(todayUTC);
      startDate.setUTCMonth(todayUTC.getUTCMonth() - 3);
      endDate = new Date(todayUTC);
      break;

    case TIME.CUSTOM_DATE:
      startDate = null; // Or set to a default value if necessary
      endDate = null; // Or set to a default value if necessary
      break;

    default:
      startDate = endDate = null;
  }

  return {
    label: filter,
    startDate: formatDate(startDate), // Adjust formatDate function to handle UTC dates if needed
    endDate: formatDate(endDate)
  };
});

export function reverseTimeFilter(startDate, endDate) {
  if (!startDate || !endDate) return null;

  const start = new Date(startDate);
  const end = new Date(endDate);
  
  const timeDifference = end - start;
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24); // Difference in days

  if (dayDifference < 30) {
    return TIME.MONTH_TILL_DATE;
  } else if (dayDifference === 6) {
    return TIME.LAST_7_DAYS;
  } else if (dayDifference === 29) {
    return TIME.LAST_30_DAYS;
  } else if (end.getMonth() - start.getMonth() === 3 && dayDifference > 85 && dayDifference < 95) {
    return TIME.LAST_3_MONTHS;
  } else {
    return TIME.CUSTOM_DATE;
  }
}

function getISOFormatDate(date) {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD"); // Format as YYYY-MM-DD
}

export const transformFilters = data => {
  // Transform the filters array
  const transformedFilters = data.map(filter => {
    return {
      key: filter.type,
      operator: "in",
      values: filter.values
    };
  });

  return transformedFilters.filter(el => el.values && el.values.length > 0).filter(el => el.key !== "time");
};

export function downloadCSV(data) {
  // Extract the data from the state
  const results = data;
  
  // Define the headers of the CSV
  const csvHeaders = Object.keys(results[0]);

  // Create CSV content
  const csvRows = [
      csvHeaders.join(','),  // Add headers
      ...results.map(row => csvHeaders.map(header => row[header]).join(','))  // Add data rows
  ];

  // Convert the rows to a single string
  const csvContent = csvRows.join('\n');

  // Create a Blob object representing the CSV file
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link element to download the file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'report.csv';
  
  // Append the link to the body and trigger click to download
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link element
  document.body.removeChild(link);
}

export function calculateRangeFromType(type) {
  const now = moment();
  let startDate = moment();
  let endDate = moment();

  switch (type) {
    case 'lastDay':
      startDate = now.subtract(1, 'days');
      break;
    case 'last7Days':
      startDate = now.subtract(7, 'days');
      break;
    case 'last30Days':
      startDate = now.subtract(30, 'days');
      break;
    case 'lastWeek':
      startDate = now.subtract(1, 'weeks').startOf('week');
      endDate = now.subtract(1, 'weeks').endOf('week');
      break;
    case 'lastMonth':
      startDate = now.subtract(1, 'months').startOf('month');
      endDate = now.subtract(1, 'months').endOf('month');
      break;
    case 'currentWeek':
      startDate = now.startOf('week');
      endDate = now.endOf('week');
      break;
    case 'currentMonth':
      startDate = now.startOf('month');
      endDate = now.endOf('month');
      break;
    default:
      break;
  }

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD')
  };
}

export const disabledDate = current => {
  // Can not select days before today and today in UTC
  return current < dayjs().utc().startOf("day");
};

export const getDisabledHours = () => {
  return [0, 1, 2, 3, 4, 5, 6, 7]; // since we need to disable hours from 00:00 to 07:00 as report runs every 6.30 UTC
};

export const disabledTime = (current) => {
  return {
    disabledHours: () => getDisabledHours()   
  }
};

export const getTimePeriodLabel = (label: string) => {
  console.log("label", label, TIME_PERIOD_TYPE);
  if (label == TIME.CUSTOM_DATE) {
    return "fixed";
  }

  return TIME_PERIOD_TYPE.find((item) => item.label === label).value;
}

export const getSiteUrl = (siteUuid, premiumSites) => {
  if (!premiumSites || !premiumSites.properties || !siteUuid) {
    return '-';
  }
  const site = premiumSites.properties.find(el => el.siteUuid === siteUuid);
  return site ? site.url : '';
};

export function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';
  
  const k = 1024; // 1 KB = 1024 Bytes
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}