import { Space, Table, DatePicker } from 'antd/es';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/fontawesome-pro-light';
import dayjs from 'dayjs';
import Style from '../premiumReports.module.scss';
import {
  customDateFormat,
  getAnalyticsPayload,
  getDateRanges,
  getLastNDaysRange,
  getTableData,
  shouldDateBeDisabled,
  formatNumber,
  getCompairionsDefaultDateRange
} from '../utils';
import { useTrafficConsentTableData } from '../../../data/hooks/analytics';
import Loader from '../../../components/ui/loader';
import { COUNTRY_SESSIONS_OPTIN_STAT, COUNTRY_TRAFFIC_OPTIN_STAT, DATE_FORMAT, DATE_RANGE, DIMENSIONS_METRICS_MAP, FIELD_NAMES } from '../constants';
import TEXT from '../text';
import { COUNTRY_CODES } from '../../../utils/constants';

// @flow
interface ITrafficConsentCardProps {
  currentDateFilter: Array<string>;
  appliedFilters: any;
  propertyId: Array<string>;
  pCode: string;
}

const { RangePicker } = DatePicker;

const getPercentageChangeIcon = (value) => {
  if (!value || value === 0) return null;
  return value > 0 ? (
    <FontAwesomeIcon className="fa-sm" icon={faArrowUp} color="rgba(82, 119, 37, 1)" />
  ) : (
    <FontAwesomeIcon className="fa-sm" icon={faArrowDown} color="rgba(188, 21, 3, 1)" />
  );
};

const columns = [
  {
    title: 'Country',
    dataIndex: FIELD_NAMES.COUNTRY,
    key: FIELD_NAMES.COUNTRY,
    width: '50%',
    filters: COUNTRY_CODES.map(country => {
      const countryLabel = country.label;
      return { text: countryLabel.length > 20 ? `${countryLabel.substring(0, 20)}...` : countryLabel, value: country.value };
    }),
    onFilter: (value, record) => record.key === value,
    filterSearch: true,
    render: (_txt, item) => <span>{item.country}</span>
  },
  {
    title: 'Display Count',
    dataIndex: FIELD_NAMES.SESSIONS,
    key: FIELD_NAMES.SESSIONS,
    width: '25%',
    align: 'left',
    sorter: (a, b) => {
      const aTotalSessions = a.totalSessions || 0;
      const bTotalSessions = b.totalSessions || 0;
      return aTotalSessions - bTotalSessions;
    },
    defaultSortOrder: 'descend',
    render: (_txt, item) => (
      <Space>
        <span>{formatNumber(item.totalSessions)}</span>
        {getPercentageChangeIcon(item.totalSessionsChange)}
      </Space>
    )
  },
  {
    title: 'Opt-in',
    dataIndex: FIELD_NAMES.OPT_IN,
    key: FIELD_NAMES.OPT_IN,
    width: '25%',
    align: 'left',
    sorter: (a, b) => {
      const aOptInPercent = a.optInPercent || 0;
      const bOptInPercent = b.optInPercent || 0;
      return aOptInPercent - bOptInPercent;
    },
    render: (_txt, item) => {
      const optInPercent = item.optInPercent || 0;
      return (
        <Space>
          <span>{`${optInPercent.toFixed(1)}%`}</span>
          {getPercentageChangeIcon(item.optInPercentChange)}
        </Space>
      );
    }

  }
];

const getDisplayTableData = (metricsData) => {
  const metric = metricsData[0];
  const { dimensions, metrics } = DIMENSIONS_METRICS_MAP[metric.id];
  return getTableData(dimensions[0], [...metrics, 'optInPercentChange', 'totalSessionsChange'], metric);
};

const TrafficConsentCard = ({
  currentDateFilter,
  appliedFilters,
  propertyId,
  pCode
}: ITrafficConsentCardProps) => {
  const [dateFilter, setDateFilter] = useState(getCompairionsDefaultDateRange().map(d => d.format(DATE_FORMAT)));

  const queriesArr = [COUNTRY_SESSIONS_OPTIN_STAT];
  const currentDatePayload = getAnalyticsPayload(
    currentDateFilter,
    appliedFilters,
    propertyId,
    pCode,
    'none',
    queriesArr
  );
  const comparisonDatePayload = dateFilter
    ? getAnalyticsPayload(dateFilter, appliedFilters, propertyId, pCode, 'none', queriesArr)
    : {};

  const TablePayload = {
    currentDate: currentDatePayload,
    comparisonDate: comparisonDatePayload,
    enabled: !!dateFilter
  };
  const { isLoading, data, fetchStatus } = useTrafficConsentTableData(TablePayload);
  const isDataLoading = isLoading && fetchStatus !== 'idle';

  const tableDataSource = data ? getDisplayTableData(data) : null;

  const handleDateChange = (dates) => {
    if (!dates) {
      setDateFilter(dates);
      return;
    }
    const datesArr = dates.map(date => dayjs(date).format(DATE_FORMAT));
    setDateFilter(datesArr);
  };

  return (
    <div className={Style.tableCard}>
      {
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Space className={Style.header}>
            <Space direction="vertical">
              <p className={Style.subTitle}>{TEXT.TABLE_HEADER}</p>
              <Space>
                <p className={Style.subDescription}>{TEXT.TABLE_SUB_TEXT}</p>
                <RangePicker
                  onChange={handleDateChange}
                  defaultValue={getCompairionsDefaultDateRange()}
                  size="small"
                  format={customDateFormat}
                  disabledDate={d => shouldDateBeDisabled(d, DATE_RANGE)}
                  ranges={getDateRanges()}
                />
              </Space>
            </Space>
          </Space>
          {isDataLoading ? (
            <Loader />
          ) : (
            <Table
              rowClassName={(record, index) => (index % 2 === 0 ? Style.tableRowDark : Style.tableRowLight)}
              dataSource={tableDataSource}
              columns={columns}
              pagination={{ pageSize: 10, showSizeChanger: false, size:"small"}}
              hideOnSinglePage
              className={Style.table}
            />
          )}
        </Space>
      }
    </div>
  );
};

export default TrafficConsentCard;
