/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import Typography from 'antd/es/typography';

import Styles from './tooltips.module.scss';

const SITETOOLTIPS = {
  AMP: (
    <>
      This site uses the AMP web component framework. For more information, please refer to our
      {' '}
      <a
        href="https://support.inmobi.com/choice/how-to-guide/integrate-the-tag-or-sdk/amp-implementation/overview-amp/"
        target="_blank"
        rel="noopener noreferrer"
      >
        help article.
      </a>
    </>
  ),
  APPLY_SUBDOMAINS:
  <>
    Enabling this alone is NOT ENOUGH for enabling consents across sub-domains. You need to ensure that you implement the steps mentioned <a href="https://support.inmobi.com/choice/how-to-guide/integrate-the-tag-or-sdk/site-implementation-tag/sharing-consent-between-multiple-sites" target="_blank" rel="noopener noreferrer"> here</a>.
  </>,
  USER_CONSENT: 'Publishers established within the EEA and the UK are required to apply GDPR and ePrivacy Directive to any user (option "Everyone") regardless of the user\'s location.',
  CONSENT_SCOPE: "Service-specific scope means a user's preferences are applicable only to the publisher property on which the preference was set.",
  GOOGLE_TOOLTIP: pcode => (
    <>
      Enables Google to use additional vendors from their Ad Tech Providers (ATP) list.
      This is the vendor list Google has provided to gather and propagate additional consent (alongside TCF v2.0 implementation)
      {" for companies that are not yet registered with the IAB Europe Global Vendor List but are on Google's Ad Tech Providers (ATP) list. Please visit Google's "}
      <a href="https://support.google.com/admanager/answer/9681920" target="_blank" rel="noopener noreferrer">
        help article
      </a>
      {' '}
      for more information. You can configure the vendors you partner with in the
      {' '}
      <a
        href={`${window.location.origin}/protect/p-${pcode}/vendors`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Vendors section of our portal.
      </a>
    </>
  ),
  CCPA: (
    <>
      If you would like to learn more about CCPA, refer to our
      {' '}
      <a
        href="https://help.quantcast.com/hc/en-us/articles/13422467354907-The-California-Consumer-Privacy-Act-CCPA-"
        target="_blank"
        rel="noopener noreferrer"
      >
        help article.
      </a>
    </>
  ),
  uspLspact: (
    <>
      Check with your DPO whether your company has signed the
      {' '}
      <a href="https://www.iabprivacy.com/" target="_blank" rel="noopener noreferrer">
        Limited Services Provider Agreement
      </a>
      {' '}
      as part of the
      {' '}
      <a
        href="https://www.iab.com/blog/ccpa-compliance-framework/"
        target="_blank"
        rel="noopener noreferrer"
      >
        IAB CCPA Compliance Framework
      </a>
      {". If you aren't sure, leave this field unchecked - this will not impact your usage of the CCPA opt-out signal."}
    </>
  ),
  useStacks: (
    <>
      You can either show a list of purposes or you select from pre-generated list of stacks.
      For more information about stack refer to the
      {' '}
      <a href="https://iabeurope.eu/tcf-2-0/" target="_blank" rel="noopener noreferrer">
        IAB policy.
      </a>
    </>
  ),
  selectPurposes: (
    <>
      Allows you to select purposes that you want to apply a restriction to the vendor’s legal basis.
      This is an expert feature please consult the
      {' '}
      <a href="https://iabeurope.eu/tcf-2-0/" target="_blank" rel="noopener noreferrer">
        IAB policy.
      </a>
      {' '}
      for details on publisher restrictions before proceeding.
    </>
  ),
  initialThemeScreen: 'Your users will see this when the CMP is first prompted.',
  sumaryView: 'This screen includes a summary of all the purposes in the provided privacy language, with more options for users to proceed to provide granular consent preferences.',
  listView: 'This screen includes a list view of all the purposes and/or stacks configured, with progressive disclosures for users to proeceed to provide granular consent preferences.',
  persistentConsentLink: 'A means for users to revoke consent is required. The persistent privacy button is a convenient way to accomplish this. If you decide to disable it, you are responsible to provide access to the user to revoke consent through another mechanism.',
  countryCode: (
    <>
      The country code of the country that determines legislation of reference.
      Commonly, this corresponds to the country in which the publisher’s business
      entity is established. For more information, please visit
      {' '}
      <a href="https://iabeurope.eu/tcf-2-0/" target="_blank" rel="noopener noreferrer">IAB TCF v2.0</a>
      .
    </>
  ),
  siteLogo: (
    <>
      We support PNG, BMP, JPG/JPEG, and GIF file formats for site
      logos with a ratio between a quotient of 1 to 3.5.
      <div style={{ marginTop: 10 }}>
        <Typography.Text strong className={Styles.typography_text}> Example: </Typography.Text>
        Your logo has a ratio of 6:3.
        6 divided by 3 equals a quotient of 2, which falls between 1 to 3.5.
      </div>
    </>
  ),
  reportGDPR: (
    <span className={Styles.tooltip_with_paragraphs}>
      <p>
        <Typography.Text strong className={Styles.typography_text}> Saw InMobi CMP: </Typography.Text>
        The percentage the CMP was shown; this will always be 100%.
      </p>
      <p>
        <Typography.Text className={Styles.typography_text} strong> Accept All: </Typography.Text>
        This is the number of users who accepted all consent configs (agreed without making any modification).
        The percentage is calculated by: Accept All visits (17) / Saw InMobi CMP (21) = 80.95%
      </p>
      <p>
        <Typography.Text className={Styles.typography_text} strong> Reject All: </Typography.Text>
        This is the number of users who rejected all consent configs
        on the CMP (disagreed to the consent terms). The percentage is calculated
        by: Reject All visits (2) / Saw InMobi CMP (21) = 9.52%
      </p>
      <p>
        <Typography.Text className={Styles.typography_text} strong> Accept Some: </Typography.Text>
        Represents the number of users accepted self-defined consent configs on the CMP.
        The percentage is calculated by: Accept Some visits (0) / Saw InMobi CMP (21) = 0%
      </p>
      <p>
        <Typography.Text className={Styles.typography_text} strong> Bounce: </Typography.Text>
        Shows the percentage of times the CMP was shown, but had no interaction with the
        user because the CMP was indirectly dismissed (for example, the browser or tab was
        closed, any bot traffic on your page). This is calculated by: Bounce (2) / Saw InMobi CMP (21) = 9.52
      </p>
    </span>
  ),
  createEditTheme: pathname => (
    <>
      Learn more about how to
      {pathname && pathname.includes('new')
        ? ' create ' : ' edit '}
      a theme in our
      {' '}
      <a
        href="https://help.quantcast.com/hc/en-us/articles/13422220343451-How-to-Configure-or-Edit-a-Site"
        target="_blank"
        rel="noopener noreferrer"
      >
        help article.
      </a>
    </>
  ),
  enableGBC: (
    <>
      To learn more about Google basic consent, click
      {' '}
      <a
        href="https://support.inmobi.com/choice/google-basic-consent-with-inmobi-cmp/introduction-gbc"
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </>
  ),
  gbcDefaultValue: (
    <>
      Recommended to be set as “Denied“ especially for EEA and UK regions.
    </>
  ),
  gbcLocation: (
    <>
      Recommended to be set as EEA & UK region since Google Basic consent is a mandatory requirement for this region.
    </>
  ),
  coveredTransaction: (
   <>
      Not sure about your signatory status? Click
      { ' ' }
      <a href="https://www.iabprivacy.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      { ' ' }
      to review the signatory list and sign the framework.
    </>
  ),
  regulatoryPath: (
    <>
      If you are an MSPA signatory, it is recommended to use National framework all across US. To know why click
      { ' ' }
      <a href="https://support.inmobi.com/choice/gpp-cmp/overview-gpp/technicalities-of-mspa#what-is-a-regulatory-path?"
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </>
  ),
  optOutPurposes: (
    <>
      All selected opt-outs may not be collected in all states. Know more about the state-wise opt-out requirements -
      { ' ' }
      <a href="https://support.inmobi.com/choice/gpp-cmp/consents-covered-under-mspa#consents-covered-under-mspa"
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </>
  ),
  gdprEncodingMode: (
    <>
      It is advisable to select both "GPP" and "TCF". "Both" Option will not work for AMP sites.
    </>
  ),
  gdprEncodingModeApp: (
    <>
      It is advisable to select both "GPP" and "TCF".
    </>
  ),
  consentLocations: (
    <>
      Depending on the location of your end-users, the applicable regulation will be auto-applied.
    </>
  ),
  ccpaViaUsp: (
    <>
      To learn more, click
      { ' ' }
      <a href="https://iabtechlab.com/us-privacy-signal-deprecation-deadline-extended-to-january-31-2024/"
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </>
  ),
  gdpr: (
    <>
      To learn more about GDPR, click
      { ' ' }
      <a href="https://support.inmobi.com/choice/choice-overview/supported-regulations/choice-gdpr"
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </>
  ),
  US_regulation: (
    <>
    To learn more about US regulation, click
    { ' ' }
    <a href="https://support.inmobi.com/choice/gpp-cmp/overview-gpp/us-regulations"
      target="_blank"
      rel="noopener noreferrer"
    >
      here.
    </a>
  </>
  ),
  UrlPassThrough: (
    <>
    For details on what URL passthrough is:
    { ' ' }
    <a href="https://developers.google.com/tag-platform/tag-manager/templates/consent-apis#url-passthrough"
      target="_blank"
      rel="noopener noreferrer"
    >
      Link.
    </a>
    </>
  ),
  AdsDataRedaction: (
    <>
    For details on what Data redaction is:
    { ' ' }
    <a href="https://developers.google.com/tag-platform/tag-manager/templates/consent-apis#data-redaction"
      target="_blank"
      rel="noopener noreferrer"
    >
      Link.
    </a>
    </>
  ),
  vendorsDisclosed: (
    <>
      For details on what Vendors Disclosed is:
      { ' ' }
      <a href="https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20Consent%20string%20and%20vendor%20list%20formats%20v2.md#disclosed-vendors"
        target="_blank"
        rel="noopener noreferrer"
      >
        Link.
      </a>
    </>
  ),
};

export default SITETOOLTIPS;
