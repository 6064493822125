import { Hub, Auth } from "aws-amplify";
import { delay, eventChannel } from "redux-saga";
import { take, put, takeEvery, fork, select } from "redux-saga/effects";
import { authStateChanged } from "../duck/authentication";
import { AsyncStorage } from "../../utils/secureTokenStorage";
import { AUTH } from "../actions";


export function* initializeAuthentication() {
    window.Auth = Auth;
    yield fork(eventConsumer, {payload: {event: 'init'}});
    yield fork(keepAuthentication);
}

export function *keepAuthentication() {
    yield take(AUTH.STATE_CHANGED);
    while(true) {
        yield delay(1000 * 60 * 1);
        const {isAuthenticating, isAuthenticated} = yield select((s) => s.auth);
        if(isAuthenticating == false && isAuthenticated == true) {
            // Every minute ask for the user. This makes sure that when 
            // session expires it it automatically extended.
            console.log("Refreshing token");
            yield Auth.currentAuthenticatedUser()
        }
    }
}

export function *eventConsumer(event) {
    // console.log('eventConsumer', event.payload);
    const emptyAuthState = {
        isAuthenticated: false,
        user: null,
        isAuthenticating: false,
    };
    switch (event.payload.event) {
        case "configured":
        case "signIn":
        case "signIn_failure":
        case "signOut":
        case "init":
            try {
                var user = yield Auth.currentAuthenticatedUser();
                yield AsyncStorage.syncRemote();
                yield put(authStateChanged(
                    {
                        isAuthenticated: _isAuthenticated(user),
                        user: user,
                        isAuthenticating: false,
                    }
                ));
            } catch(err) {
                yield put(authStateChanged(emptyAuthState));
            }
            break;
        case "tokenRefresh":
            yield AsyncStorage.syncRemote();
        default:
            break;
    }
    if(event.payload.event === 'signOut') {
        yield AsyncStorage.cleanRemote();
        window.location.href = '/login';
    }
}

function _isAuthenticated(user) {
    if (
        !user ||
        !user.getSignInUserSession() ||
        !user.getSignInUserSession().isValid() // isValid() also verified the Token Signature
    ) {
        return false
    }
    return true;
}

export function* authListener() {
    const channel = eventChannel((emit) => {
        // Emitter
        const emitter = (event) => emit(event);
  
        // AWS: Auth Listener
        Hub.listen('auth', emitter);
  
        // AWS: Remove Auth Listener
        return () => Hub.remove('auth', emitter);
    });
    yield takeEvery(channel, eventConsumer);
}