// Form.js
import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ConfirmSignUp from './ConfirmSignUp'
import ForgotPassword from './ForgotPassword'
import ForgotPasswordSubmit from './ForgotPasswordSubmit'
import styles from './Form.module.css'
import commonStyles from './common.module.css'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const initialFormState = {
  username: '', password: '', email: '', confirmationCode: '', error: null
}

async function signUp({ username, password, given_name, family_name, publisher_name }, updateFormType, updateFormError) {
  try {
    updateFormError(undefined);
    await Auth.signUp({
      username, password, attributes: { given_name, family_name, email: username, 'custom:publisher_name': publisher_name }
    })
    updateFormType('confirmSignUp')
  } catch (err) {
    console.error('error signing up..', err)
    updateFormError(err);
  }
}

async function confirmSignUp({ username, confirmationCode }, updateFormType, updateFormError) {
  try {
    updateFormError(undefined);
    await Auth.confirmSignUp(username, confirmationCode)
    updateFormType('signIn');
  } catch (err) {
    console.log('error signing up..', err)
    updateFormError(err);
  }
}

async function signIn({ username, password }, updateFormType, updateFormError) {
  try {
    updateFormError(undefined);
    await Auth.signIn(username, password)
  } catch (err) {
    console.log('error signing up..', err)
    if(err.code === 'UserNotConfirmedException') {
      await Auth.resendSignUp(username);
      updateFormType('confirmSignUp');
      updateFormError({message: "New activation email has been sent to your inbox."});
    } else {
      updateFormError(err);
    }
  }
  return true;
}

async function forgotPassword({ username }, updateFormType, updateFormError) {
  try {
    updateFormError(undefined);
    await Auth.forgotPassword(username)
    updateFormType('forgotPasswordSubmit')
  } catch (err) {
    console.log('error submitting username to reset password...', err)
    updateFormError(err);
  }
}

async function forgotPasswordSubmit({ username, confirmationCode, password }, updateFormType, updateFormError, history) {
  try {
    updateFormError(undefined)
    await Auth.forgotPasswordSubmit(username, confirmationCode, password)
    updateFormType('signIn')
    history.push("/login");
  } catch (err) {
    console.log('error updating password... :', err)
    updateFormError(err);
  }
}

function Form(props) {
  const location = useLocation();
  const [formType, updateFormType] = useState(
    location.pathname == '/reset-password' ? 'forgotPassword' : 'signIn'
  )
  const [formState, updateFormState] = useState(initialFormState)
  const history = useHistory();

  function updateForm(event) {
    const newFormState = {
      ...formState, [event.target.name]: event.target.value
    }
    updateFormState(newFormState)
  }
  function updateFormError(err) {
    const newFormState = {
      ...formState, error: err
    }
    updateFormState(newFormState)
  }

  function renderForm() {
    switch(formType) {
      case 'signUp':
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType, updateFormError)}
            updateFormState={e => updateForm(e)}
            updateFormError={updateFormError}
            error={formState.error}
          />
        )
      case 'confirmSignUp':
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType, updateFormError)}
            updateFormState={e => updateForm(e)}
            error={formState.error}
          />
        )
      case 'signIn':
        return (
          <SignIn
            signIn={() => signIn(formState, updateFormType, updateFormError)}
            updateFormState={e => updateForm(e)}
            updateFormType={e => updateFormType(e)}
            error={formState.error}
          />
        )
      case 'forgotPassword':
        return (
          <ForgotPassword
            forgotPassword={() => forgotPassword(formState, updateFormType, updateFormError)}
            updateFormState={e => updateForm(e)}
            error={formState.error}
          />
        )
      case 'forgotPasswordSubmit':
        return (
          <ForgotPasswordSubmit
            forgotPasswordSubmit={() => forgotPasswordSubmit(formState, updateFormType, updateFormError, history)}
            updateFormState={e => updateForm(e)}
            error={formState.error}
          />
        )
     default:
        return null
    }
  }

  return (
    <div>
      {renderForm()}
      {(formType === 'signUp' || formType === 'signIn') && (
      <div className={styles.footer}>
        {
          formType === 'signUp' && (
            <>
              <p>
                Already have an account?
                <button
                  className={commonStyles.tertiaryButton}
                  onClick={() => {
                    updateFormError(undefined);
                    updateFormType('signIn');
                  }}
                >Sign In</button>
              </p>
            </>
          )
        }
        {
          formType === 'signIn' && (
            <>
              <p>
                Need an account? <button
                  className={commonStyles.tertiaryButton}
                onClick={() => {
                  updateFormError(undefined);
                  updateFormType('signUp');
                }}
                >Sign Up</button>
              </p>
            </>
          )
        }
      </div>)}
    </div>
  )
}

export default Form;
