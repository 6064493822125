// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GoogleTable from './googleTable';
import { fetchAllGoogleATP } from '../../../../store/duck/non-iabvendors';
import Loader from '../../../../components/ui/loader';
import Styles from './table.module.scss';

type Props = {
  data: Array<Object>,
  selectedGoogleVendors: Array<Number>,
  handleChangeGoogleList: Function
}

function GoogleVendors({ data, selectedGoogleVendors, handleChangeGoogleList }: Props) {
  const nonIabVendors = useSelector(state => state.nonIabVendors);
  const vendors = useSelector(state => state.vendors);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!nonIabVendors.googleList.length) {
      dispatch(fetchAllGoogleATP());
    }
  }, []);

  return (
    <>
      {(nonIabVendors.googleList.length === 0 || vendors.v2.pending || vendors.googleVendorWhiteList.pending) && (
        <div className={Styles.loaderWrapper}>
          <Loader />
        </div>
      )}
      {nonIabVendors.googleList.length > 0 && (
        <GoogleTable
          data={data}
          selectedGoogleVendors={selectedGoogleVendors}
          handleChangeGoogleList={handleChangeGoogleList}
        />
      )}
    </>
  );
}

export default GoogleVendors;
