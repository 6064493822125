/* eslint-disable import/prefer-default-export */
import { requestCustomization, requestGoogleATP } from '../../utils/requests';

const url = '/vendors/custom';

export function createNonIabVendor([data]) {
  return requestCustomization
    .post(url, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getAll([pCode]) {
  return requestCustomization
    .get(url, { params: { pCode } })
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getAllGoogle() {
  return requestGoogleATP
    .get()
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function deleteVendor([id]) {
  return requestCustomization
    .delete(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function editNonIabVendor([id, data]) {
  return requestCustomization
    .put(`${url}/${id}`, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getById([id]) {
  return requestCustomization
    .get(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
