import React, { useState } from 'react';
import { Button, Col, Row, Space, DatePicker } from 'antd/es';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import GraphsCard from './graphsCard';
import TrafficConsentCard from './trafficConsentCard';
import Style from '../premiumReports.module.scss';
import FilterDrawer from './filterDrawer';
import {
  customDateFormat,
  getAnalyticsDataForDisplay,
  getAnalyticsPayload,
  getCurrentDefaultDateRange,
  getDateRanges,
  getFilterTags,
  getNumberofDaysInRange,
  shouldDateBeDisabled,
  showGraphsInFullScreen
} from '../utils';
import { useAnalyticsData } from '../../../data/hooks/analytics';
import {
  BAR_LINE_OPTIONS,
  CCPA_CONSENT_STAT,
  DATE_FORMAT,
  DATE_RANGE,
  DEFAULT_APPLIED_FILTERS,
  GDPR_CONSENT_STAT,
  PAGE_SESSIONS_STAT,
  REGION_SESSIONS_STAT, REGULATION_CONSENT_STAT,
  REGULATION_OPTIN_STAT,
  REGULATION_SESSIONS_STAT
} from '../constants';
import Loader from '../../../components/ui/loader';
import TEXT from '../text';
// @flow

const { RangePicker } = DatePicker;

dayjs.extend(weekday);
dayjs.extend(localeData);

interface IAnalyticsViewerProps {
  propertyId: Array<string>;
  pCode: string;
}

const AnalyticsViewer = React.memo(({ propertyId, pCode }: IAnalyticsViewerProps) => {
  const [dateFilter, setDateFilter] = useState(getCurrentDefaultDateRange().map(d => d.format(DATE_FORMAT)));
  const [filterOpen, setFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(DEFAULT_APPLIED_FILTERS);

  const numberOfDays = getNumberofDaysInRange(dateFilter[0], dateFilter[1]);
  const granularity = numberOfDays > 15 ? 'week' : 'day';
  const isFullScreen = showGraphsInFullScreen(numberOfDays);
  const isFilterApplied = Object.values(appliedFilters).some(val => val.length > 0);

  const queriesArr = [
    REGULATION_OPTIN_STAT,
    REGULATION_SESSIONS_STAT,
    REGION_SESSIONS_STAT,
    PAGE_SESSIONS_STAT,
    REGULATION_CONSENT_STAT
  ];
  const analyticsPayload = getAnalyticsPayload(dateFilter, appliedFilters, propertyId, pCode, granularity, queriesArr);
  const { isLoading: isAnalyticsDataLoading, isError, data: analyticsData } = useAnalyticsData(analyticsPayload);
  const formattedData = isAnalyticsDataLoading || isError ? {} : getAnalyticsDataForDisplay(analyticsData);

  const handleApplyFilter = (filterData) => {
    setAppliedFilters(filterData);
    setFilterOpen(false);
  };

  const handleEditFilter = () => {
    setFilterOpen(true);
  };

  const removeAllFilters = () => {
    setAppliedFilters(DEFAULT_APPLIED_FILTERS);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  const deleteFilterItem = (fieldName, item) => {
    const updatedFilters = appliedFilters[fieldName].filter(filterItem => filterItem !== item);
    setAppliedFilters({ ...appliedFilters, [fieldName]: updatedFilters });
  };

  const handleDateChange = (dates) => {
    if (!dates) return;

    const datesArr = dates.map(date => dayjs(date).format(DATE_FORMAT));
    setDateFilter(datesArr);
  };

  return (
    <>
      <Space direction="vertical" size="large" className={Style.analyticsViewer}>
        <Space size="large" className={Style.actions}>
          <p className={Style.title}>{TEXT.INSIGHTS}</p>
          <RangePicker
            onChange={handleDateChange}
            format={customDateFormat}
            disabledDate={d => shouldDateBeDisabled(d, DATE_RANGE)}
            defaultValue={getCurrentDefaultDateRange()}
            ranges={getDateRanges()}
            allowClear={false}
          />
          {!isFilterApplied && (
            <Button className={Style.filterBtn} onClick={() => setFilterOpen(true)}>
              {TEXT.MORE_FILTERS}
            </Button>
          )}
        </Space>
        {isFilterApplied && (
          <Space className={Style.appliedFilters}>
            <Space style={{ flexWrap: 'wrap' }}>{getFilterTags(appliedFilters, deleteFilterItem)}</Space>
            <Space>
              <Button className={Style.button} onClick={handleEditFilter}>
                {TEXT.EDIT_FILTER}
              </Button>
              <Button className={classNames(Style.button, Style.removeBtn)} onClick={removeAllFilters}>
                {TEXT.REMOVE_ALL}
              </Button>
            </Space>
          </Space>
        )}
        {!isAnalyticsDataLoading ? (
          <Row gutter={[32, 32]}>
            <Col span={isFullScreen ? 24 : 12}>
              <GraphsCard
                graphHeader={TEXT.REGULATION_OPTIN_HEADER}
                graphOptions={BAR_LINE_OPTIONS}
                graphData={formattedData[REGULATION_OPTIN_STAT]}
                type="bar"
                labels={{
                  x: 'Date',
                  y: 'Opt-in'
                }}
                isFullScreen={isFullScreen}
                granularity={granularity}
              />
            </Col>
            <Col span={isFullScreen ? 24 : 12}>
              <GraphsCard
                graphHeader={TEXT.REGULATION_SESSION_HEADER}
                graphOptions={BAR_LINE_OPTIONS}
                graphData={formattedData[REGULATION_SESSIONS_STAT]}
                type="line"
                labels={{
                  x: 'Date',
                  y: 'Display Count'
                }}
                isFullScreen={isFullScreen}
                granularity={granularity}
              />
            </Col>
            <Col span={isFullScreen ? 24 : 12}>
              <GraphsCard
                graphHeader={TEXT.PAGE_SESSIONS_HEADER}
                graphOptions={BAR_LINE_OPTIONS}
                graphData={formattedData[PAGE_SESSIONS_STAT]}
                type="line"
                labels={{
                  x: 'Date',
                  y: 'Display Count'
                }}
                isFullScreen={isFullScreen}
                granularity={granularity}
              />
            </Col>
            <Col span={isFullScreen ? 24 : 12}>
              <GraphsCard
                graphHeader={TEXT.REGION_SESSION_HEADER}
                graphOptions={BAR_LINE_OPTIONS}
                graphData={formattedData[REGION_SESSIONS_STAT]}
                type="line"
                labels={{
                  x: 'Date',
                  y: 'Display Count'
                }}
                isFullScreen={isFullScreen}
                granularity={granularity}
              />
            </Col>
            <Col span={12}>
              <TrafficConsentCard
                currentDateFilter={dateFilter}
                appliedFilters={appliedFilters}
                propertyId={propertyId}
                pCode={pCode}
              />
            </Col>
            <Col span={12}>
              <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <Row>
                  <Col span={24}>
                    <GraphsCard
                      graphHeader={TEXT.GDPR_CONSENT_HEADER}
                      graphData={formattedData[GDPR_CONSENT_STAT]}
                      type="pie"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <GraphsCard
                      graphHeader={TEXT.CCPA_CONSENT_HEADER}
                      graphData={formattedData[CCPA_CONSENT_STAT]}
                      type="pie"
                    />
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        ) : (
          <div style={{ position: 'relative' }}>
            <Loader />
          </div>
        )}
      </Space>
      <FilterDrawer
        filterOpen={filterOpen}
        onClose={handleClose}
        onApplyFilter={handleApplyFilter}
        savedFilters={appliedFilters}
      />
    </>
  );
});

export default AnalyticsViewer;
