// @flow
import React from 'react';
import classNames from 'classnames';
import List from '../list';
import Styles from './vendor-information.module.scss';

type Props = {
  vendor: Object;
  policyUrl: string;
  deviceStorageDisclosureUrl: string | null;
  slice?: boolean;
  isGVLVersion3: boolean;
}

function VendorInformation({ vendor, policyUrl, deviceStorageDisclosureUrl, slice, isGVLVersion3 }: Props) {
  const {
    purposes,
    legIntPurposes,
    flexiblePurposes,
    specialPurposes,
    features,
    specialFeatures
  } = vendor;

  return (
    <div className={classNames(Styles.childrenInfo, { [Styles.hidden]: slice })}>
      {isGVLVersion3 ? (
        <>
          {vendor.policyUrl.length && (
          <p>
            <strong>{slice ? 'P' : 'Privacy policy links: '}</strong>
          </p>
          )}
          <div>
            {vendor.policyUrl.map(url => (
              <div key={`${url.langId}-${vendor.id}`}>
                <p>
                  <u>{slice ? 'L' : 'Language:'}</u>
                  {' '}
                  { url.langId.toUpperCase()}
                </p>
                <ul style={{ listStyle: 'none', marginLeft: '10px' }}>
                  { url.privacy
                    && (
                      <li key={`privacy-${url.langId}-${vendor.id}`}>
                        <p>
                          <u>{slice ? 'P' : 'Privacy Policy:'}</u>
                          <a href={url.privacy} target="_blank" rel="noopener noreferrer">
                            {' '}
                            {url.privacy}
                          </a>
                        </p>
                      </li>
                    )
                  }
                  { url.legIntClaim
                    && (
                    <li key={`legitimate-${url.langId}-${vendor.id}`}>
                      <p>
                        <u>{slice ? 'P' : 'Legitimate Interest Claim:'}</u>
                        <a href={url.legIntClaim} target="_blank" rel="noopener noreferrer">
                          {' '}
                          {url.legIntClaim}
                        </a>
                      </p>
                    </li>
                    )
                  }
                </ul>
              </div>
            ))}
          </div>
          <br />
        </>
      ) : (
        <p>
          <strong>{slice ? 'P' : 'Privacy policy: '}</strong>
          {Array.isArray(policyUrl) ? policyUrl.map(policy => (
            <>
              <a target="_blank" rel="noopener noreferrer" href={policy}>
                {policy}
              </a>
              &nbsp;
              &nbsp;
            </>
          )) : (
            <a target="_blank" rel="noopener noreferrer" href={policyUrl}>
              {policyUrl}
            </a>
          )
        }
        </p>
      )
      }

      {deviceStorageDisclosureUrl && (
        <p>
          <strong>{slice ? 'D' : 'Device Storage Disclosure: '}</strong>
          {/* eslint-disable-next-line max-len */}
          <a target="_blank" rel="noopener noreferrer" href={deviceStorageDisclosureUrl}>{deviceStorageDisclosureUrl}</a>
        </p>
      )}
      <List title="Purposes: " list={purposes} slice={slice} />
      <List title="Legitimate Interest Purposes: " list={legIntPurposes} slice={slice} />
      <List title="Flexible Purposes: " list={flexiblePurposes} slice={slice} />
      <List title="Special Purposes: " list={specialPurposes} slice={slice} />
      <List title="Features: " list={features} slice={slice} />
      <List title="Special Features: " list={specialFeatures} slice={slice} />
    </div>
  );
}

VendorInformation.defaultProps = { slice: false };

export default VendorInformation;
