/* eslint-disable import/prefer-default-export */

/**
 * @param {number} status - Error status code
 * @param {string} message - Error message
 * @returns {{message: number, status: string}}
 */
export function buildError({ status, message }) {
  return { status, message };
}
