import { GBC_PURPOSES } from '../actions';
import { buildError } from '../../utils/reducer';

const defState = {
  purposes: [],
  error: {
    status: 200,
    message: ''
  },
  pending: false,
};

export function fetchGBCPurposes() {
  return { type: GBC_PURPOSES.GET.FETCH };
}

function gbcPurposesReducer(state = defState, action) {
  switch (action.type) {
    case GBC_PURPOSES.GET.PENDING: {
      return { ...state, pending: true };
    }
    case GBC_PURPOSES.GET.FULFILLED: {
      return { ...state, purposes: action.payload, pending: false };
    }
    case GBC_PURPOSES.GET.REJECTED: {
      return { ...state, purposes: [], error: buildError(action.payload), pending: false };
    }
    default: {
      return state;
    }
  }
}

export default gbcPurposesReducer;
