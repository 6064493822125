import React from "react";
import { Modal, Form, Select, DatePicker, TimePicker, Input, Button } from "antd/es";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TIME_PERIOD_TYPE } from "../../../utils/constants";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { disabledDate, disabledTime, getDisabledHours } from "../../utils";

const { RangePicker } = DatePicker;

dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleReportModal = ({ isScheduleModalOpen, setIsScheduleModalOpen, onFinish, onFinishFailed, form }) => {
  return (
    <Modal
      title="Schedule Report"
      open={isScheduleModalOpen}
      okButtonProps={{ autoFocus: true, htmlType: "submit" }}
      destroyOnClose
      onOk={() => onFinish(form.getFieldsValue())}
      onCancel={() => setIsScheduleModalOpen(false)}
    >
      <Form
        name="basic"
        labelCol={{
          span: 24
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        preserve={false}
        initialValues={{
          frequency: "daily",
          timePeriod: "lastDay",
        }}
      >
        <Form.Item
          label="Report Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter report name"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Frequency"
          name="frequency"
          rules={[
            {
              required: true,
              message: "Frequency is required"
            }
          ]}
        >
          <Select
            defaultValue="daily"
            onChange={value => form.setFieldValue("frequency", value)}
            options={[
              {
                value: "daily",
                label: "Daily"
              },
              {
                value: "weekly",
                label: "Weekly"
              },
              {
                value: "monthly",
                label: "Monthly"
              }
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
              message: "Please enter start date"
            }
          ]}
          className="form-label"
        >
          <DatePicker style={{ width: "100%" }} disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item
          label="End Date"
          name="endDate"
          rules={[
            {
              required: true,
              message: "Please enter end date"
            }
          ]}
        >
          <DatePicker style={{ width: "100%" }} disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item
          label={`Time (UTC): Select in format "hours: minutes: seconds"`}
          name="time"
          rules={[
            {
              required: true,
              message: "Please enter time"
            }
          ]}
          help={`You cannot select schedule time between 0-7 UTC hrs`}
        >
          <TimePicker style={{ width: "100%" }} disabledTime={disabledTime} showNow/>
        </Form.Item>

        <h6
          style={{
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "0.3px",
            fontSize: "12px",
            marginTop: "16px",
            color: "#1f242e",
            textTransform: "uppercase"
          }}
        >
          Recipients
        </h6>

        <Form.List name="recipients">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div style={{ width: "100%", display: "flex", gap: "8px", alignItems: "center", marginTop: "8px" }}>
                  <Form.Item
                    {...restField}
                    name={[name, "first"]}
                    style={{ width: "100%", margin: "0" }}
                  >
                    <Input placeholder="Email Address" style={{ width: "100%" }} />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button type="link" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add New
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default ScheduleReportModal;
