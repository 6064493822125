/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { NON_IABVENDORS, BUILD, ALERTS } from '../actions';
import {
  createNonIabVendor,
  deleteVendor,
  getAll,
  editNonIabVendor,
  getById,
  getAllGoogle
} from '../requests/non-iabvendors';
import { formatDataToSubmit } from '../../utils/noniabvendors';

export function* fetchCreateNoIabVendor({ payload: { vendorData, pCode, amountOfVendors } }) {
  yield put({ type: NON_IABVENDORS.CREATE.PENDING });

  const { res, err } = yield call(createNonIabVendor, [formatDataToSubmit(vendorData)]);

  if (res) {
    yield put({ type: NON_IABVENDORS.CREATE.FULFILLED, payload: res.data });
    if (amountOfVendors === 0) yield put({ type: BUILD.BATCH_UPDATE_CHOICEJS.FETCH, payload: { pCode } });
    yield put({ type: BUILD.NON_IAB_VENDOR.FETCH, payload: { pCode } });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: 'You have added this vendor to be shown in your CMP',
        description: '',
        closable: true,
        autoClose: true
      }
    });
  } else {
    yield put({ type: NON_IABVENDORS.CREATE.REJECTED, payload: err.response });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Cannot add vendor ${vendorData.name}`,
        description: '',
        closable: true,
        autoClose: true
      }
    });
  }
}

export function* fetchAllNonIABVendors({ payload }) {
  yield put({ type: NON_IABVENDORS.GET_ALL.PENDING });
  const { res, err } = yield call(getAll, [payload.pcode]);
  if (res) {
    const data = Array.isArray(res.data) ? res.data : [];
    yield put({ type: NON_IABVENDORS.GET_ALL.FULFILLED, payload: data });
  } else {
    yield put({ type: NON_IABVENDORS.GET_ALL.REJECTED, payload: err.response });
  }
}

export function* fetchAllGoogle() {
  yield put({ type: NON_IABVENDORS.GET_GOOGLE_ATP.PENDING });

  const { res, err } = yield call(getAllGoogle);

  if (res) {
    if (!res.data[res.data.length - 1].provider_id) res.data.pop();
    yield put({ type: NON_IABVENDORS.GET_GOOGLE_ATP.FULFILLED, payload: res.data });
  } else {
    yield put({ type: NON_IABVENDORS.GET_GOOGLE_ATP.REJECTED, payload: { data: err } });
  }
}

export function* fetchDeleteVendor({ payload: { vendor: { nonIabId, pCode }, amountOfVendors } }) {
  yield put({ type: NON_IABVENDORS.DELETE.PENDING });
  const { res, err } = yield call(deleteVendor, [nonIabId]);

  if (res) {
    yield put({ type: NON_IABVENDORS.DELETE.FULFILLED, payload: nonIabId });
    if (amountOfVendors === 1) yield put({ type: BUILD.BATCH_UPDATE_CHOICEJS.FETCH, payload: { pCode } });
    yield put({ type: BUILD.NON_IAB_VENDOR.FETCH, payload: { pCode } });
  } else {
    yield put({ type: NON_IABVENDORS.DELETE.REJECTED, payload: err.response });
  }
}

export function* fetchEditNonIab({ payload }) {
  yield put({ type: NON_IABVENDORS.EDIT.PENDING });
  const { res, err } = yield call(editNonIabVendor, [payload.nonIabId, formatDataToSubmit(payload.data)]);
  const { data: { pCode } } = payload;

  if (res) {
    yield put({ type: BUILD.NON_IAB_VENDOR.FETCH, payload: { pCode } });
    yield put({ type: NON_IABVENDORS.EDIT.FULFILLED, payload: res.data });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `You have edited ${payload.data.name} vendor successfully`,
        description: '',
        closable: true,
        autoClose: true
      }
    });
  } else {
    yield put({ type: NON_IABVENDORS.EDIT.REJECTED, payload: err.response });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Cannot edit vendor ${payload.data.name}`,
        description: '',
        closable: true,
        autoClose: true
      }
    });
  }
}

export function* fetchNonIabVendorById({ payload }) {
  yield put({ type: NON_IABVENDORS.GET_BY_ID.PENDING });

  const { res, err } = yield call(getById, [payload.nonIabId]);

  if (res) {
    yield put({ type: NON_IABVENDORS.GET_BY_ID.FULFILLED, payload: res.data });
  } else {
    yield put({ type: NON_IABVENDORS.GET_BY_ID.REJECTED, payload: err.response });
  }
}
