// @flow
/* eslint-disable import/prefer-default-export */

export function buildTableData(data: any): Array<any> {
  return Object.keys(data).length
    ? data.map((vendor) => {
      const vendorInfo = { ...vendor };
      if (vendor.privacyPolicyUrl || vendor.description || vendor.legitimateInterestClaimUrl) {
        vendorInfo.children = [{
          nonIabId: `c-${vendor.nonIabId}`,
          policyUrl: vendor.privacyPolicyUrl,
          description: vendor.description,
          legitimateInterestClaimUrl: vendor.legitimateInterestClaimUrl
        }];
      }

      return vendorInfo;
    })
    : [];
}

export function formatDataToSubmit(data: any): Object {
  const formData = { ...data };

  Object.keys(formData).forEach((key) => {
    if (key === 'privacyPolicyUrl' && formData[key] !== '') {
      formData[key] = /^(?:http(s)?:\/\/)/i.test(formData[key]) ? formData[key] : `http://${formData[key]}`;
    }
    if (formData[key] === '' || formData[key] === null) delete formData[key];
    return false;
  });

  return formData;
}
