// @flow
import React, { useState } from 'react';
import Form from 'antd/es/form';
import Card from 'antd/es/card';
import Radio from 'antd/es/radio';
import Input from 'antd/es/input';
import Space from 'antd/es/space';
import classNames from 'classnames';
import uid from 'uid';
import Styles from '../../../../components/forms/sites/sitesForm.module.scss';
import Select from '../../../../components/ui/select';
import InfoIconTooltip from '../../../../components/ui/infoIconTooltip';
import FormStyles from '../../../../scss/_forms.module.scss';
import { HEADER_REGULATION_TEXT, encodingOptions } from '../../../../utils/constants';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import validate from '../../../../components/forms/sites/syncValidation';
import Alert from '../../../../components/ui/alert';

type Props = {
  consentScopeIsGroup: boolean;
  setDataTestId: Function;
  getFieldValue: Function;
}

function ConsentScope({
  consentScopeIsGroup,
  setDataTestId,
  getFieldValue
}: Props) {
  const [gdprEncodingMode, setGdprEncodingMode] = useState(getFieldValue('gdprEncodingMode'));

  const consentScopeOptions = [
    { value: 'service', label: 'Your app only' }
  ];
  const { Option } = Select;
  const { repromtDaysAfterGvlUpdate } = validate();
  return (
    <Card
      className={Styles.consentCard}
      title={[
        <span key={uid()}>Consent scope</span>,
        <span className={Styles.cardSubtitle} key={uid()}>What purposes should be notified to end-users</span>
      ]}
      extra={HEADER_REGULATION_TEXT.GDPR}
    >
      <>
        <Form.Item
          name="consentScope"
          tooltip={{
            title: SITETOOLTIPS.CONSENT_SCOPE,
            icon: <span><InfoIconTooltip /></span>,
          }}
          label="CONSENT SCOPE: ASK USER FOR CONSENT FOR..."
        >
          <Select>
            {consentScopeOptions.map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>{opt.label}</Option>
            ))}
          </Select>
        </Form.Item>
        {consentScopeIsGroup && (
          <section>
            <p>
              <strong>NOTE:</strong>
              &nbsp;By choosing a Group option, you must provide a Group Domain. This requires a deployment of a JSON
              API to read/set cookies. See&nbsp;
              <a
                target="technical_impletation"
                // eslint-disable-next-line
                href="https://help.quantcast.com/hc/en-us/articles/13422563777435-Sharing-Consent-Between-Multiple-Sites-"
              >
                Technical Implementation Guide.
              </a>
            </p>
            <Form.Item
              name="consentScopeGroupURL"
              label="CONSENT SCOPE GROUP URL"
              required
            >
              <Input
                placeholder="Paste URL path here"
              />
            </Form.Item>
            <Form.Item label="Storage Mode" required>
              <section className={Styles.regulationCheckboxes}>
                <Radio.Group
                  data-testid={setDataTestId('thirdPartyStorageType')}
                >
                  <Radio value="iframe">Iframe</Radio>
                  <Radio value="api">API</Radio>
                </Radio.Group>
              </section>
            </Form.Item>
          </section>
        )}
        <div className={FormStyles['wrapper-inline']}>
          <section className={classNames(FormStyles['inline-fields'], [Styles.twoColumns])}>
            {/* TODO: To be enabled when mobile CMP supports redirectUrl */}
            {/* <Input
              input={buildInput('redirectUrl')}
              meta={buildMeta('redirectUrl')}
              label="NON-CONSENT REDIRECT URL"
              placeholder="Enter URL"
            /> */}
            <Form.Item
              label="SHOW NEW VENDORS EVERY..."
              name="repromtDaysAfterGvlUpdate"
              rules={repromtDaysAfterGvlUpdate}
            >
              <Input
                max={350}
                min={0}
                suffix="Days"
                placeholder={30}
                type="number"
                data-testid={setDataTestId('repromtDaysAfterGvlUpdate')}
              />
            </Form.Item>
          </section>
        </div>
        <Space direction="vertical" size={0}>
          <Form.Item
            label={<span>What is the string format for encoding GDPR consent?</span>}
            tooltip={{
              title: SITETOOLTIPS.gdprEncodingModeApp,
              icon: (<span><InfoIconTooltip /></span>)
            }}
            name="gdprEncodingMode"
            onChange={e => setGdprEncodingMode(e.target.value)}
            style={{ marginBottom: '10px' }}
          >
            <Radio.Group>
              {encodingOptions.map(option => <Radio value={option.value}>{option.label}</Radio>)}
            </Radio.Group>
          </Form.Item>
          {
            gdprEncodingMode === 'TCF' && (
              <Alert
                type="warning"
                message={
                    `Some of your ad networks might not currently (or in future) be compliant with
                    TCF but instead support GPP, hence its recommended to select both GPP and TCF.`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
          {
            gdprEncodingMode === 'GPP' && (
              <Alert
                type="warning"
                message={
                    `Some of your ad networks might not be compliant with GPP yet, hence
                    its recommended to select both GPP and TCF`
                }
                className={Styles.warningAlert}
                showIcon
              />
            )
          }
        </Space>
      </>
    </Card>
  );
}

export default ConsentScope;
