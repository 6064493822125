import { REPORT_TYPES } from "../../../../utils/constants";

export const INITIAL_STATE = {
  dimensionOpen: false,
  filterOpen: false,
  metricOpen: false,
  isScheduleModalOpen: false,
  granularity: "day",
  reportName: "",
  payload: {},
  saveRunJobPayload: {},
  customDate: [],
  customDateString: "",
  columns: [],
  reportType: REPORT_TYPES
};

export const ACTION_TYPES = {
  TOGGLE_DIMENSION_OPEN: "TOGGLE_DIMENSION_OPEN",
  TOGGLE_FILTER_OPEN: "TOGGLE_FILTER_OPEN",
  TOGGLE_METRIC_OPEN: "TOGGLE_METRIC_OPEN",
  TOGGLE_SCHEDULE_MODAL: "TOGGLE_SCHEDULE_MODAL",
  SET_SELECTED_GRANULARITY: "SET_SELECTED_GRANULARITY",
  SET_REPORT_NAME: "SET_REPORT_NAME",
  SET_PAYLOAD: "SET_PAYLOAD",
  SET_SAVE_RUN_JOB_PAYLOAD: "SET_SAVE_RUN_JOB_PAYLOAD",
  SET_CUSTOM_DATE: "SET_CUSTOM_DATE",
  SET_CUSTOM_DATE_STRING: "SET_CUSTOM_DATE_STRING",
  SET_COLUMNS: "SET_COLUMNS",
  SET_REPORT_TYPE: "SET_REPORT_TYPE"
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_DIMENSION_OPEN:
      return { ...state, dimensionOpen: !state.dimensionOpen };
    case ACTION_TYPES.TOGGLE_FILTER_OPEN:
      return { ...state, filterOpen: !state.filterOpen };
    case ACTION_TYPES.TOGGLE_METRIC_OPEN:
      return { ...state, metricOpen: !state.metricOpen };
    case ACTION_TYPES.TOGGLE_SCHEDULE_MODAL:
      return { ...state, isScheduleModalOpen: !state.isScheduleModalOpen };
    case ACTION_TYPES.SET_SELECTED_GRANULARITY:
      return { ...state, granularity: action.payload };
    case ACTION_TYPES.SET_REPORT_NAME:
      return { ...state, reportName: action.payload };
    case ACTION_TYPES.SET_PAYLOAD:
      return { ...state, payload: action.payload };
    case ACTION_TYPES.SET_SAVE_RUN_JOB_PAYLOAD:
      return { ...state, saveRunJobPayload: action.payload };
    case ACTION_TYPES.SET_CUSTOM_DATE:
      return { ...state, customDate: action.payload };
    case ACTION_TYPES.SET_CUSTOM_DATE_STRING:
      return { ...state, customDateString: action.payload };
    case ACTION_TYPES.SET_COLUMNS:
      return { ...state, columns: action.payload };
    case ACTION_TYPES.SET_REPORT_TYPE:
      return { ...state, reportType: action.payload };
    default:
      return state;
  }
};
