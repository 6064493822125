/* eslint-disable import/prefer-default-export */
import { requestBuildV2, requestCustomizationV2 } from '../../utils/requests';

const url = '/app';

export function createApp([data]) {
  return requestCustomizationV2
    .post(url, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function editApp([id, data]) {
  return requestCustomizationV2
    .put(`${url}/${id}`, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getById([id]) {
  return requestCustomizationV2
    .get(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function deleteApp([id]) {
  return requestCustomizationV2
    .delete(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

/**
 * Delete app-config.json from choice s3 bucket
 * @param pCode
 * @param packageId
 * @returns {Promise<{res: AxiosResponse<any>} | {err: any}>}
 */
export function deleteAppFromChoice([pCode, packageId]) {
  return requestBuildV2
    .delete(`${url}/${packageId}/pCode/${pCode}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
