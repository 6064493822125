import React, { useState } from 'react'
import Spin from '../../components/ui/spin';

import commonStyles from './common.module.css'

function errorMessage(error) {
  return error.message || error.log || error.code;
}

function ForgotPasswordSubmit({ forgotPasswordSubmit, updateFormState, updateFormType, error }) {
  const [loading, setLoading] = useState(false);

  const forgotPasswordSubmitNow = (e) => {
    setLoading(true);
    e.preventDefault(); 
    forgotPasswordSubmit().finally(() => {
      setLoading(false);
    });
    return false;
  }

  return (
    <>
      <div className={commonStyles.heading}>
        Forgot Password
      </div>
      {error && (<span className={commonStyles.error}>{errorMessage(error)}</span>)}
      <form className={commonStyles.formContainer} onSubmit={forgotPasswordSubmitNow}>
        <input
          name='confirmationCode'
          placeholder='Confirmation code'
          onChange={e => {e.persist();updateFormState(e)}}
        />
        <input
          name='password'
          placeholder='New password'
          type='password'
          onChange={e => {e.persist();updateFormState(e)}}
        />
        <button type='submit' className={commonStyles.primaryButton}>
          {loading ? (<Spin size='large' />) : (<>Save new password</>)}
        </button>
      </form>
    </>
  )
}

export default ForgotPasswordSubmit