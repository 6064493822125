/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { CMP_VERSIONS_V2 } from '../actions';
import { getCMPVersionsV2 } from '../requests/versionsV2';

export function* fetchCMPVersionsV2({ payload }) {
  yield put({ type: CMP_VERSIONS_V2.GET.PENDING });

  const { res, err } = yield call(getCMPVersionsV2, [payload.pCode]);

  if (res) {
    yield put({
      type: CMP_VERSIONS_V2.GET.FULFILLED,
      payload: [
        ...res.data
      ]
    });
  } else {
    yield put({ type: CMP_VERSIONS_V2.GET.REJECTED, payload: err.response });
  }
}
