/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'antd/es/button';
import Card from '../../components/ui/card';
import ContentHeader from '../../components/ui/content-header';
import Result from '../../components/ui/result';
import { messages } from '../../utils/constants';
import { BACK_TO_ALL_PROPERTIES_LABEL } from '../bulk-update/constants';
import { fetchBulkUpdateStatus } from '../../store/duck/bulk-updates';
import withNavbar from '../../components/hoc/with-navbar';
import Spin from '../../components/ui/spin';
import './styles.scss';

const { CardContent } = Card;
const BULK_UPDATE_STATUS_TITLE = 'Bulk Update Status';
const BULK_UPDATE_STATUS_REFRESH_LABEL = 'Refresh';
const COMPLETED = 'COMPLETED';
const RUNNING = 'RUNNING';
const WAITING = '-';

const BulkUpdateStatusPage = () => {
  const app = useSelector(state => state.app);
  const { bulkUpdateStatus, response } = useSelector(state => state.bulkUpdates.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (app && !app.loading && app.pCode) {
      dispatch(fetchBulkUpdateStatus({ pCode: app.pCode, page: 'status' }));
    }
  }, [app]);

  const error = response && response.code !== 200 ? response.message : false;

  const currentStatus = (bulkUpdateStatus && (bulkUpdateStatus.status === 'SUCCESSFUL' || bulkUpdateStatus.status === 'FAILED')) ? COMPLETED : RUNNING;

  const statusLabel = bulkUpdateStatus.done ? currentStatus : WAITING;

  return (
    <>
      <ContentHeader
        title={BULK_UPDATE_STATUS_TITLE}
        backBtn={{
          goTo: `p-${app.pCode}/properties`,
          text: BACK_TO_ALL_PROPERTIES_LABEL
        }}
      />
      {
        <Spin size="large" spinning={!bulkUpdateStatus.done}>
          <Card className="bulk-update-status">
            <CardContent>
              {error ? (
                <Result
                  status="error"
                  title={messages.BULK_UPDATES_STATUS_ERROR}
                  subTitle={response.message}
                />
              ) : (
                <>
                  <p>
                    <span>Bulk update process status - </span><span style={{ color: '#35aad8' }}>{ statusLabel }</span>
                  </p>
                  <p><span>Detail:</span></p>
                  <p>
                    <span>User who initiated the process: </span>{bulkUpdateStatus.initiatedBy}
                  </p>
                  <p>
                    <span>Date/Time when the process started: </span>{new Date(bulkUpdateStatus.lastUpdatedOn).toLocaleString()}
                  </p>
                  <p><span>Number of updated sites: </span>{bulkUpdateStatus.successful} / {bulkUpdateStatus.total}
                  </p>
                  <p><span>Number of failed sites: </span>{bulkUpdateStatus.failed} / {bulkUpdateStatus.total} </p>
                  <p><span>Number of pending sites: </span>{bulkUpdateStatus.pending} / {bulkUpdateStatus.total} </p>
                  <Button
                    onClick={() => dispatch(fetchBulkUpdateStatus({ pCode: app.pCode, page: 'status' }))}
                    disabled={currentStatus === COMPLETED}
                  >
                    {BULK_UPDATE_STATUS_REFRESH_LABEL}
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </Spin>
      }
    </>
  );
};

export default withNavbar(BulkUpdateStatusPage);
