// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Radio from 'antd/es/radio';
import uid from 'uid';
import Restrictions from '../restrictions';
import { fetchAllVendors } from '../../../../store/duck/vendors';
import { fetchStacks } from '../../../../store/duck/stacks';
import Styles from '../../../../components/ui/recommended-list/purposesV2.module.scss';
import RecommendedList from '../../../../components/ui/recommended-list/recommendedList';
import Loader from '../../../../components/ui/loader';
import Modal from '../../../../components/ui/modal';
import Stacks from '../../../../components/forms/themes/components/purposes-v2/components/stacks';
import { GVL_VERSION_2, GVL_VERSION_3 } from '../../../../utils/constants';
import { getLegitimateConsentIds } from '../../../../utils/vendors';

type Props = {
  onChange: Function;
  values: Object;
  initData: Object;
  use: string;
  userSelectedStack: Boolean;
  stackIds: Array;
  setUserSelectedStack: Function;
  pCode: string;
  handleCustomization: Function;
  vendorsData: Object;
};

function CustomizePurposes({
  onChange,
  values,
  initData,
  use,
  userSelectedStack,
  stackIds,
  setUserSelectedStack,
  pCode,
  handleCustomization,
  vendorsData,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [componentValues, setValues] = useState({});
  const [restrictions, setRestrictions] = useState({});
  const [stackList, setStackList] = useState([]);
  const [selectedStack, setSelectedStack] = useState([]);
  const [selectedStackId, setSelectedStackId] = useState(0);
  const [restrictionsModal, setRestrictionsModal] = useState(false);
  const [callRecommendationStacks, setCallRecommendationStacks] = useState(false);
  const [purposes, setPurposes] = useState(initData.vendorConfig.vendorPurposeIds);
  const [legitimateInterestPurposes, setLegitimateInterestPurposes] = useState(
    initData.vendorConfig.vendorPurposeLegitimateInterestIds
  );
  const [features, setFeatures] = useState(initData.vendorConfig.vendorFeaturesIds);
  const [specialFeatures, setSpecialFeatures] = useState(initData.vendorConfig.vendorSpecialFeaturesIds);
  const [specialPurposes, setSpecialPurposes] = useState(initData.vendorConfig.vendorSpecialPurposesIds);
  const [flexiblePurposes, setFlexiblePurposes] = useState([]);
  const { pending, list } = useSelector(state => state.stacks);
  const dispatch = useDispatch();
  const addSpecialPurposes = item => ({
    ...item,
    specialPurposes,
    features
  });

  useEffect(() => {
    if (!userSelectedStack && !stackIds.length && stackList && stackList.length) {
      onChange({
        stacks: [...stackList[0].stacks],
        restrictions: { publisherConsentRestrictionIds: [], publisherLIRestrictionIds: [] }
      });
    } else {
      onChange({
        restrictions: {
          publisherConsentRestrictionIds: componentValues.publisherConsentRestrictionIds || [],
          publisherLIRestrictionIds: componentValues.publisherLIRestrictionIds || []
        }
      });
    }
  }, [stackList]);

  const getRecommendedList = () => {
    if (use === 'stacks') {
      if (!pending && stackList.length) {
        const stackToUse = userSelectedStack
          ? {
            ...stackList.find((item, index) => {
              const sameStack = JSON.stringify(item.stacks) === JSON.stringify(stackIds);
              if (sameStack && selectedStackId !== index) {
                setSelectedStackId(index);
              }
              return sameStack;
            }),
            ...features
          }
          : { ...stackList[0], features };
        return (
          <>
            <RecommendedList
              stackList={Object.keys(stackToUse).length ? stackToUse : { ...list[0], features }}
              purposesData={vendorsData}
            />
            <Button
              type="secondary"
              onClick={() => setModalOpen(true)}
              disabled={list.length <= 1}
            >
              Configure
            </Button>
          </>
        );
      }
      return (<Loader />);
    }

    if (
      purposes
      && specialFeatures
      && legitimateInterestPurposes
      && specialPurposes
      && features
    ) {
      return (
        <RecommendedList
          stackList={{
            publisherPurposeConsentIds: purposes,
            publisherSpecialFeaturesIds: specialFeatures,
            stacks: [],
            publisherPurposeLegitimateInterestIds: legitimateInterestPurposes,
            specialPurposes,
            features
          }}
          purposesData={vendorsData}
        />
      );
    }

    return (<Loader />);
  };

  useEffect(() => {
    const initial = {
      publisherConsentRestrictionIds: values.vendorConfig.publisherConsentRestrictionIds || [],
      publisherLIRestrictionIds: values.vendorConfig.publisherLIRestrictionIds || [],
      consentRestrictionsValues: {},
      liRestrictionsValues: {}
    };

    setValues(initial);
    setRestrictions(initial);
  }, []);

  const cleanLastSelectedRestrictions = () => {
    setValues({ ...restrictions });
  };

  const formatDataOnSubmit = () => {
    const data = {
      publisherConsentRestrictionIds: componentValues.publisherConsentRestrictionIds || [],
      publisherLIRestrictionIds: componentValues.publisherLIRestrictionIds || []
    };
    setRestrictions({ ...componentValues });
    onChange({ restrictions: data }, true);
  };

  const onCancelClick = () => {
    cleanLastSelectedRestrictions();
    setRestrictionsModal(false);
  };

  const footer = (
    <Button.Group className={Styles.footerButtons}>
      <Button
        type="secondary"
        onClick={onCancelClick}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => {
          formatDataOnSubmit();
          setRestrictionsModal(false);
        }}
      >
        Ok
      </Button>
    </Button.Group>
  );

  const onChangeForm = (input, data) => {
    if (input === 'restrictions') {
      const consentRestrictionsValues = {};
      const liRestrictionsValues = {};
      data.consent.forEach((item) => {
        consentRestrictionsValues[`CONSENT_${item}`] = true;
      });
      data.legitimate.forEach((item) => {
        liRestrictionsValues[`LI_${item}`] = true;
      });
      setValues({
        liRestrictionsValues,
        consentRestrictionsValues,
        publisherLIRestrictionIds: data.legitimate || [],
        publisherConsentRestrictionIds: data.consent || [],
      });
    }
  };

  useEffect(() => {
    if (!(Object.keys(vendorsData).length)) {
      dispatch(fetchAllVendors(pCode, values.gvlVersion));
    }
  }, [values.gvlVersion]);

  useEffect(() => {
    if (
      use === 'stacks'
      && (callRecommendationStacks
        || (!list.length || (list.length && list[0] && list[0].stacks && !list[0].stacks.length))
      )
    ) {
      dispatch(fetchStacks(
        purposes,
        legitimateInterestPurposes,
        specialFeatures
      ));

      if (callRecommendationStacks) {
        setCallRecommendationStacks(false);
      }
    }

    if (list.length) {
      if (use === 'stacks' && list[0].stacks.length === 0) {
        if (list[0].stacks.length > 0) {
          onChange(preValues => ({
            ...preValues,
            stacks: list[0].stacks,
            restrictions: {
              publisherConsentRestrictionIds: componentValues.publisherConsentRestrictionIds || [],
              publisherLIRestrictionIds: componentValues.publisherLIRestrictionIds || []
            }
          }));
        } else {
          setValues({ ...values, use: 'purposes' });
        }
      }
      setStackList(list.map(addSpecialPurposes));
    }
  }, [use, list, callRecommendationStacks]);

  useEffect(() => {
    const filteredVendors = getLegitimateConsentIds(vendorsData);

    setPurposes(filteredVendors.vendorPurposeIds);
    setLegitimateInterestPurposes(filteredVendors.vendorPurposeLegitimateInterestIds);
    setFeatures(filteredVendors.vendorFeaturesIds);
    setSpecialFeatures(filteredVendors.vendorSpecialFeaturesIds);
    setSpecialPurposes(filteredVendors.vendorSpecialPurposesIds);
    setFlexiblePurposes(filteredVendors.vendorFlexiblePurposesIds);

    handleCustomization(filteredVendors);
  }, [vendorsData]);

  const handleTcfVersion = (event) => {
    event.persist();
    onChange({ gvlVersion: Number(event.target.value) }, true);
    setCallRecommendationStacks(true);
  };

  return (
    <>
      <Card
        title={[
          <div className={Styles.customizeHeader} key={uid()}>
            <h3>
              Customize Purpose Screen
              &nbsp;
              <span>This allows you to customize how you present the purposes within your CMP</span>
            </h3>
          </div>
        ]}
        extra={[<h3 className={Styles.rightSection} key={uid()}>GDPR</h3>]}
      >
        <div className={Styles.optionsContainer}>
          <Form.Item
            name="gvlVersion"
            label="TCF Version"
            className={Styles.consentsRadios}
            onChange={handleTcfVersion}
          >
            <Radio.Group
              options={[
                { value: GVL_VERSION_2, label: 'TCF 2.0', disabled: false },
                { value: GVL_VERSION_3, label: 'TCF 2.2', disabled: false },
              ]}
            />
          </Form.Item>
        </div>
        <section className={Styles.usePurposes}>
          <Form.Item
            name="use"
          >
            <Radio.Group
              className={Styles.consentsRadios}
              onChange={e => onChange({ use: e.target.value }, true)
              }
            >
              <Radio
                value="purposes"
              >
                Use Purposes
              </Radio>
              <Radio
                value="stacks"
              >
                Use Stacks
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Button
            type="link"
            disabled={(flexiblePurposes && !(flexiblePurposes.length))}
            onClick={() => setRestrictionsModal(true)}
          >
            CONFIGURE RESTRICTIONS
          </Button>
        </section>
        {getRecommendedList()}
        <Modal
          title="Customize Stacks"
          onClose={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          open={modalOpen}
          destroyOnClose
          className={Styles.modalSize}
          onOk={() => {
            setModalOpen(false);
            setUserSelectedStack(true);
            onChange({ stacks: selectedStack }, true);
          }}
        >
          <Stacks
            purposeIds={purposes}
            purposeLegitimateIds={legitimateInterestPurposes}
            specialFeatures={specialFeatures}
            specialPurposes={specialPurposes}
            features={features}
            onChange={stack => setSelectedStack(stack)}
            list={list}
            pending={pending}
            selectedStack={selectedStackId}
            purposesData={vendorsData}
          />
        </Modal>
        <Modal
          title="Configure restrictions"
          onClose={onCancelClick}
          onCancel={onCancelClick}
          open={restrictionsModal}
          destroyOnClose
          className={Styles.modalSize}
          footer={footer}
        >
          <Restrictions
            flexiblePurposes={flexiblePurposes}
            onChange={onChangeForm}
            values={componentValues}
            purposesList={vendorsData.purposes}
          />
        </Modal>
      </Card>
    </>
  );
}

export default CustomizePurposes;
