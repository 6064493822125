/* eslint-disable import/prefer-default-export */

// @flow

export function sortBy(array: Array<any>, sort: string): Array<any> {
  return array.sort((a, b) => {
    if (a[sort].toUpperCase() < b[sort].toUpperCase()) return -1;
    if (a[sort].toUpperCase() > b[sort].toUpperCase()) return 1;
    return 0;
  });
}
