import React from 'react';

const TEXT = {
  CARD_HEADER_V2: 'Do not sell my data',
  HEADLINE_V2: 'Title of the MSPA message',
  BODY_V2: 'Body of the MSPA message',
  SUPRESSLINKS_V2: 'Suppress MSPA Links',
  CARD_HEADER_V1: 'Customize Do Not Sell My Data Screen',
  HEADLINE_V1: 'HEADLINE TEXT',
  BODY_V1: 'BODY TEXT',
  SUPRESSLINKS_V1: 'Suppress CCPA Links',
  HEADLINE_PLACEHOLDER: '[Publisher Name] - Do Not Sell My Data',
  BODY_PLACEHOLDER: `When you visit this website, we collect personal information such as IP addresses,
  cookie identifiers and other pseudonymous identifiers.
  This information may be used to personalize content based on your interests,
  run and optimize advertising campaigns tailored to you,
  measure the performance of ads and content,
  and derive insights about the audiences who engage with ads and content.
  This information may also be disclosed by us to third parties on the IAB's List of Downstream
  Participants that may further disclose it to other third parties.
  Using personal information as described above is an integral part of how we operate our website,
  make revenue to support our staff, and generate relevant content for our audience.
  You can learn more about our data collection and use practices in our Privacy Policy.
  If you wish to opt out of the disclosure of your personal information to third parties by us,
  please use the below opt out and confirm your selection.
  Please note that after your opt out request is processed,
  you may continue seeing interest-based ads based on personal Information
  utilized by us or personal information disclosed to third parties prior to your opt out.
  You may separately opt out of the further disclosure of your personal information by third parties on
  the IAB's List of Downstream Participants.`,
  DELETION_LINK: 'DATA DELETION LINK',
  ACCESS_LINK: 'DATA ACCESS LINK',
  PRIVACY_POLICY_LINK: 'PRIVACY POLICY LINK',
  PREVIEW: 'Preview'
};

export default TEXT;
