import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Select, Popconfirm, Button, Dropdown, notification, Tag, Radio } from "antd/es";
import { useActiveDeactivateJob, useDeleteReport, useGetReportsData } from "../../../data/hooks/analytics";
import { REPORT_STATUS, REPORT_TYPES } from "../../../utils/constants";
import { DeleteOutlined, DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import Style from "../premiumReports.module.scss";
import { connect } from "react-redux";
import withPcode from "../../../components/hoc/withPcode";
import withNavbar from "../../../components/hoc/with-navbar";
import TEXT from "../text";
import { formatBytes } from "../../utils";

const ViewReports = ({ app, setTabId }) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const type = urlParams.get("report_type");

  const [reportType, setReportType] = useState(type == REPORT_TYPES.SCHEDULED ? REPORT_TYPES.SCHEDULED : REPORT_TYPES.ON_DEMAND);
  const [deletedJobId, setDeletedJobId] = useState(null);
  const [updatedJobId, setUpdatedJobId] = useState(null);
  const [updatedJobStatus, setUpdatedJobStatus] = useState(null);
  const [reportName, setReportName] = useState("");

  const { data: reports, isLoading, refetch } = useGetReportsData(app.pCode, reportType, reportName);

  const {
    data: deletedData,
    isLoading: deletedJobLoading,
    isError: deletedJobError,
    refetch: refetchDeleteJob,
    isSuccess: deletedJobSuccess
  } = useDeleteReport(app.pCode, deletedJobId, false);

  const { refetch: refetchUpdateJob, isSuccess: updateJobSuccess } = useActiveDeactivateJob(app.pCode, updatedJobId, updatedJobStatus, false);

  useEffect(() => {
    refetch();
  }, [reportType, deletedData]);

  useEffect(() => {
    refetchDeleteJob();
  }, [deletedJobId]);

  useEffect(() => {
    refetchUpdateJob();
  }, [updatedJobId]);

  useEffect(() => {
    if (deletedJobSuccess) {
      setDeletedJobId(null);
      notification.success({
        message: TEXT.REPORT_DELETION_SUCCESS
      });
    }
  }, [deletedJobSuccess]);

  useEffect(() => {
    if (updateJobSuccess) {
      setUpdatedJobId(null);
      notification.success({
        message: TEXT.REPORT_UPDATE_SUCCESS
      });
    }
  }, [updateJobSuccess]);

  const dataSource = reports ? reports.data : [];

  const confirm = row => {
    console.log(row);
    setDeletedJobId(row.id);
  };

  const cancel = e => {
    console.log(e);
  };

  const getTagByStatus = status => {
    if (status == "PENDING") {
      return <Tag color="orange">Pending</Tag>;
    } else if (status == "RUNNING") {
      return <Tag color="blue">Running</Tag>;
    } else if (status == "COMPLETED") {
      return <Tag color="green">Completed</Tag>;
    } else if (status === "FAILED") {
      return <Tag color="red">Failed</Tag>;
    } else {
      return <Tag color="default">-</Tag>;
    }
  };

  const onDemandColumns = (deleteConfirm, deleteCancel) => [
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
      width: 400,
      render: (name, row) => {
        return <Link to={`/protect/p-${app.pCode}/reports?report_id=${row.id}`}>{name}</Link>;
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: status => {
        return getTagByStatus(status);
      }
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 200,
      render: size => {
        return size ? formatBytes(size) : "-";
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (_, row) => {
        return (
          <>
            <Button
              type="link"
              icon={<DownloadOutlined />}
              disabled={!row.fileUrl}
              onClick={() => window.open(row.fileUrl, "_blank")}
            >
              Download
            </Button>

            <Popconfirm
              title="Delete Report?"
              description="Are you sure to delete this report?"
              onConfirm={() => deleteConfirm(row)}
              onCancel={deleteCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} type="link" danger></Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const scheduledColumns = setUpdatedJob => [
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name, row) => {
        return <Link to={`/protect/p-${app.pCode}/reports?report_id=${row.id}`}>{name}</Link>;
      }
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate"
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate"
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequence"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: status => {
        return getTagByStatus(status);
      }
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      render: recipients => {
        return recipients && recipients.length > 0 ? recipients.join(", ") : "-";
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      render: (_, row) => {
        console.log('row', row.status);
        const dropdownItems = [
          {
            key: "1",
            label: (
              <Button
                type="link"
                onClick={() => {
                  const searchParams = new URLSearchParams(window.location.search); // Get current search params
                  searchParams.set("report_id", row.id); // Set the new query param
                  history.push(`/protect/p-${app.pCode}/reports?${searchParams.toString()}`);
                }}
              >
                Edit
              </Button>
            )
          },
          ...(row.status === REPORT_STATUS.ACTIVE || row.status === REPORT_STATUS.PAUSED || row.status === REPORT_STATUS.PENDING
            ? [
                {
                  key: "2",
                  label: (
                    <Button
                      onClick={() => setUpdatedJob(row.id, row.status === REPORT_STATUS.ACTIVE ? true : false)}
                      type="link"
                      danger={row.status === REPORT_STATUS.ACTIVE}
                    >
                      {row.status === REPORT_STATUS.ACTIVE
                        ? "Pause"
                        : row.status === REPORT_STATUS.PENDING || row.status === REPORT_STATUS.RUNNING
                        ? "Resume"
                        : ""}
                    </Button>
                  )
                }
              ]
            : [])
        ];
        return (
          <>
            <Button
              type="link"
              icon={<DownloadOutlined />}
              disabled={!row.fileUrl}
              onClick={() => window.open(row.fileUrl, "_blank")}
            >
              Download
            </Button>

            <Dropdown menu={{ items: dropdownItems }}>
              <a onClick={e => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </>
        );
      }
    }
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={24} justify="space-between">
        <Col span={20}>
          <Row gutter={24}>
            <Col span={6}>
              <Input placeholder="Enter report name" value={reportName} onChange={e => setReportName(e.target.value)} />
            </Col>

            <Col span={18}>
              <Radio.Group
                options={[
                  { value: REPORT_TYPES.SCHEDULED, label: TEXT.SCHEDULED_REPORTS },
                  { value: REPORT_TYPES.ON_DEMAND, label: TEXT.ON_DEMAND_REPORTS }
                ]}
                onChange={e => {
                  const { value } = e.target;
                  console.log('value', value);
                  setReportType(value);
                  history.push(`/protect/p-${app.pCode}/reports/view-reports?report_type=${value}`);
                }}
                value={reportType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <Button type="link" onClick={() => history.push(`/protect/p-${app.pCode}/reports`)}>
            View Reports Dashboard
          </Button>
        </Col>
      </Row>

      <div style={{ marginTop: "16px" }}>
        <Table
          dataSource={dataSource}
          columns={
            reportType === REPORT_TYPES.ON_DEMAND
              ? onDemandColumns(confirm, cancel)
              : scheduledColumns((jobId, status) => {
                  setUpdatedJobId(jobId);
                  setUpdatedJobStatus(status);
                })
          }
          loading={isLoading}
          rowClassName={(record, index) => (index % 2 === 0 ? Style.tableRowDark : Style.tableRowLight)}
        />
      </div>
    </div>
  );
};

const MapStateToProps = ({ app }) => ({ app });
export default connect(MapStateToProps)(withPcode(withNavbar(ViewReports)));
