// @flow
import { faPlusCircle } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';
import Space from 'antd/es/space';
import React from 'react';
import uid from 'uid';
import classNames from 'classnames';
import FilterInput from '../../../../components/ui/forms/filter-input';
import type { ToolbarProps } from '../../../../interfaces/toolbar';
import Styles from './toolbar.module.scss';
import ContentToolbar from '../../../../components/ui/content-toolbar';

function Toolbar({
  vendorsQuantity,
  handleModal,
  filter,
  disabled,
  onTabClick,
  onAddClick,
  onSaveGoogleList,
  googleSaveDisable
}: ToolbarProps): React.ElementType {
  const [tabId, setTabId] = React.useState('tab__IAB');

  const handleTabChange = (tab: string): void => {
    onTabClick(tab);
    setTabId(tab);
  };


  return (
    <>
      <ContentToolbar>
        <ContentToolbar.Column flex="0.5 0 auto">
          <Tabs
            id="tabsdemoexample"
            size="small"
            onTabClick={handleTabChange}
            defaultActiveKey={tabId}
            className={Styles.tabs}
            items={[
              // This pane and its references must be removed after v2 is deprecated
              { id: 'tab__IAB', key: 'tab__IAB', label: 'IAB Vendors' },
              { id: 'tab__IAB_2.2', key: 'tab__IAB_2.2', label: 'IAB Vendors 2.2' },
              { id: 'tab__NonIAB', key: 'tab__NonIAB', label: 'Non-IAB Vendors' },
              { id: 'tab__Google', key: 'tab__Google', label: 'Google Vendors' },
            ]}
          />
        </ContentToolbar.Column>
      </ContentToolbar>
      <ContentToolbar>
        <ContentToolbar.Column
          flex="1.5 0 auto"
          className={Styles.column}
          style={{ maxHeight: '34px' }}
        >
          {(tabId === 'tab__IAB' || tabId === 'tab__IAB_2.2' || tabId === 'tab__Google') && <FilterInput {...filter} />}
          <div
            className={Styles['vendor-counter']}
          >
            <p data-testid="vendor-counter">{`${vendorsQuantity} vendors`}</p>
          </div>
        </ContentToolbar.Column>
        <ContentToolbar.Column
          align="right"
          className={
            classNames(Styles['--align-right'], Styles['--centered'], { [Styles.column]: tabId !== 'tab__Google' })
          }
          style={tabId === 'tab__NonIAB' ? { maxWidth: '180px' } : { maxWidth: '280px' }}
        >
          {tabId === 'tab__IAB'
            && (
              <Space>
                <Button
                  onClick={() => handleModal(true)}
                  type="secondary"
                  disabled={disabled}
                  data-testid="block_list_button"
                  className={Styles['--flat-right']}
                >
                  Block
                </Button>
                <Button
                  onClick={() => handleModal(true, 'unblock')}
                  type="secondary"
                  disabled={disabled}
                  data-testid="white_list_button"
                  className={Styles['--flat-right']}
                >
                  Whitelist
                </Button>
              </Space>
            )}
          {tabId === 'tab__NonIAB' && (
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              key={uid()}
              onClick={onAddClick}
              className={Styles['--rounded']}
            >
              Add a vendor
            </Button>
          )}
          {tabId === 'tab__Google' && (
            <Button
              key={uid()}
              type="primary"
              onClick={onSaveGoogleList}
              className={Styles['--flat-right']}
              disabled={googleSaveDisable}
            >
              Save
            </Button>
          )}
          {tabId === 'tab__IAB_2.2'
            && (
              <Space>
                <Button
                  onClick={() => handleModal(true)}
                  type="secondary"
                  disabled={disabled}
                  data-testid="block_list_button"
                  className={Styles['--flat-right']}
                >
                  Block
                </Button>
                <Button
                  onClick={() => handleModal(true, 'unblock')}
                  type="secondary"
                  disabled={disabled}
                  data-testid="white_list_button"
                  className={Styles['--flat-right']}
                >
                  Whitelist
                </Button>
              </Space>
            )}
        </ContentToolbar.Column>
      </ContentToolbar>
    </>
  );
}

export default Toolbar;
