import { buildError } from '../../utils/reducer';
import { SURVEY_LINK } from '../actions';

const defState = {
  link: null,
  error: {
    status: 200,
    message: ''
  },
  pending: false,
};

export function fetchSurveyLink(pcode) {
  return {
    type: SURVEY_LINK.GET.FETCH,
    payload: { pcode }
  };
}

function surveyLinkReducer(state = defState, action) {
  switch (action.type) {
    case SURVEY_LINK.GET.PENDING: {
      return { ...state, pending: true };
    }
    case SURVEY_LINK.GET.FULFILLED: {
      return { ...state, link: action.payload.link, pending: false };
    }
    case SURVEY_LINK.GET.REJECTED: {
      return { ...state, error: buildError(action.payload), pending: false };
    }
    default: {
      return state;
    }
  }
}

export default surveyLinkReducer;
