import {
  FIELD_REQUIRED_TEXT,
  FIELD_URL_INVALID,
  IMAGE_APP_INVALID_TYPE,
  IMAGE_PUBLISHER_APP_EXT,
  URL_FIELD_REQUIRED,
  URL_TEXT_FIELD_REQUIRED,
} from '../../utils/constants';

const validate = () => ({
  customLinksUrl: fieldName => ([
    { type: 'url', message: FIELD_URL_INVALID },
    ({ getFieldValue }) => (
      {
        validator(_, value) {
          const text = getFieldValue(fieldName);
          if (text && text !== '' && !value) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          if (value && !text) {
            return Promise.reject(new Error(URL_TEXT_FIELD_REQUIRED));
          }
          return Promise.resolve();
        }
      }
    )
  ]),
  publisherLogoUrl: [
    {
      type: 'url',
      message: FIELD_URL_INVALID
    },
    {
      validator(_, value) {
        if (value) {
          const extensionSection = value.slice(value.lastIndexOf('.'));
          const lastIndexOf = extensionSection.lastIndexOf('?');
          const extension = lastIndexOf !== -1 ? extensionSection.slice(0, lastIndexOf) : extensionSection;
          if (!extension.match(IMAGE_PUBLISHER_APP_EXT)) {
            return Promise.reject(new Error(IMAGE_APP_INVALID_TYPE));
          }
        }

        return Promise.resolve();
      },
    }
  ],
  customLinksText: fieldName => ([
    ({ getFieldValue }) => (
      {
        validator(_, value) {
          const urlLink = getFieldValue(fieldName);
          if (urlLink && urlLink !== '' && !value) {
            return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
          }
          if (value && !urlLink) {
            return Promise.reject(new Error(URL_FIELD_REQUIRED));
          }
          return Promise.resolve();
        }
      }
    ),
    {
      pattern: /^[^<>]*$/,
      message: "Field should not contain '<' or '>' symbols."
    },
  ]),
  regulation: gbcEnabled => ([
    {
      validator(_, value) {
        if (!gbcEnabled && value.length === 0) {
          return Promise.reject(new Error(FIELD_REQUIRED_TEXT));
        }
        return Promise.resolve();
      }
    }
  ]),
  appName: hasLegalBasis => ([
    {
      required: hasLegalBasis,
      message: FIELD_REQUIRED_TEXT
    },
    {
      pattern: /^[^<>]*$/,
      message: "Field should not contain '<' or '>' symbols."
    }
  ]),
  textInput: [
    {
      pattern: /^[^<>]*$/,
      message: "Field should not contain '<' or '>' symbols."
    }
  ],
});
export default validate;
