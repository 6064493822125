// @flow
import React from 'react';
import Card from 'antd/es/card';
import classNames from 'classnames';
import uid from 'uid';
import Table from '../../../../components/ui/table';
import { HEADER_REGULATION_TEXT, AB_TESTCONSENT_TEXT } from '../../../../utils/constants';
import Styles from './abTesting.module.scss';

type Props = {
  analytics: Object;
  privacyMode: Array<string>;
}

const AbTesting = ({ analytics, privacyMode }: Props) => {
  const isGdprEnabled = privacyMode.includes('GDPR');
  const isCcpaEnabled = privacyMode.includes('US');

  return (
    <div className={Styles.container}>
      { (isGdprEnabled || isCcpaEnabled) && (
      <div className={classNames([Styles.content], [Styles.abData])}>
        <div>
          <p>
            <strong>
              Prefix:
              &nbsp;
              {analytics.prefix}
            </strong>
          </p>
        </div>
        <div>
          <p>
            <strong>
              First shown by:
              &nbsp;
              { analytics.startDate }
            </strong>
          </p>
        </div>
        <div>
          <p>
            <strong>
              Last shown by:
              &nbsp;
              { analytics.endDate }
            </strong>
          </p>
        </div>
      </div>
      )}
      {isGdprEnabled
      && (
        <Card
          title={[
            <span key={uid()}>Results</span>,
            <span className={Styles.cardSubtitle} key={uid()}>
              {AB_TESTCONSENT_TEXT}
            </span>
          ]}
          extra={HEADER_REGULATION_TEXT.GDPR}
        >
          <div className={Styles.content}>
            <div className={Styles.contentLeft}>
              <h1>
                {`${analytics.totalShown.toLocaleString()} times`}
              </h1>
              <h3>This test was shown</h3>
            </div>
            <div className={Styles.contentCenter}>
              <div className={Styles.contentCenterMain}>
                <div className={Styles.contentCenterLeft}>
                  <h1>{`${analytics.consentRatePercentage.toFixed(2)}%`}</h1>
                  <h3>Consent rate</h3>
                  <p>
                    Consent rate from total consent cookies set:
                    {`  ${analytics.cookiesConsentRatePercentage.toFixed(2)}%`}
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.contentRight}>
              <h1>{analytics.bounceRatePercentage ? `${analytics.bounceRatePercentage.toFixed(2)}%` : '0%'}</h1>
              <h3>Bounce Rate</h3>
            </div>
          </div>
          <hr className={Styles.separatorLine} />
          <div className={Styles.tableContainer}>
            <h3 className={Styles.title}>Consent details</h3>
            <Table
              data={[
                {
                  id: 0,
                  events: 'Saw this test',
                  visits: analytics.totalShown.toLocaleString(),
                  percent: '100.00 %'
                },
                {
                  id: 1,
                  events: 'Accept All',
                  visits: analytics.acceptAll.toLocaleString(),
                  percent: `${analytics.acceptedRatePercentage.toFixed(2)}%`
                },
                {
                  id: 2,
                  events: 'Reject All',
                  visits: analytics.rejectAll.toLocaleString(),
                  percent: `${analytics.rejectedRatePercentage.toFixed(2)}%`
                },
                {
                  id: 3,
                  events: 'Accept Some',
                  visits: analytics.partialAll.toLocaleString(),
                  percent: `${analytics.partialRatePercentage.toFixed(2)}%`
                }
              ]}
              headers={{
                events: 'event',
                visits: 'visits',
                percent: 'percent'
              }}
            />
          </div>
        </Card>
      )}
      {isCcpaEnabled
      && (
        <Card
          title={[
            <span key={uid()}>Results</span>,
            <span className={Styles.cardSubtitle} key={uid()}>
              {AB_TESTCONSENT_TEXT}
            </span>
          ]}
          extra={HEADER_REGULATION_TEXT.CCPA}
        >
          <div className={Styles.content}>
            <div className={Styles.contentLeft}>
              <h1>
                {analytics.doNotSellRequestReceived}
              </h1>
              <h3>Do Not Sell requests received</h3>
            </div>
            <div className={Styles.contentCenter}>
              <div className={Styles.contentCenterMain}>
                <div className={Styles.contentCenterLeft}>
                  <h1>
                    {analytics.doNotSellRequestFulfilled}
                  </h1>
                  <h3>Do Not Sell requests fulfilled</h3>
                </div>
              </div>
            </div>
            <div className={Styles.contentRight}>
              <h1>
                {analytics.medianTimeFulfillmentMillis}
              </h1>
              <h3>Median Time for opt-out request fulfillment</h3>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default AbTesting;
