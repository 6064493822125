import React, { useEffect } from 'react';
import protectedRoute from '../../login/protectedRoute';
import redirectToFirstAccount from '../../login/redirectToFirstAccount';

const Root = () => {
  return (
    <>
    </>
  );
};

export default redirectToFirstAccount(protectedRoute(Root));
