// @flow
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { faPencilAlt, faPlusCircle, faTrashAlt } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uid from 'uid';
import Button from 'antd/es/button';
import Empty from 'antd/es/empty';
import Space from 'antd/es/space';
import AlertsContainer from '../../components/ui/alerts-container';
import Loader from '../../components/ui/loader';
import Table from '../../components/ui/table';
import type { iThemesContainerProps } from '../../interfaces/themes';
import { buildTableData } from '../../utils/themes';
import ContentHeader from '../../components/ui/content-header';
import ContentToolbar from '../../components/ui/content-toolbar';
import FilterInput from '../../components/ui/forms/filter-input';
import Modal from '../../components/ui/modal';
import Styles from './themes.module.scss';

const ThemesContainer = ({ themes, app, actions }: iThemesContainerProps) => {
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [themeId, setThemeId] = useState(null);
  const [themeName, setThemeName] = useState(null);
  const history = useHistory();

  const withFilteredResults = (data: Array<any>): void => {
    actions.themes.useFilterData();
    setFilteredData(data);
  };

  const displayDeleteThemeModal = (idTheme, nameTheme) => {
    setThemeId(idTheme);
    setThemeName(nameTheme);
    setOpen(!open);
  };

  const confirmDeleteTheme = (idTheme, nameTheme) => {
    actions.themes.deleteTheme({ idTheme, pCode: app.pCode, nameTheme });
  };

  useEffect(() => {
    if (themes.deleteTheme && !themes.deleteTheme.pending) {
      setOpen(false);
    }
  }, [themes.deleteTheme]);

  useEffect(() => {
    actions.alerts.clear();
    actions.themes.clearCurrent();
    actions.themes.fetchAllThemes(app.pCode);
  }, []);

  useEffect(() => {
    if (themes && themes.list.length && !themes.pending && !themes.useFilterData) {
      setFilteredData(themes.list);
    }
  }, [themes]);

  const tableHeaders = {
    name: 'theme',
    regulation: 'regulation',
    type: 'Type',
    actions: ''
  };
  const table = (
    <Table
      data={buildTableData({
        themes: filteredData,
        actions: [{
          component: Button,
          href: `/protect/p-${app.pCode}/themes/`,
          icon: (<FontAwesomeIcon icon={faPencilAlt} color="nimbus" />),
          dataTest: 'themes_edit',
          mode: 'text',
          title: 'Edit',
          type: 'text',
          key: uid(),
        },
        {
          component: Button,
          icon: (<FontAwesomeIcon icon={faTrashAlt} color="nimbus" />),
          dataTest: 'themes_delete',
          mode: 'text',
          title: 'Delete',
          type: 'text',
          key: uid(),
        }
        ],
        displayDeleteThemeModal,
        history
      })}
      headers={tableHeaders}
    />
  );


  return (
    <div style={{padding: '20px'}}>
      <ContentHeader
        description="Manage the look and feel of your consent screen."
      />
      <section className={Styles.container}>
        <div className={Styles.header}>
          <AlertsContainer />
          <ContentToolbar>
            <ContentToolbar.Column>
              <p>Themes</p>
            </ContentToolbar.Column>
            <ContentToolbar.Column align="right">
              <Space>
                <FilterInput
                  filterBy={['themeName']}
                  onFilter={withFilteredResults}
                  baseData={themes.list}
                />
                <Button
                  data-testid="themes_protect_a_site_btn"
                  type="primary"
                  onClick={() => {
                    history.push(`/protect/p-${app.pCode}/themes/site/new`);
                  }}
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  text="create a site theme"
                >
                  CREATE A SITE THEME
                </Button>
                <Button
                  data-testid="themes_protect_a_site_btn"
                  type="primary"
                  onClick={() => {
                    history.push(`/protect/p-${app.pCode}/themes/app/new`);
                  }}
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  text="create an app theme"
                >
                  CREATE AN APP THEME
                </Button>
              </Space>
            </ContentToolbar.Column>
          </ContentToolbar>
        </div>
        {!themes.pending ? (
          <main className={Styles.content}>
            {!filteredData.length ? (
              <Empty
                description="No Results Found"
              />
            ) : table}
          </main>
        ) : <Loader />
        }
      </section>
      <Modal
        title={'Delete theme'}
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => confirmDeleteTheme(themeId, themeName)}
        centered
        key={themeId}
        okText={'CONFIRM'}
        cancelText={'CANCEL'}
        confirmLoading={themes.deleteTheme && themes.deleteTheme.pending}
      >
        <p>
          Are you sure you want to delete&nbsp;
          <b>{themeName}</b>
          ?. This is irreversible.
        </p>
      </Modal>
    </div>
  );
};

export default ThemesContainer;
