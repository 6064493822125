import { APP } from '../actions';

const defState = {
  navBarLoaded: false,
  loading: false,
  currentUser: null,
  pCode: null,
  accountId: null,
  error: null,
  permissions: null,
  eventStream: null,
  isPremium: false,
};

export function getAppInfo(pcode) {
  return {
    type: APP.GET_INFO.FETCH,
    payload: { pcode }
  };
}

export function setCurrentUser(currentUser) {
  return {
    type: APP.CURRENT_USER.SET,
    payload: currentUser
  };
}

export function setAccount(accountId, pCode) {
  return {
    type: APP.ACCOUNT.SET,
    payload: { accountId, pCode },
  };
}

export function clearAccount() {
  return { type: APP.ACCOUNT.CLEAR };
}

export function rejectPcode() {
  return { type: APP.ACCOUNT.REJECTED };
}

export function setUserPermissions(permission) {
  return {
    type: APP.PERMISSION.SET,
    payload: permission
  };
}

function appReducer(state = defState, action) {
  switch (action.type) {
    case APP.GET_INFO.PENDING: {
      return { ...state, loading: true };
    }
    case APP.GET_INFO.FULFILLED: {
      return { ...state, loading: !action.payload };
    }
    case APP.GET_INFO.REJECTED: {
      return { ...state, loading: action.payload };
    }
    case APP.CURRENT_USER.SET: {
      return { ...state, currentUser: action.payload };
    }
    case APP.ACCOUNT.SET: {
      return { ...state, accountId: action.payload.accountId, pCode: action.payload.pCode };
    }
    case APP.ACCOUNT.CLEAR: {
      return { ...defState };
    }
    case APP.PERMISSION.SET: {
      return {
        ...state,
        permissions: {
          name: action.payload.name,
          allowed: action.payload.allowed
        }
      };
    }
    case APP.PRIVACYREGULATIONS.SET: {
      return { ...state, isPremium: action.payload.isPremium };
    }
    default: {
      return state;
    }
  }
}

export default appReducer;
