import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ANALYTICS,
  APP,
  APPS,
  AUDIT_LOGS,
  AUTH,
  BUILD,
  BULK_UPDATES,
  CMP_VERSIONS_V2,
  GBC_PURPOSES,
  MSPA_PURPOSES,
  NON_IABVENDORS,
  PREVIEW,
  SITES,
  STACKS,
  SURVEY_LINK,
  THEMES,
  VENDORS
} from './actions';
import { authListener, initializeAuthentication } from './sagas/authentication';
import {
  bulkUpdate,
  fetchBulkUpdateStatus,
} from './sagas/bulk-updates';
import {
  fetchAbtestBySite,
  fetchAllAnalytic,
  fetchAnalyticByApp,
  fetchAnalyticByURL
} from './sagas/analytics';
import {
  fetchAllGoogle,
  fetchAllNonIABVendors,
  fetchCreateNoIabVendor,
  fetchDeleteVendor,
  fetchEditNonIab,
  fetchNonIabVendorById
} from './sagas/non-iabvendors';
import {
  fetchAllThemes,
  fetchCreateTheme,
  fetchDeleteTheme,
  fetchEditTheme,
  fetchThemeById
} from './sagas/themes';
import {
  fetchAllVendors,
  toggleGoogleVendorsWhitelist,
  toggleGoogleVendorsWhitelistV3,
  toggleVendorBlock,
} from './sagas/vendors';
import {
  fetchAmpTag,
  fetchCreateSite,
  fetchDeleteSite,
  fetchEditSite,
  fetchSitesById,
  fetchSitesInitData,
  fetchSupportedCustomizedVendors
} from './sagas/sites';
import { fetchAppById, fetchCreateApp, fetchDeleteApp, fetchEditApp } from './sagas/apps';
import {
  fetchDownload,
  fetchDownloadAll,
  fetchPrepareDownload,
  fetchStatus,
  hideAuditModals
} from './sagas/audit-logs';

import { fetchCMPVersionsV2 } from './sagas/versionsV2';
import { fetchGBCPurposes } from './sagas/gbc';
import { fetchMSPAPurposes } from './sagas/mspa';
import { fetchPreview } from './sagas/preview';
import { fetchStacks } from './sagas/stacks';
import { fetchUserAndAccountInfo } from './sagas/app';
import patchAllChoiceJsUpdate from './sagas/build';
import { fetchSurveyLink } from './sagas/survey-link';

function* allSagas() {
  yield all([
    // Analytics sagas
    yield takeLatest(ANALYTICS.GET_BY_URL.FETCH, fetchAnalyticByURL),
    yield takeEvery(ANALYTICS.GET_ALL.FETCH, fetchAllAnalytic),
    yield takeEvery(ANALYTICS.GET_AB_TEST_BY_SITE.FETCH, fetchAbtestBySite),
    yield takeLatest(ANALYTICS.GET_BY_APP.FETCH, fetchAnalyticByApp),
    // App sagas
    yield takeLatest(APP.GET_INFO.FETCH, fetchUserAndAccountInfo),
    // CMP Version sagas
    yield takeLatest(CMP_VERSIONS_V2.GET.FETCH, fetchCMPVersionsV2),
    // Survey Link
    yield takeLatest(SURVEY_LINK.GET.FETCH, fetchSurveyLink),
    // Sites sagas
    yield takeLatest(SITES.CREATE.FETCH, fetchCreateSite),
    yield takeLatest(SITES.EDIT.FETCH, fetchEditSite),
    yield takeLatest(SITES.DELETE.FETCH, fetchDeleteSite),
    yield takeLatest(SITES.GET_BY_ID.FETCH, fetchSitesById),
    yield takeLatest(SITES.INIT_DATA.FETCH, fetchSitesInitData),
    yield takeLatest(SITES.GET_AMP_TAG.FETCH, fetchAmpTag),
    yield takeLatest(SITES.GET_SUPPORT_CUSTOM_VENDORS.FETCH, fetchSupportedCustomizedVendors),
    // Themes sagas
    yield takeLatest(THEMES.GET_ALL.FETCH, fetchAllThemes),
    yield takeLatest(THEMES.CREATE.FETCH, fetchCreateTheme),
    yield takeLatest(THEMES.EDIT.FETCH, fetchEditTheme),
    yield takeLatest(THEMES.GET_BY_ID.FETCH, fetchThemeById),
    yield takeLatest(THEMES.DELETE.FETCH, fetchDeleteTheme),
    // Audit logs sagas
    yield takeLatest(AUDIT_LOGS.PREPARE_DOWNLOAD.FETCH, fetchPrepareDownload),
    yield takeEvery(AUDIT_LOGS.DOWNLOAD.FETCH, fetchDownload),
    yield takeLatest(AUDIT_LOGS.DOWNLOAD_ALL.FETCH, fetchDownloadAll),
    yield takeLatest(AUDIT_LOGS.STATUS.FETCH, fetchStatus),
    yield takeLatest(AUDIT_LOGS.STATUS.HIDE_MODALS, hideAuditModals),
    // Build sagas
    yield takeLatest(BUILD.BATCH_UPDATE_CHOICEJS.FETCH, patchAllChoiceJsUpdate),
    // Vendors sagas
    yield takeLatest(VENDORS.TOGGLE_BLOCK.FETCH, toggleVendorBlock),
    yield takeLatest(VENDORS.V3_TOGGLE_BLOCK.FETCH, toggleVendorBlock),
    yield takeLatest(VENDORS.TOGGLE_GOOGLE_WHITE_LIST.FETCH, toggleGoogleVendorsWhitelist),
    yield takeLatest(VENDORS.V2.GET_ALL.FETCH, fetchAllVendors),
    yield takeLatest(VENDORS.V3.GET_ALL.FETCH, fetchAllVendors),
    yield takeLatest(VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.FETCH, toggleGoogleVendorsWhitelistV3),
    // Non IAB vendors sagas
    yield takeLatest(NON_IABVENDORS.CREATE.FETCH, fetchCreateNoIabVendor),
    yield takeLatest(NON_IABVENDORS.GET_ALL.FETCH, fetchAllNonIABVendors),
    yield takeLatest(NON_IABVENDORS.EDIT.FETCH, fetchEditNonIab),
    yield takeLatest(NON_IABVENDORS.GET_BY_ID.FETCH, fetchNonIabVendorById),
    yield takeLatest(NON_IABVENDORS.DELETE.FETCH, fetchDeleteVendor),
    yield takeLatest(NON_IABVENDORS.GET_GOOGLE_ATP.FETCH, fetchAllGoogle),
    // Preview
    yield takeLatest(PREVIEW.SHOW.FETCH, fetchPreview),
    // stacks
    yield takeLatest(STACKS.GET_RECOMMENDED.FETCH, fetchStacks),
    // Apps entity
    yield takeLatest(APPS.CREATE.FETCH, fetchCreateApp),
    yield takeLatest(APPS.EDIT.FETCH, fetchEditApp),
    yield takeLatest(APPS.GET_BY_ID.FETCH, fetchAppById),
    yield takeLatest(APPS.DELETE.FETCH, fetchDeleteApp),
    // Bulk update
    yield takeLatest(BULK_UPDATES.UPDATE.FETCH, bulkUpdate),
    yield takeLatest(BULK_UPDATES.STATUS.FETCH, fetchBulkUpdateStatus),
    yield fork(authListener),
    yield takeLatest(AUTH.INIT, initializeAuthentication),
    // GBC Purposes
    yield takeLatest(GBC_PURPOSES.GET.FETCH, fetchGBCPurposes),
    // MSPA Purposes
    yield takeLatest(MSPA_PURPOSES.GET.FETCH, fetchMSPAPurposes)
  ]);
}

export default allSagas;
