const TEXT = {
  EDIT_FILTER: 'Edit Filter',
  REMOVE_ALL: 'Remove All',
  MORE_FILTERS: 'More Filters',
  REGULATION_OPTIN_HEADER: 'Regulation-wise Opt-in',
  REGULATION_TRAFFIC_HEADER: 'Regulation-wise Traffic',
  REGULATION_SESSION_HEADER: 'Regulation-wise Display Count',
  PAGE_SESSIONS_HEADER: 'Page-wise Display Count',
  REGION_TRAFFIC_HEADER: 'Region-wise Traffic',
  REGION_SESSION_HEADER: 'Region-wise Display Count',
  GDPR_CONSENT_HEADER: 'GDPR Consent Distribution',
  CCPA_CONSENT_HEADER: 'CCPA Consent Distribution',
  SUMMARY: 'Summary',
  INSIGHTS: 'Detailed Insights',
  COMPARE: 'compared to',
  FILTER: 'Filter your',
  DASHBOARD: 'Dashboard',
  REGULATION: 'Regulation',
  FILTERS: 'Filters',
  PAGES: 'Pages',
  DEVICE_TYPE: 'Device Type',
  OS: 'OS',
  COUNTRIES: 'Countries',
  CANCEL: 'Cancel',
  APPLY_FILTER: 'Apply Filter ',
  NO_CHANGE: 'No Change',
  TABLE_HEADER: 'Display Count & Opt-in by Countries',
  TABLE_SUB_TEXT: 'in comparison to',
  NO_DATA_LINE1: 'It looks like no properties have been selected.',
  NO_DATA_LINE2: 'Select a property to see detailed insights and metrics.',
  TIME: 'Time',
  PAGE_FORMAT: 'Page Format',
  BROWSER: 'Browser',
  REPORT_DELETION_SUCCESS: 'Report deleted successfully',
  SCHEDULED_REPORTS: 'Scheduled Reports',
  ON_DEMAND_REPORTS: 'On Demand Reports',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  REPORT_UPDATE_SUCCESS: 'Report updated successfully',
  OLD_VERSION_MESSAGE: 'N/A (old SDK version)'
};

export default TEXT;
