import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavbar from '../../components/hoc/with-navbar';
import * as AlertsActions from '../../store/duck/alerts';
import * as ThemesActions from '../../store/duck/themes';
import ThemesContainer from './themesContainer';
import withPcode from '../../components/hoc/withPcode';

const MapStateToProps = ({ app, themes }) => ({ app, themes });

const MapDispatchToProps = dispatch => ({
  actions: {
    themes: bindActionCreators(ThemesActions, dispatch),
    alerts: bindActionCreators(AlertsActions, dispatch)
  }
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withPcode(withNavbar(ThemesContainer)));
