import React, { useState } from 'react'

import commonStyles from './common.module.css'
import Spin from '../../components/ui/spin';

function errorMessage(error) {
  if (error.log === "Username cannot be empty") {
    return "Email cannot be empty";
  }
  switch (error.code) {
    case 'InvalidParameterException':
      return 'First name or Last name should be at least 2 characters long'
    case 'NotAuthorizedException':
    case 'UserNotFoundException':
      return "Please check your email and password and try again";
    default:
      return error.message || error.log || error.code;
  }
}

function SignUp({ updateFormState, updateFormError, signUp, error }) {
  const [loading, setLoading] = useState(false);

  const signUpNow = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const regex = /^[^<>]*$/;
    const formValues = Object.fromEntries(formData.entries());
    const { given_name, family_name, publisher_name } = formValues;
    const isNameValid = regex.test(given_name) && regex.test(family_name) && regex.test(publisher_name);

    if (!isNameValid) {
      updateFormError({ message: "Name Fields should not include '<' and '>' characters" });
      return false;
    }

    setLoading(true);
    signUp().finally(() => {
      setLoading(false);
    });
    return false;
  };

  return (
    <>
      <div className={commonStyles.heading}>
        Sign Up
      </div>
      {error && (<span className={commonStyles.error}>{errorMessage(error)}</span>)}
      <form className={commonStyles.formContainer} onSubmit={signUpNow}>
        <input
          name='given_name'
          onChange={e => { e.persist(); updateFormState(e) }}
          placeholder='First Name'
        />
        <input
          name='family_name'
          onChange={e => { e.persist(); updateFormState(e) }}
          placeholder='Last Name'
        />
        <input
          name='publisher_name'
          onChange={e => { e.persist(); updateFormState(e) }}
          placeholder='Publisher Name'
        />
        <input
          name='username'
          onChange={e => { e.persist(); updateFormState(e) }}
          placeholder='Email'
        />
        <input
          type='password'
          name='password'
          onChange={e => { e.persist(); updateFormState(e) }}
          placeholder='Password'
        />
        <button type='submit' className={commonStyles.primaryButton}>
          {loading ? (<Spin size='large' />) : (<>Sign Up</>)}
        </button>
        <div style={{ display: 'block', align: 'center', padding: '20px 0' }}>
          By signing up, you agree to InMobi’s <a href="https://www.inmobi.com/terms-of-service/" rel="noreferrer" target="_blank">Terms of Service (TOS)</a>, {" "}
          <a href="https://www.inmobi.com/cmp-terms/" rel="noreferrer" target="_blank">CMP Terms of Service (TOS)</a>, {" "}
          <a href="https://www.inmobi.com/privacy-policy/" rel="noreferrer" target="_blank">Privacy Policy</a> and {" "}
          <a href="https://www.inmobi.com/publisher-policy/" rel="noreferrer" target="_blank">Publisher Policy</a>.
        </div>
      </form>
    </>
  )
}

export default SignUp
