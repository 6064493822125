import { ALERTS } from '../actions';

export function add(alert) {
  return { type: ALERTS.ADD, payload: alert };
}

export function remove(id) {
  return { type: ALERTS.REMOVE, payload: id };
}

export function clear() {
  return { type: ALERTS.CLEAR };
}

function alertsReducer(state = [], action) {
  switch (action.type) {
    case ALERTS.ADD: {
      if (state.some(alert => alert.id === action.payload.id) === false) {
        const alert = Object.assign({}, action.payload);
        alert.id = alert.id || state.length + 1;
        return [...state, alert].sort((a, b) => a.id.toString() - b.id.toString());
      }
      return state;
    }
    case ALERTS.REMOVE: {
      const nextState = state.filter(alert => alert.id !== action.payload);
      return nextState;
    }
    case ALERTS.CLEAR: {
      return [];
    }
    default: {
      return state;
    }
  }
}

export default alertsReducer;
