// @flow
import React, { useState } from 'react';
import Checkbox from 'antd/es/checkbox';
import Tooltip from 'antd/es/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faAngleRight } from '@fortawesome/fontawesome-pro-solid';
import classNames from 'classnames';
import InfoIconTooltip from '../../../../components/ui/infoIconTooltip';
import Styles from '../../../../components/forms/themes/components/purposes-v2/purposesV2.module.scss';
import { PURPOSES_INFO } from '../../../../utils/constants';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';

type Props = {
  flexiblePurposes: Array<number>;
  onChange: Function;
  values: Object;
  purposesList: Array<Record<String, any>>;
}

function Restrictions(
  {
    flexiblePurposes,
    onChange,
    values,
    purposesList
  }: Props
) {
  const [restrictions, setRestrictions] = useState({
    consent: values.publisherConsentRestrictionIds,
    legitimate: values.publisherLIRestrictionIds
  });
  const [isVisible, setIsVisible] = useState('');

  const onChangeValues = (event) => {
    const isConsent = event.target.name.includes('CONSENT');
    const id = parseInt(event.target.name.split('_')[1], 10);
    const value = event.target.checked;
    let newConsentRestrictions = [...restrictions.consent];
    let newLiRestrictions = [...restrictions.legitimate];

    if (isConsent) {
      if (value) {
        newConsentRestrictions.push(id);
        newLiRestrictions = newLiRestrictions.filter(item => item !== id);
      } else {
        newConsentRestrictions = newConsentRestrictions.filter(item => item !== id);
      }
    } else if (!isConsent) {
      if (value) {
        newLiRestrictions.push(id);
        newConsentRestrictions = newConsentRestrictions.filter(item => item !== id);
      } else {
        newLiRestrictions = newLiRestrictions.filter(item => item !== id);
      }
    }
    const data = {
      consent: newConsentRestrictions,
      legitimate: newLiRestrictions
    };

    setRestrictions(data);
    onChange('restrictions', data);
  };

  return (
    <>
      <p>
        Select purposes that you want to apply restrictions to the vendors
        legal basis. You can configure a restriction for vendors by either
        applying a restriction for consent or for legitimate interest
        <Tooltip
          title={SITETOOLTIPS.selectPurposes}
          placement="right"
        >
          <span><InfoIconTooltip style={{ marginLeft: '5' }} /></span>
        </Tooltip>
      </p>

      <h4 className={Styles.titleHeader}>Purposes</h4>
      {flexiblePurposes && flexiblePurposes.map((purpose) => {
        const purposeData = (purposesList || PURPOSES_INFO).find(item => item.id === purpose);
        return (
          <div key={purpose}>
            <div className={Styles.titleContainer}>
              <div className={Styles.titlePurpose}>
                <p className={Styles.purposesList}>{purposeData.name}</p>
                {(values.publisherConsentRestrictionIds.includes(purpose)
                  || values.publisherLIRestrictionIds.includes(purpose)) && (
                    <FontAwesomeIcon icon={faBan} />)}
              </div>
              <button
                className={classNames({ [Styles.rotateIcon]: isVisible === purpose })}
                type="button"
                onClick={() => {
                  if (isVisible === purpose) {
                    setIsVisible('');
                  } else {
                    setIsVisible(purpose);
                  }
                }}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
            <div className={classNames(Styles.checkBoxContainer, { [Styles.active]: isVisible === purpose })}>
              <div className={Styles.hiddenSection}>
                <Checkbox
                  className={Styles.restrictionsCheckBox}
                  name={`CONSENT_${purposeData.id}`}
                  checked={values.publisherConsentRestrictionIds.indexOf(purposeData.id) >= 0}
                  onChange={onChangeValues || (e => onChange(`CONSENT_${purposeData.id}`, e))}
                  data-testid={`CONSENT_${purposeData.id}-input`}
                >
                  Restrict to consent
                </Checkbox>
                <Checkbox
                  className={Styles.restrictionsCheckBox}
                  name={`LI_${purposeData.id}`}
                  checked={values.publisherLIRestrictionIds.indexOf(purposeData.id) >= 0}
                  onChange={onChangeValues || (e => onChange(`LI_${purposeData.id}`, e))}
                  data-testid={`LI_${purposeData.id}-input`}
                >
                  Restrict to Legitimate interest
                </Checkbox>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Restrictions;
