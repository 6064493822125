export function getStorage(key) {
  return localStorage.getItem(key);
}

export function isStorageAvailable() {
  const testId = 'choice-ls-test';
  try {
    localStorage.setItem(testId, testId);
    localStorage.removeItem(testId);
    return true;
  } catch (e) {
    return false;
  }
}

export function removeStorage(key) {
  localStorage.removeItem(key);
}

export function updateStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
