// @flow
import React from 'react';
import { faPlusCircle } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/es/button';
import Empty from 'antd/es/empty';
import NonIABTable from './components/non-iab-vendors';
import Modal from '../../../../components/ui/modal';
import { buildTableData } from '../../../../utils/noniabvendors';
import './styles.scss';

type NonIABProps = {
  tableData: Array<Object>,
  onAddClick: Function,
  onDelete: Function,
  onEdit: Function
}

function NonIAB({ tableData, onAddClick, onDelete, onEdit }: NonIABProps) {
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [curVendor, setCurVendor] = React.useState({});

  const openDelModal = (vendor) => {
    setCurVendor(vendor);
    setDelModalOpen(true);
  };

  const onClickDelete = () => {
    onDelete({ vendor: curVendor, amountOfVendors: tableData.length });
    setDelModalOpen(false);
  };

  let content = null;

  if (tableData.length) {
    content = <NonIABTable data={buildTableData(tableData)} onDelete={openDelModal} onEdit={onEdit} />;
  } else {
    content = (
      <Empty
        image={<FontAwesomeIcon icon={faPlusCircle} />}
        className="empty-icon"
        description={(
          <h2>Add vendors here</h2>
        )}
      >
        <Button
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          type="primary"
          onClick={onAddClick}
        >
          ADD A VENDOR
        </Button>
      </Empty>
    );
  }

  return (
    <>
      {content}
      <Modal
        title="Remove this vendor?"
        open={delModalOpen}
        onCancel={() => setDelModalOpen(false)}
        onOk={onClickDelete}
        okText="Delete"
        centered
      >
        <p>
          This vendor will no longer be shown in your CMP, and the CMP will be re-prompted on your
          site(s) with the updated vendor list.
        </p>
      </Modal>
    </>
  );
}

export default NonIAB;
