// @flow
import React, { useState, useEffect, useRef } from 'react';
import { faPencilAlt } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import uid from 'uid';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';
import AnchorTooltipIconButton from '../../components/ui/buttons/anchor-tooltip-icon';
import Loader from '../../components/ui/loader';
import type { iSiteDetailContainerProps } from '../../interfaces/siteDetail';
import TypoStyles from '../../scss/_typo.module.scss';
import Styles from './siteDetail.module.scss';
import ChoiceReport from './components/choice-reports';
import AbTesting from './components/ab-testing';
import Unauthorized from '../../components/ui/unauthorized';
import ContentHeader from '../../components/ui/content-header';
import Result from '../../components/ui/result';
import { NotFoundPage } from '../not-found';
import SitesFormsPage from '../sites/components/sites-form-page';
import { GVL_VERSION_2, GVL_VERSION_3, LATEST_CMP_VERSION } from '../../utils/constants';
import useGBCPurposes from '../../components/forms/gbc/useGBCPurposes';
import formatInitData from '../../components/forms/sites/formatData';
import { isGPPSupported } from '../../utils/property';

const SiteDetailContainer = ({
  sites,
  themes,
  app,
  analytics,
  actions,
  match,
  history,
  cmpVersionV2,
  vendors
}: iSiteDetailContainerProps) => {
  const [navbarTabId, setNavbarTabId] = useState('tab__qc_choice_reports');
  const [siteUrl, setSiteUrl] = useState('');
  const [themeName, setThemeName] = useState('');
  const [showPage, setShowPage] = useState(false);
  const [emptyAnalytics, setEmptyAnalytics] = useState(true);
  const [analyticsFetching, setAnalyticsFetching] = useState(false);
  const [abTestingEnabled, setAbTestingEnabled] = useState(false);
  const isFirstRun = useRef(true);
  const gbcPurposes = useGBCPurposes();

  useEffect(() => {
    actions.sites.clearCurrent();
    actions.themes.clearCurrent();
    actions.analytics.clearCurrent();
    actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_2);
    actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_3);
  }, []);

  useEffect(() => {
    if (app && app.pCode) {
      if (!cmpVersionV2.pending && !cmpVersionV2.list.length) {
        actions.cmpVersionV2.fetchCMPVersionsV2(match.params.id, app.pCode);
      }
    }
  }, [app.pCode, cmpVersionV2]);

  useEffect(() => {
    const { current } = sites;
    const abTesting = (current.abTestingSitesCollection && current.abTestingSitesCollection.length);
    if (
      current && !(Object.keys(current).length)
      && !(sites.pending) && !(current.siteId === Number(match.params.id))
      && sites.error.status !== 401 && sites.error.status !== 404 && sites.error.status !== 400
      && isFirstRun.current
    ) {
      isFirstRun.current = false;
      actions.sites.getById(match.params.id, app.pCode);
    }
    if (
      (Object.keys(current).length && !(themes.pending)
        && !(themes.current.themeId === current.themeId)
        && themes.error.status !== 401)
    ) {
      actions.themes.getById(current.themeId, app.pCode);
      actions.themes.fetchAllThemes(app.pCode, 'SITE');
    }

    if (
      Object.keys(current).length && !(Object.keys(analytics.current).length) && !(analytics.pending)
      && !(analytics.current.siteId === current.siteId)
      && !analyticsFetching
    ) {
      actions.analytics.fetchByURL(current.siteId, app.pCode, [current.siteId]);
      setAnalyticsFetching(true);
    }

    if (
      abTesting
      && Object.keys(current).length
      && !(Object.keys(analytics.currentAbTest).length)
      && !(analytics.pending)
    ) {
      actions.analytics.fetchAbTestBySite(current.siteId, app.pCode);
    } else if (
      !abTesting
      && window.location.pathname === `/protect/p-${app.pCode}/sites/detail/${current.siteId}/ab-testing`
    ) {
      history.push(`/protect/p-${app.pCode}/sites/detail/${current.siteId}`);
    }

    if ('themeName' in themes.current
      && ('url' in current)
      && !(sites.pending)
      && (Object.keys(analytics.current).length > 0)
      && (current.siteId === Number(match.params.id))
    ) {
      setThemeName(themes.current.themeName);
      setShowPage(true);
      setSiteUrl(current.url);
      setEmptyAnalytics(false);
    } else {
      setEmptyAnalytics(true);
      setAbTestingEnabled(abTesting);
    }
  }, [sites, match, app, themes, analytics]);

  const renderTabs = () => {
    const { params } = match;
    const siteAnalytic = analytics.current ? analytics.current : null;

    if (
      params.page === 'ab-testing'
      && abTestingEnabled
      && analytics.currentAbTest
      && (Object.keys(analytics.currentAbTest).length)) {
      const { currentAbTest } = analytics;

      if (navbarTabId !== 'tab__ab_testing') {
        setNavbarTabId('tab__ab_testing');
      }

      return (
        <AbTesting
          analytics={currentAbTest}
          privacyMode={sites.current.privacyMode}
          data-testid="ab-testing-component"
        />
      );
    }

    if (navbarTabId !== 'tab__qc_choice_reports') {
      setNavbarTabId('tab__qc_choice_reports');
    }

    const { privacyModes, gbcEnabled, cmpVersion } = sites.current;

    return (
      <ChoiceReport
        analytics={siteAnalytic}
        privacyMode={privacyModes}
        dataTestid="choice-report-component"
        isGBCEnabled={gbcEnabled}
        isGPPEnabled={isGPPSupported(cmpVersion)}
      />
    );
  };

  const onSubmit = (e, values) => {
    e.preventDefault();
    actions.sites.editSite(match.params.id, values, history);
    history.push(`/protect/p-${app.pCode}/sites/detail/${match.params.id}`);
  };

  const getTabs = () => (
    <Tabs
      size="small"
      id="siteDetailTabs"
      onChange={renderTabs}
      defaultActiveKey={navbarTabId}
      data-testid="site-detail-tabs"
      items={[
        {
          id: 'tab__qc_choice_reports',
          key: '1',
          label: (
            <Link
              data-test="site_detail_qc_choice_reports_tab"
              to={`/protect/p-${app.pCode}/sites/detail/${match.params.id}`}
            >
              InMobi Reports
            </Link>
          )
        },
        ...(abTestingEnabled
          ? [{
            id: 'ab__ab_testing',
            key: '2',
            label: (
              <Link
                data-test="site_detail_ab_testing_tab"
                to={`/protect/p-${app.pCode}/sites/detail/${match.params.id}/ab-testing`}
              >
                A/B Testing
              </Link>
            )
          }]
          : [])
      ]}
    />
  );
  let content = (<Loader />);
  const contentHeader = (
    <ContentHeader
      title={siteUrl}
      description={[
        <h4 key={uid()} className={TypoStyles['h4-text']}>Theme</h4>,
        <p className={TypoStyles['text-action']} key={uid()}>{(themeName) && themeName}</p>
      ]}
      actions={[{
        component: AnchorTooltipIconButton,
        props: {
          title: 'Edit',
          dataTest: `site_${match.params.id}_detail_edit`,
          href: `/protect/p-${app.pCode}/sites/edit/${match.params.id}/`,
          type: 'text',
          icon: <FontAwesomeIcon icon={faPencilAlt} />
        }
      }]}
    />
  );
  if (sites.error.status === 401 || themes.error.status === 401) {
    content = (<div style={{ padding: '1em' }}><Unauthorized /></div>);
  } else if (
    sites.error.status === 404 || themes.error.status === 404 || sites.error.status === 400
    || [undefined, 'ab-testing', 'edit'].indexOf(match.params.page) === -1
  ) {
    content = (<div style={{ padding: '1em' }}><NotFoundPage /></div>);
  } else if (showPage && !emptyAnalytics) {
    content = (
      <div>
        {contentHeader}
        <section className={Styles.toolbar}>
          {getTabs()}
        </section>
        {renderTabs()}
      </div>
    );
  } else if (match.params.page === 'edit' && Object.keys(sites.current).length && gbcPurposes.length) {
    content = (
      <SitesFormsPage
        onSubmit={onSubmit}
        dataTest="site-form-page"
        themes={themes.list}
        versions={cmpVersionV2.list}
        versionsV2={cmpVersionV2.list}
        title="Edit A Site"
        initData={formatInitData(app.accountId, app.pCode, sites.current, gbcPurposes)}
        pending={sites.pending}
        history={history}
        fromSiteDetail
        path={`sites/detail/${match.params.id}`}
        isPremium={app.isPremium}
        pcode={app.pCode}
        vendors={vendors}
      />
    );
  } else if (!(analytics.pending) && !(themes.pending) && !(sites.pending) && emptyAnalytics) {
    content = (
      <div>
        {contentHeader}
        <section className={Styles.toolbar}>
          {getTabs()}
        </section>
        <Result
          title="Something went wrong"
          status="error"
          subTitle="This was an error in pulling data for this page.
          Please try again or contact your InMobi representative."
          extra={(
            <Button
              href={`/protect/p-${app.pCode}`}
              type="primary"
              style={{ color: '#fff' }}
            >
              Back to Properties
            </Button>
          )}
        />
      </div>
    );
  }
  return content;
};

export default SiteDetailContainer;
