import './styles.scss';

import React from 'react';
import {
  CONSENT_SCOPE,
  REGULATION_MODES,
  USER_CONSENT_SCOPE,
} from '../../utils/constants';
import { CONSENT_LOCATIONS } from './constants';
import { isGPPSupported } from '../../utils/property';

/* eslint-disable jsx-a11y/anchor-is-valid */

export const tableColumns = (themes, versions) => [
  {
    title: 'Property',
    dataIndex: 'Property',
    key: 'Property',
    render: (_txt, item) => (
      <a
        className="page-column"
        title={item.packageId || item.url}
      >
        {item.packageId || item.url}
      </a>
    )
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type',
    render: (_txt, item) => <span>{item.propertyType}</span>
  },
  {
    title: 'Regulation',
    dataIndex: 'Regulation',
    key: 'Regulation',
    render: (_txt, item) => (
      <span>
        {item.privacyModes.length === 0 ? 'No Regulation' : (
          REGULATION_MODES.find(regMode => regMode.value === item.privacyModes.sort().join(', ')).key
        )}
      </span>
    )
  },
  {
    title: 'Theme',
    dataIndex: 'Theme',
    key: 'Theme',
    render: (_txt, item) => {
      const theme = themes.list.find(val => val.themeId === item.themeId);
      return <span>{theme ? theme.themeName : ' - '}</span>;
    }
  },
  {
    title: 'CMP version',
    dataIndex: 'CMPVersion',
    key: 'CMPVersion',
    render: (_txt, item) => {
      const latestVersion = versions.list.length > 0 ? `(${versions.list[1]})` : '';
      return (
        <span>
          {item.cmpVersion === 'latest' ? `${item.cmpVersion} ${latestVersion}` : item.cmpVersion}
        </span>
      );
    }
  },
  {
    title: 'User Locations',
    dataIndex: 'UserLocations',
    key: 'UserLocations',
    render: (_txt, item) => {
      if (isGPPSupported(item.cmpVersion, versions.list[1])) {
        const uConsentLocations = CONSENT_LOCATIONS.filter(val => item.consentLocations && item.consentLocations.includes(val.value));
        const uConsentLocationsLabels = uConsentLocations.map(val => val.label).join(', ');
        return <span>{uConsentLocationsLabels.length > 0 ? uConsentLocationsLabels : ' - '}</span>;
      }
      const uConsentScope = USER_CONSENT_SCOPE.find(val => val.value === item.requireConsentFrom);
      return <span>{uConsentScope ? uConsentScope.label : ' - '}</span>;
    }
  },
  {
    title: 'Consent Scope',
    dataIndex: 'ConsentScope',
    key: 'ConsentScope',
    render: (_txt, item) => {
      const consentScope = CONSENT_SCOPE.find(val => val.value === item.consentScope);
      return <span>{consentScope ? consentScope.label : ' - '}</span>;
    }
  },
  {
    title: 'Legal Basis',
    dataIndex: 'LegalBasis',
    key: 'LegalBasis',
    render: (_txt, item) => <span>{item.purposeIds.length > 0 ? 'Yes' : 'No'}</span>
  }
];

export const FIELDS_MAP = {
  applyToSubDomains: 'Apply to sub domains',
  ampEnabled: 'AMP enabled',
  themeId: 'Theme',
  privacyModes: 'Regulations',
  uspJurisdiction: 'USP jurisdiction',
  uspLspact: 'IAB LSPA signed',
  cmpVersion: 'CMP version',
  publisherCC: 'Publisher country code',
  consentLocations: 'Consent Location',
  gbcEnabled: 'GBC Enabled',
  gbcApplicablePurposes: 'GBC Applicable Purposes',
  urlPassThrough: 'URL pass through',
  adsDataRedaction: 'Ads data redaction',
  gbcLocations: 'GBC Location',
  requireConsentFrom: 'Require consent from',
  consentScope: 'Which users should be asked for consent?',
  consentScopeGroupURL: 'Consent scope groups URL',
  thirdPartyStorageType: 'Storage mode',
  repromtDaysAfterGvlUpdate: 'Show new vendors every',
  gdprEncodingMode: 'GDPR encoding mode',
  legalBasis: 'Your consent configuration',
  language: 'Language',
  initScreenRejectButtonShowing: 'Show disagree button on the summary view',
  initScreenRejectButton: 'Disagree button text',
  uxInitialScreenCustomLinks: 'Custom links',
  features: 'Features',
  purposeIds: 'Purpose ids',
  purposeLegitimateIds: 'Legitimate purpose ids',
  specialFeatures: 'Special features',
  specialPurposes: 'Special purposes',
  isCoveredTransaction: 'Is Covered Transaction',
  mspaJurisdiction: 'MSPA Regulatory path',
  ccpaViaUsp: 'CCPA via USP',
  mspaOptOutPurposeIds: 'MSPA OptOut Purposes',
  mspaSensitiveDataPurposeIds: 'MSPA Sensitive data purposes',
  mspaAutoPopUp: 'MSPA Auto popup'
};

export const getGBCPurposes = (gbcData) => {
  if (!gbcData) return [];
  return gbcData.map(purpose => ({
    id: purpose.id,
    name: purpose.portal.title
  }));
};

export const getMSPAPurposeIds = (mspadata) => {
  if (!mspadata) return [];
  const optOutPurposeIds = [];
  const sensitivePurposeIds = [];

  mspadata.forEach((purpose) => {
    optOutPurposeIds.push({
      id: purpose.id,
      name: purpose.portal.title
    });
    if (purpose.id === 5) {
      purpose.purposes.forEach((subPurpose) => {
        sensitivePurposeIds.push({
          id: subPurpose.id,
          name: subPurpose.portal.title
        });
      });
    }
  });
  return {
    mspaOptOutPurposeIds: optOutPurposeIds,
    mspaSensitiveDataPurposeIds: sensitivePurposeIds
  };
};
