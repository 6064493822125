import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withPcode from '../../components/hoc/withPcode';
import withNavbar from '../../components/hoc/with-navbar';
import VendorsContainer from './vendorsContainer';
import * as VendorsActions from '../../store/duck/vendors';
import * as NonIabVendorsActions from '../../store/duck/non-iabvendors';
import * as AlertsActions from '../../store/duck/alerts';
import * as SitesActions from '../../store/duck/sites';

const MapStateToProps = ({
  app,
  vendors,
  sites,
  nonIabVendors,
  alerts
}) => ({
  app,
  vendors,
  sites,
  nonIabVendors,
  alerts
});

const MapDispatchToProps = dispatch => ({
  actions: {
    sites: bindActionCreators(SitesActions, dispatch),
    vendors: bindActionCreators(VendorsActions, dispatch),
    nonIabVendors: bindActionCreators(NonIabVendorsActions, dispatch),
    alerts: bindActionCreators(AlertsActions, dispatch)
  }
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withPcode(withNavbar(VendorsContainer)));
