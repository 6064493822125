// @flow
import React, { useState, useEffect } from 'react';
import { faAngleDown, faAngleRight } from '@fortawesome/fontawesome-pro-light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Collapse from 'antd/es/collapse';
import { Row, Col } from 'antd/es/grid';
import Space from 'antd/es/space';
import Radio from 'antd/es/radio';
import PurposesList from './purposeList';
import Alert from '../../../../components/ui/alert';
import Modal from '../../../../components/ui/modal';
import { FIELD_REQUIRED_TEXT, FIELD_URL_INVALID, GVL_VERSION_3, GVL_VERSION_2 } from '../../../../utils/constants';
import Styles from './nonIabVendorsForm.module.scss';
import validate from '../../../../components/forms/sites/syncValidation';

const { Panel } = Collapse;

type Props = {
  onSubmit: Function;
  toggleOpen: Function;
  open: boolean;
  initData: Object;
  header: string;
  isAdd: Boolean;
}

const validations = validate();
function ModalForm({
  onSubmit,
  toggleOpen,
  open,
  initData,
  header,
  isAdd,
}: Props) {
  const [form] = Form.useForm();
  const [consentIds, setConsentIds] = useState(initData.nonIabPurposeConsentIds);
  const [legitimateIds, setLegitimateIds] = useState(initData.nonIabPurposeLegitimateInterestIds);
  const [selectedVersion, setSelectedVersion] = useState(GVL_VERSION_3);
  useEffect(() => {
    setConsentIds(initData.nonIabPurposeConsentIds);
    setLegitimateIds(initData.nonIabPurposeLegitimateInterestIds);
  }, [initData]);
  const handleListChange = (list) => {
    setConsentIds(list.consentIds);
    setLegitimateIds(list.legitimateIds);
  };
  const handleVersionChange = e => setSelectedVersion(e.target.value);

  const handleSubmit = (e, values) => {
    const filteredPurposesIds = consentIds.filter(id => !legitimateIds.includes(id));

    const data = {
      ...values,
      nonIabPurposeConsentIds: filteredPurposesIds,
      nonIabPurposeLegitimateInterestIds: legitimateIds,
      pCode: initData.pCode
    };

    onSubmit(e, data);
  };

  return (
    <Modal
      title={header}
      open={open}
      onCancel={toggleOpen}
      cancelButtonProps={{ 'data-test': 'cancel_vendors_modal' }}
      okButtonProps={{ 'data-test': 'submit_vendors_modal' }}
      okText="SAVE VENDOR"
      onOk={(e) => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(e, values);
          });
      }}
      centered
      destroyOnClose
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="noniab-vendor"
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Form.Item
              name="name"
              label="VENDOR NAME"
              className={Styles.customFormLabel}
              rules={validations.vendorName}
              preserve={false}
              initialValue={initData.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="privacyPolicyUrl"
              label="VENDOR PRIVACY POLICY"
              className={Styles.customFormLabel}
              rules={[{ type: 'url', message: FIELD_URL_INVALID }]}
              preserve={false}
              initialValue={initData.privacyPolicyUrl}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <Form.Item
              name="description"
              label="VENDOR DESCRIPTION"
              rules={validations.textInput}
              className={Styles.customFormLabel}
              preserve={false}
              initialValue={initData.description}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item name="gvlVersion" initialValue={initData.gvlVersion} preserve={false}>
            <Radio.Group
              initialValues={GVL_VERSION_3}
              onChange={handleVersionChange}
              disabled={!isAdd}
              options={[
                { value: GVL_VERSION_2, label: 'TCF 2.0', disabled: false },
                { value: GVL_VERSION_3, label: 'TCF 2.2', disabled: false },
              ]}
            />
          </Form.Item>
          {selectedVersion === GVL_VERSION_3 && initData.gvlVersion === GVL_VERSION_3
          && (
            <Form.Item
              name="legitimateInterestClaimUrl"
              label="VENDOR LEGITIMATE INTEREST CLAIM"
              className={Styles.customFormLabel}
              rules={[{ type: 'url', message: FIELD_URL_INVALID }]}
              preserve={false}
              initialValue={initData.legitimateInterestClaimUrl}
            >
              <Input />
            </Form.Item>
          )
          }
        </Row>
      </Form>
      <Space direction="vertical" size="large">
        <Collapse
          accordion
          bordered={false}
          className={Styles.accordionPanel}
          defaultActiveKey={
            initData.nonIabPurposeConsentIds.length > 0
            || initData.nonIabPurposeLegitimateInterestIds.length > 0 ? '1' : ''
          }
          expandIcon={({ isActive }) => (<FontAwesomeIcon icon={isActive ? faAngleDown : faAngleRight} />)}
        >
          <Panel
            key="1"
            header={<span className={Styles.accordionTitle}>PURPOSES</span>}
          >
            <PurposesList
              onChange={handleListChange}
              purposeIds={consentIds}
              purposeLegitimateIds={legitimateIds}
              gvlVersion={selectedVersion}
            />
          </Panel>
        </Collapse>
        <Alert
          type="warning"
          message={`
            Non-IAB Vendor added will be reflected on the Partners page within the CMP.
            We recommend adding Non-IAB Vendors in batches if you are planning to add multiple
            Non-IAB Vendors to your CMP for disclosure, in order to minimize the amount of
            times your users will be re-prompted on the site.
          `}
        />
      </Space>
    </Modal>
  );
}

export default ModalForm;
