import { PREVIEW } from '../actions';

const defState = {
  preview: {},
  fetching: false
};

export function showPreview(values) {
  return {
    type: PREVIEW.SHOW.FETCH,
    payload: { values }
  };
}

export function cleanPreview() {
  return { type: PREVIEW.CLEAN.FETCH };
}

function previewReducer(state = defState, action) {
  switch (action.type) {
    case PREVIEW.SHOW.PENDING: {
      return { ...state, preview: {}, fetching: true };
    }
    case PREVIEW.CLEAN.FETCH: {
      return { ...state, preview: {}, fetching: false };
    }
    case PREVIEW.SHOW.FULFILLED: {
      return { ...state, preview: action.payload, fetching: false };
    }
    case PREVIEW.SHOW.REJECTED: {
      return { ...state, preview: 'error', fetching: false };
    }
    default: {
      return state;
    }
  }
}

export default previewReducer;
