import React from 'react';

const TEXT = {
  SITE_NAME_LABEL: 'Site Name',
  LOGO_LABEL: 'Site Logo',
  CMP_VERSION_LABEL: 'CMP Version',
  PUBLISHER_CC_LABEL: 'Publisher Country Code',
  USER_CONSENT_LABEL: 'WHICH USERS SHOULD BE ASKED FOR CONSENT',
  REGULATIONS_LABEL: 'Select the applicable regulations for your end-user',
  REGULATION_WARNING: (
    <span>
      In US jurisdictions, CCPA and other US regulations would take precedence over GDPR.
      <br />
      Therefore, only US regulation consent will be collected from users in US and GDPR
      consent will not be collected.
    </span>
  ),
};

export default TEXT;
