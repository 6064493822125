// Definition of main paths
const SITES_PATH = '/sites';
const APPS_PATH = '/apps';
const THEMES_PATH = '/themes';
const SITE_DETAILS_PATH = `${SITES_PATH}/detail`;
const SITE_REPORTS_PATH = `${SITES_PATH}/reports`;
const BULK_UPDATE_PATH = '/bulk-update';
const BULK_UPDATE_STATUS_PATH = '/bulk-update-status';

export const VENDORS = { INDEX: '/vendors' };

export const SITES = { INDEX: `${SITES_PATH}/:action?/:id?` };

export const SITE_DETAIL = { INDEX: `${SITE_DETAILS_PATH}/:id/:page?/:action?` };

export const THEMES = {
  INDEX: `${THEMES_PATH}`,
  FORM: `${THEMES_PATH}/:type/:action/:id?`
};

export const APPS = { INDEX: `${APPS_PATH}/:action?/:id?` };

export const PROPERTIES = { INDEX: '/properties' };

export const BULK_UPDATE = {
  INDEX: `${BULK_UPDATE_PATH}/:page?`,
  STATUS: `${BULK_UPDATE_STATUS_PATH}`
};

export const SITE_REPORTS = { INDEX: '/reports', VIEW_REPORTS: '/reports/view-reports' };
export const SITE_REPORTS_ID = { INDEX: `${SITE_REPORTS_PATH}/:id` };


