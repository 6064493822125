/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { SURVEY_LINK } from '../actions';
import { getSurveyLink } from '../requests/survey-link';

export function* fetchSurveyLink({ payload }) {
  yield put({ type: SURVEY_LINK.GET.PENDING });
  const { res, err } = yield call(getSurveyLink, [payload.pcode]);

  if (res) {
    yield put({
      type: SURVEY_LINK.GET.FULFILLED,
      payload: { ...res.data }
    });
  } else {
    yield put({ type: SURVEY_LINK.GET.REJECTED, payload: err.response });
  }
}
