/* eslint-disable import/prefer-default-export */
import { requestCustomizationV2 } from '../../utils/requests';

const url = '/stack';

export function fetchStacksRequest(data) {
  return requestCustomizationV2
    .post(`${url}/recommendation`, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
