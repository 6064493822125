import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import reducer from './reducers';
import allSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewareList = [sagaMiddleware];

// Only logger must be displayed on development environment
if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true });
  middlewareList.push(logger);
}

// Redux dev tool extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancers(
  applyMiddleware(...middlewareList),
  // other store enhancers if any
);

const store = createStore(reducer, middleware);

// Init sagas middleware
sagaMiddleware.run(allSagas);

export default store;
