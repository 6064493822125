// @flow
import React from 'react';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentHeader from '../../../../components/ui/content-header';
import { BACK_TO_ALL_PROPERTIES_LABEL } from '../../../bulk-update/constants';

type Props = {
  handleSubmit: Function;
  pending: boolean;
  titlePage: string;
  hasChanges: boolean;
}

function FormHeader({ handleSubmit, pending, titlePage, hasChanges }: Props) {
  const history = useHistory();
  const pcode = useSelector(state => state.app.pCode);
  return (
    <ContentHeader
      title={titlePage}
      backBtn={{
        goTo: `p-${pcode}/properties`,
        text: BACK_TO_ALL_PROPERTIES_LABEL
      }}
      actions={[
        {
          component: Space,
          children: [
            {
              component: Button,
              props: {
                type: 'secondary',
                'data-testid': 'cancel-bttn',
                onClick: () => history.push(`/protect/p-${pcode}/sites`),
              },
              children: ['CANCEL']
            },
            {
              component: Button,
              props: {
                type: 'primary',
                onClick: handleSubmit,
                disabled: !hasChanges,
                'data-testid': 'save-changes-bttn'
              },
              children: [pending ? 'LOADING...' : 'SAVE PROPERTY']
            }
          ]
        }
      ]}
    />
  );
}

export default FormHeader;
