/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { mockEmptyAnalyticByURL } from '../../__mocks__/analytics';
import { ANALYTICS } from '../actions';
import { getAnalytics, getByUrl, getAbTestBySite, getByApp } from '../requests/analytics';

export function* fetchAllAnalytic({ payload }) {
  yield put({ type: ANALYTICS.GET_ALL.PENDING });

  const { res, err } = yield call(getAnalytics, [payload.pcode, payload.pageSiteIds]);

  if (res) {
    yield put({ type: ANALYTICS.GET_ALL.FULFILLED, payload: res.data });
  } else if (err) {
    yield put({ type: ANALYTICS.GET_ALL.REJECTED, payload: err });
  }
}

export function* fetchAnalyticByURL({ payload }) {
  yield put({ type: ANALYTICS.GET_BY_URL.PENDING });

  const { res, err } = yield call(getByUrl, [payload.url, payload.pCode, payload.pageSiteIds]);

  if (res) {
    yield put({ type: ANALYTICS.GET_BY_URL.FULFILLED, payload: { current: res.data[0] } });
  } else if (err) {
    const response = Object.assign({}, mockEmptyAnalyticByURL);
    response.url = payload.url;
    yield put({ type: ANALYTICS.GET_BY_URL.REJECTED, payload: response });
  }
}

export function* fetchAnalyticByApp({ payload }) {
  yield put({ type: ANALYTICS.GET_BY_APP.PENDING });
  const { res, err } = yield call(getByApp, [payload.appId, payload.pCode, payload.pageSiteIds]);
  if (res) {
    yield put({ type: ANALYTICS.GET_BY_APP.FULFILLED, payload: { current: res.data[0], idProperty: payload.appId } });
  } else if (err) {
    yield put({ type: ANALYTICS.GET_BY_APP.REJECTED, payload: { current: err, idProperty: payload.appId } });
  }
}

export function* fetchAbtestBySite({ payload }) {
  yield put({ type: ANALYTICS.GET_AB_TEST_BY_SITE.PENDING });

  const { res, err } = yield call(getAbTestBySite, [payload.siteId, payload.pCode]);

  if (res) {
    yield put({ type: ANALYTICS.GET_AB_TEST_BY_SITE.FULFILLED, payload: res.data });
  } else if (err) {
    yield put({ type: ANALYTICS.GET_AB_TEST_BY_SITE.REJECTED, payload: err });
  }
}
