import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withNavbar from '../../components/hoc/with-navbar';
import * as AuditLogsActions from '../../store/duck/audit-logs';
import * as AlertsActions from '../../store/duck/alerts';
import * as SitesActions from '../../store/duck/sites';
import * as VendorsActions from '../../store/duck/vendors';
import * as AppsActions from '../../store/duck/apps';
import * as BulkUpdateActions from '../../store/duck/bulk-updates';
import SitesContainer from './sitesContainer';
import withPcode from '../../components/hoc/withPcode';

const MapStateToProps = ({
  analytics,
  app,
  apps,
  audit,
  cmpVersionV2,
  sites,
  themes,
  alerts,
  vendors
}) => ({
  analytics,
  app,
  apps,
  audit,
  sites,
  themes,
  cmpVersionV2,
  alerts,
  vendors
});

const MapDispatchToProps = dispatch => ({
  actions: {
    auditLogs: bindActionCreators(AuditLogsActions, dispatch),
    alerts: bindActionCreators(AlertsActions, dispatch),
    sites: bindActionCreators(SitesActions, dispatch),
    vendors: bindActionCreators(VendorsActions, dispatch),
    apps: bindActionCreators(AppsActions, dispatch),
    bulkUpdate: bindActionCreators(BulkUpdateActions, dispatch),
  }
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withPcode(withNavbar(SitesContainer)));
