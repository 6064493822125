
import React, { useEffect } from 'react'
import Form from '../components/Form'
import { useSelector } from 'react-redux'

import styles from './Login.module.css';
import redirectToFirstAccount from '../redirectToFirstAccount';
import { Auth } from 'aws-amplify';
import logoImage from '../../assets/images/ChoiceLogo.svg';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

function Login() {
  var { user } = useSelector((s) => s.auth);

  return (
    <div className={styles.body}>
      <div className={styles.topnav}>
        <Space split={<Divider style={{borderLeft: '1px solid rgba(255,255,255,0.6)', height: '1.9em', top: '0'}} type="vertical" />}>
          <img src={logoImage} />
          <div style={{color: 'var(--blue-1)', fontSize: '16px', fontWeight: '600'}}>Consent Management Platform (CMP)</div>
        </Space>
      </div>
      <div className={styles.container}>
        <div className={styles.messageContainer}>
            <div>
                <h1>InMobi CMP</h1>
                <h2>Foster Trust. Unlock Revenue.</h2>
                <ul>
                  <li>Free Consent Management Platform across app & web</li>
                  <li>Compliant with GDPR, CCPA, IAB TCF 2.2 & Google</li>
                  <li>Flexible customization with an intuitive interface</li>
                  <li>Easy implementation in 3 steps</li>
                </ul>
                <h3>Get started with InMobi CMP today.</h3>
            </div>
        </div>
        <div className={styles.formContainer}>
          {!user && (<Form />)}
          {user && (
            <div>
              <h1 onClick={() => { Auth.signOut()}}>Profile</h1>
              <h2>Username: {user.username}</h2>
              <h3>Email: {user.email}</h3>
              <h3>Name: {user.name}</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default redirectToFirstAccount(Login);
