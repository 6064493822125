// @flow
import React, { useState, useEffect } from 'react';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Radio from 'antd/es/radio';
import Tooltip from 'antd/es/tooltip';
import uid from 'uid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-light';
import {
  PURPOSES_INFO,
  SPECIAL_FEATURES_INFO,
  SPECIAL_PURPOSES_INFO,
  FEATURES_INFO
} from '../../../../utils/constants';
import Modal from '../../../../components/ui/modal';
import './styles.scss';
import Styles from '../../../../components/forms/sites/components/purposes-v2/purposesV2.module.scss';


type Props = {
  applyChanges: Function;
  cancelChanges: Function;
  showModal: Boolean;
  customizedFilters: Object | null;
  filterMode?: boolean;
}

function PurposeList({ showModal, applyChanges, cancelChanges, customizedFilters, filterMode }: Props) {
  const [_purposesConsents, setpurposesConsents] = useState([]);
  const [_legitimateInterests, setLegitimateInterests] = useState([]);
  const [_specialPurposes, setSpecialPurposes] = useState([]);
  const [_features, setFeatures] = useState([]);
  const [_specialFeatures, setSpecialFeatures] = useState([]);
  const [allPurposes, setAllPurposes] = useState([]);
  const [exactMatch, setExactMatch] = useState(false);

  const setConfig = (config) => {
    setpurposesConsents(config ? config.purposesConsents : []);
    setLegitimateInterests(config ? config.legitimateInterests : []);
    setSpecialPurposes(config ? config.specialPurposes : []);
    setFeatures(config ? config.features : []);
    setSpecialFeatures(config ? config.specialFeatures : []);
    setAllPurposes([...(config ? config.purposesConsents : []), ...(config ? config.legitimateInterests : [])]);
    setExactMatch(config ? config.exactMatch : false);
  };

  const togglePurpose = (id) => {
    if (allPurposes.includes(id)) {
      setAllPurposes(allPurposes.filter(_id => _id !== id));
      setpurposesConsents(_purposesConsents.filter(_id => _id !== id));
      setLegitimateInterests(_legitimateInterests.filter(_id => _id !== id));
    } else {
      setAllPurposes([...allPurposes, id]);
      setpurposesConsents([..._purposesConsents, id]);
    }
  };

  const toggleLegitimate = (id) => {
    if (_legitimateInterests.includes(id)) {
      setpurposesConsents([..._purposesConsents, id]);
      setLegitimateInterests(_legitimateInterests.filter(_id => _id !== id));
    } else {
      setpurposesConsents(_purposesConsents.filter(_id => _id !== id));
      setLegitimateInterests([..._legitimateInterests, id]);
    }
  };

  const toggleSpecialPurpose = (id) => {
    if (_specialPurposes.includes(id)) {
      setSpecialPurposes(_specialPurposes.filter(_id => _id !== id));
    } else {
      setSpecialPurposes([..._specialPurposes, id]);
    }
  };

  const toggleFeature = (id) => {
    if (_features.includes(id)) {
      setFeatures(_features.filter(_id => _id !== id));
    } else {
      setFeatures([..._features, id]);
    }
  };

  const toggleSpecialFeature = (id) => {
    if (_specialFeatures.includes(id)) {
      setSpecialFeatures(_specialFeatures.filter(_id => _id !== id));
    } else {
      setSpecialFeatures([..._specialFeatures, id]);
    }
  };

  const okAction = () => {
    const purposeConsents = _purposesConsents.includes(1)
      ? _purposesConsents
      : [1, ..._purposesConsents];

    const filteredSettings = {
      purposesConsents: purposeConsents,
      legitimateInterests: _legitimateInterests,
      specialPurposes: _specialPurposes,
      features: _features,
      specialFeatures: _specialFeatures
    };

    if (filterMode) {
      filteredSettings.exactMatch = exactMatch;
    }

    applyChanges(filteredSettings);
  };

  const clearAction = () => {
    setConfig(null);
    setExactMatch(false);

    const filteredSettings = {
      purposesConsents: [PURPOSES_INFO[0].id],
      legitimateInterests: [],
      specialPurposes: [],
      features: [],
      specialFeatures: [],
    };

    if (filterMode) {
      filteredSettings.exactMatch = false;
    }

    applyChanges(filteredSettings);
  };

  const cancelAction = () => {
    if (customizedFilters === undefined) {
      cancelChanges(true);
    } else {
      applyChanges(customizedFilters);
    }
    setConfig(customizedFilters);
    cancelChanges();
  };

  useEffect(() => {
    setConfig(customizedFilters);
  }, [customizedFilters]);

  return (
    <Modal
      open={showModal}
      title="Customize settings"
      footer={[
        <Button type="secondary" key="clear" onClick={clearAction}>Clear</Button>,
        <Button type="secondary" key="cancel" onClick={cancelAction}>Cancel</Button>,
        <Button type="primary" key="ok" onClick={okAction}>Ok</Button>
      ]}
      closable={false}
      className="bulk-update-customize-settings"
    >
      {filterMode && (
        <Checkbox
          checked={exactMatch}
          onChange={event => setExactMatch(event.target.checked)}
          className="no-match"
        >
          Exact match
          <Tooltip title="Check this option to filter by an exact match of the entire list">
            <span>
              &nbsp;
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
          </Tooltip>
        </Checkbox>
      )}
      <section className={Styles.purposeSection}>
        <p>Purposes</p>
        {
          PURPOSES_INFO.map(purpose => (
            <React.Fragment key={uid()}>
              <Checkbox
                checked={allPurposes.includes(purpose.id) || purpose.id === 1}
                onChange={() => togglePurpose(purpose.id)}
                disabled={purpose.id === 1}
              >
                {purpose.name}
              </Checkbox>
              {(allPurposes.includes(purpose.id) || purpose.id === 1) && (
                <Radio.Group
                  onChange={() => toggleLegitimate(purpose.id)}
                  value={_legitimateInterests.includes(purpose.id) ? 1 : 0}
                  disabled={purpose.id === 1}
                >
                  <Radio value={0}>Consent</Radio>
                  <Radio value={1}>Legitimate Interest</Radio>
                </Radio.Group>
              )}
            </React.Fragment>
          ))
        }
        <p>Special features</p>
        {SPECIAL_FEATURES_INFO.map(specialFeature => (
          <Checkbox
            checked={_specialFeatures.includes(specialFeature.id)}
            key={uid()}
            onChange={() => toggleSpecialFeature(specialFeature.id)}
          >
            {specialFeature.name}
          </Checkbox>
        ))}
        <p>Special purposes</p>
        {SPECIAL_PURPOSES_INFO.map(specialPurpose => (
          <Checkbox
            checked={_specialPurposes.includes(specialPurpose.id)}
            key={uid()}
            onChange={() => toggleSpecialPurpose(specialPurpose.id)}
          >
            {specialPurpose.name}
          </Checkbox>
        ))}
        <p>Features</p>
        {FEATURES_INFO.map(feature => (
          <Checkbox
            checked={_features.includes(feature.id)}
            key={uid()}
            onChange={() => toggleFeature(feature.id)}
          >
            {feature.name}
          </Checkbox>
        ))}
      </section>
    </Modal>
  );
}

PurposeList.defaultProps = { filterMode: false };

export default PurposeList;
