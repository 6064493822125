import { Hub } from "aws-amplify";

let dataMemory = {};

/** @class */
export class AsyncStorage {
    static syncId = 0
    static syncRemote () {
        return fetch(process.env.REACT_APP_AUTH_SECURE_TOKEN_STORE_URL, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(dataMemory)
        }).finally(() => {
          // console.log("SecureTokenStorage Synced");
        });
    }
    static cleanRemote() {
      if(process.env.REACT_APP_AUTH_SECURE_TOKEN_STORE_CLEAN_UP) {
        return fetch(process.env.REACT_APP_AUTH_SECURE_TOKEN_STORE_CLEAN_UP, {
            credentials: 'include'
        }).catch((e) => console.error(e));
      }
    }
}

/** @class */
class SecureTokenStorage {
  static syncPromise = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    if(!key.startsWith('CognitoIdentityServiceProvider')) {
      window.localStorage.setItem(key, value);
    }
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : window.localStorage.getItem(key) || undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Will sync the MyStorage data from AsyncStorage to storageWindow MyStorage
   */
  static sync() {
    if (!SecureTokenStorage.syncPromise) {
      SecureTokenStorage.syncPromise = new Promise((res, rej) => {
        dataMemory = { ...window.localStorage };
        fetch(process.env.REACT_APP_AUTH_SECURE_TOKEN_STORE_URL, {
            credentials: 'include'
        }).then(response => response.json())
          .then(response => {
            // console.log("SecureTokenStorage Loaded");
            Object.keys(response).forEach(k => {
                dataMemory[k] = response[k];
            });
            res();
          }).catch(err => {
              console.error(err);
              res();
          });
      });
    }
    return SecureTokenStorage.syncPromise;
  }
}

export default SecureTokenStorage;