import { call, put } from 'redux-saga/effects';

import { GBC_PURPOSES } from '../actions';
import { getGBCPurposes } from '../requests/gbc';
/* eslint-disable import/prefer-default-export */


export function* fetchGBCPurposes() {
  yield put({ type: GBC_PURPOSES.GET.PENDING });

  const { res, err } = yield call(getGBCPurposes);

  if (res) {
    yield put({ type: GBC_PURPOSES.GET.FULFILLED, payload: res.data.purposes });
  } else {
    yield put({ type: GBC_PURPOSES.GET.REJECTED, payload: err.response });
  }
}
