// @flow
import React from 'react';
import Card from 'antd/es/card';
import uid from 'uid';
import PurposesV2 from '../../../../components/forms/sites/components/purposes-v2';
import Styles from '../../../../components/forms/sites/sitesForm.module.scss';
import { HEADER_REGULATION_TEXT } from '../../../../utils/constants';

type Props = {
  values: Object,
  initData: Object,
  purposes: number[];
  setFieldsValue: Function;
  getFieldValue: Function;
  validateFields: Function;
  setValues: Function;
  legitimatePurposes: number[];
  validatePurposes: Array;
  setDataTestId: Function;
  vendorsData: Record<string, any>;
};

function ConsentConfiguration({
  values,
  initData,
  purposes,
  legitimatePurposes,
  setValues,
  setFieldsValue,
  validateFields,
  validatePurposes,
  getFieldValue,
  setDataTestId,
  vendorsData
}: Props) {
  const {
    specialFeatures,
    consentScope,
    isDefaultConsent,
    publisherCC,
    stackIds,
    specialPurposes,
    features,
  } = values;
  return (
    <Card
      className={Styles.consentCard}
      title={[
        <span key={uid()}>Your consent configuration</span>,
        <span className={Styles.cardSubtitle} key={uid()}>Collect consent for data you use</span>
      ]}
      extra={HEADER_REGULATION_TEXT.GDPR}
    >
      <PurposesV2
        setFieldsValue={setFieldsValue}
        setValues={setValues}
        validateFields={validateFields}
        purposeIds={purposes}
        purposeLegitimateIds={legitimatePurposes}
        specialFeatures={specialFeatures}
        consentScope={consentScope}
        isDefaultConsent={isDefaultConsent}
        publisherCC={publisherCC}
        stackIds={stackIds}
        specialPurposes={specialPurposes}
        features={features}
        validatePurposes={validatePurposes}
        getFieldValue={getFieldValue}
        setDataTestId={setDataTestId}
        initData={initData}
        vendorsData={vendorsData}
        propertyType="app"
      />
    </Card>
  );
}

export default ConsentConfiguration;
