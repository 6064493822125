import { AUTH } from '../actions';

const defState = {
  isAuthenticating: true,
  isAuthenticated: false,
  user: null,
};

export function initAuthentication() {
  return {
    type: AUTH.INIT
  };
}

export function authStateChanged({ isAuthenticated, user, isAuthenticating }) {
  return {
    type: AUTH.STATE_CHANGED,
    payload: { isAuthenticated, user, isAuthenticating }
  };
}

function authencationReducer(state = defState, action) {
  switch (action.type) {
    case AUTH.INIT: {
      return defState;
    }
    case AUTH.STATE_CHANGED: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}
  
export default authencationReducer;