/* eslint-disable import/prefer-default-export */
import { requestBuildV2 } from '../../utils/requests';

const mockSite = {
  url: 'www.preview.com',
  cmpVersion: 'LATEST',
  requireConsentFrom: 'always',
  consentScope: 'service',
  uuid: '5acab6e1-85a3-498a-9987-72d9a05e2ccf',
};

export function previewTheme([values]) {
  return requestBuildV2
    .post('choicejs/preview', { site: mockSite, theme: values })
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
