import { buildError } from '../../utils/reducer';
import { CMP_VERSIONS_V2 } from '../actions';

const defState = {
  list: [],
  error: {
    status: 200,
    message: ''
  },
  pending: false,
};

export function fetchCMPVersionsV2(pCode) {
  return { type: CMP_VERSIONS_V2.GET.FETCH, payload: { pCode } };
}

function cmpVersionsV2Reducer(state = defState, action) {
  switch (action.type) {
    case CMP_VERSIONS_V2.GET.PENDING: {
      return { ...state, pending: true };
    }
    case CMP_VERSIONS_V2.GET.FULFILLED: {
      return { ...state, list: action.payload, pending: false };
    }
    case CMP_VERSIONS_V2.GET.REJECTED: {
      return { ...state, error: buildError(action.payload), pending: false };
    }
    default: {
      return state;
    }
  }
}

export default cmpVersionsV2Reducer;
