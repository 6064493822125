function formatData(accountId, pCode, current) {
  return {
    accountId,
    pCode,
    name: current.name || '',
    description: current.description || '',
    privacyPolicyUrl: current.privacyPolicyUrl || '',
    nonIabPurposeConsentIds: current.nonIabPurposeConsentIds || [],
    nonIabPurposeLegitimateInterestIds: current.nonIabPurposeLegitimateInterestIds || [],
    legitimateInterestClaimUrl: current.legitimateInterestClaimUrl || '',
    gvlVersion: current.gvlVersion || 3,
  };
}

export default formatData;
