import './styles.scss';

import { Col, Row } from 'antd/es/grid';
import React, { useEffect, useMemo } from 'react';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { BACK_TO_DETAILS_LABEL, PAGE_TITLE } from '../../constants';
import {
  COUNTRY_CODES,
  FEATURES_INFO,
  PURPOSES_INFO,
  REGULATION_MODES,
  SPECIAL_FEATURES_INFO,
  SPECIAL_PURPOSES_INFO,
} from '../../../../utils/constants';
import { FIELDS_MAP, getGBCPurposes, getMSPAPurposeIds, tableColumns } from '../../utils';
// @flow
import { fetchBulkUpdate, resetBulkUpdate } from '../../../../store/duck/bulk-updates';

import Alert from '../../../../components/ui/alert';
import AlertsContainer from '../../../../components/ui/alerts-container';
import Card from '../../../../components/ui/card';
import ContentHeader from '../../../../components/ui/content-header';
import { GBC_FIELDS } from '../../../../components/forms/gbc/constants';
import { useDispatch, useSelector } from 'react-redux';
import useMSPAPurposes from '../../../../components/forms/consent_scope_usp/useMSPAPurposes';

interface iBulkUpdateSummary {
  fieldsToUpdate: Array<Object>,
  selectedSites: Array<Object>,
  themes: {
    list: Array<Object>
  },
  pCode: String,
  gbcPurposesData: Object
}

const NOTHING_SELECTED = 'Nothing selected.';

const renderInner = (key, listMap) => (
  <li key={key}>
    <span>{`${listMap.find(listItem => listItem.id === key).name}`}</span>
  </li>
);

const getDefaultTableValues = (value) => {
  console.log('Val ', value);
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
};

const BulkUpdateSummary = (props: iBulkUpdateSummary) => {
  const mspaPurposes = useMSPAPurposes();
  const { fieldsToUpdate, selectedSites, themes, pCode, gbcPurposesData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const bulkUpdateState = useSelector(state => state.bulkUpdates.update);
  const { cmpVersionV2 } = useSelector(state => state);
  const gbcPurposes = getGBCPurposes(gbcPurposesData);
  const { mspaOptOutPurposeIds, mspaSensitiveDataPurposeIds } = getMSPAPurposeIds(mspaPurposes);
  const onConfirm = () => {
    const siteIds = selectedSites.map(site => site.siteId);
    const { legalBasis, noLegalBasis, ...fields } = fieldsToUpdate;
    dispatch(fetchBulkUpdate({ pCode, sites: siteIds, fields }));
  };

  useEffect(() => {
    if (!Object.keys(fieldsToUpdate).length) {
      history.push(`/protect/p-${pCode}/bulk-update/details`);
    }
  }, [fieldsToUpdate]);

  useEffect(() => {
    if (bulkUpdateState.successed) {
      history.push(`/protect/p-${pCode}/properties`);
      dispatch(resetBulkUpdate());
    }
  }, [bulkUpdateState]);


  const HIDDEN_FIELDS = useMemo(() => {
    const hidden = [
      'noLegalBasis',
      'hasGDPR',
      'hasCCPA',
    ];
    if (fieldsToUpdate.legalBasis === 'applyRecommended') {
      hidden.push(...[
        'features',
        'purposeIds',
        'purposeLegitimateIds',
        'specialFeatures',
        'specialPurposes',
      ]);
    }
    return hidden;
  }, [fieldsToUpdate]);

  const tableColumnsFields = [
    {
      title: 'field',
      render: (_txt, item) => <strong>{FIELDS_MAP[item.field]}</strong>
    },
    {
      title: 'value',
      render: (_txt, item) => {
        let value = null;
        switch (item.field) {
          case 'publisherCC': {
            const selectedCountry = COUNTRY_CODES.find(country => country.value === item.value);
            value = selectedCountry.label;
            break;
          }
          case 'themeId': {
            const themeSelected = themes.list.find(theme => theme.themeId === item.value);
            value = themeSelected.themeName;
            break;
          }
          case 'features': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(feature => renderInner(feature, FEATURES_INFO))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          case 'purposeIds':
          case 'purposeLegitimateIds': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(purpose => renderInner(purpose, PURPOSES_INFO))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          case 'specialFeatures': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(specialFeat => renderInner(specialFeat, SPECIAL_FEATURES_INFO))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          case 'specialPurposes': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(specialPurp => renderInner(specialPurp, SPECIAL_PURPOSES_INFO))
                    : 'Nothing selected.'
                }
              </ul>
            );
            break;
          }
          case 'privacyModes': {
            value = (
              <span>
                {
                  REGULATION_MODES.find(regMode => regMode.value === item.value.sort().join(', ')).key
                }
              </span>
            );
            break;
          }
          case GBC_FIELDS.ENABLED: {
            value = (
              <span>
                {item.value ? 'Yes' : 'No'}
              </span>
            );
            break;
          }
          case GBC_FIELDS.LOCATIONS: {
            value = (
              <span>
                {item.value.includes('EEA') ? 'EEA & UK' : item.value.join(', ')}
              </span>
            );
            break;
          }
          case GBC_FIELDS.APPLICABLE_PURPOSES: {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(purpose => renderInner(purpose.id, gbcPurposes))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          case 'mspaJurisdiction': {
            value = (
              <span>
                {item.value === 'NATIONAL' ? 'National' : 'State and National'}
              </span>
            );
            break;
          }
          case 'mspaOptOutPurposeIds': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(id => renderInner(id, mspaOptOutPurposeIds))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          case 'mspaSensitiveDataPurposeIds': {
            value = (
              <ul>
                {
                  item.value.length
                    ? item.value.map(id => renderInner(id, mspaSensitiveDataPurposeIds))
                    : NOTHING_SELECTED
                }
              </ul>
            );
            break;
          }
          default:
            value = (
              <span>
                {getDefaultTableValues(item.value)}
              </span>
            );
            break;
        }
        return value;
      }
    }
  ];

  return (
    <>
      <ContentHeader
        title={PAGE_TITLE}
        backBtn={{
          goTo: `p-${pCode}/bulk-update/details`,
          text: BACK_TO_DETAILS_LABEL
        }}
        actions={[
          {
            component: Button,
            props: {
              type: 'primary',
              disabled: false,
              'data-testid': 'bulk_next_to_submit',
              onClick: onConfirm
            },
            children: ['SUBMIT']
          }
        ]}
      />
      <div id="bulk-update-summary" className="bulk-update-summary">
        <Row gutter={[16, 16]}>
          <Col span="24">
            <AlertsContainer />
          </Col>
          <Col span="24">
            <Alert
              message="Step 3"
              description={
                `The following information will be updated on the selected ${selectedSites.length} properties...`
              }
              type="info"
              showIcon
            />
          </Col>
          <Col span="24">
            <Card>
              <Table
                showHeader={false}
                columns={tableColumnsFields}
                dataSource={
                  Object.keys(fieldsToUpdate)
                    .filter(field => !HIDDEN_FIELDS.includes(field))
                    .map(field => ({ field, value: fieldsToUpdate[field] }))
                }
                pagination={false}
                rowKey={record => record.field}
              />
              <Divider />
              <div className="bulk-update-summary__table">
                <Table
                  columns={tableColumns(themes, cmpVersionV2)}
                  dataSource={selectedSites}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BulkUpdateSummary;
