import { buildError } from '../../utils/reducer';
import { NON_IABVENDORS } from '../actions';

const defState = {
  current: {},
  error: {
    status: 200,
    message: ''
  },
  list: [],
  pending: false,
  fetched: false,
  googleList: []
};

/**
 * @param {string} pcode - Pcode
 * @returns {{payload: {pcode: string}, type: string}}
 */
export function fetchAllNonIabVendors(pcode) {
  return {
    type: NON_IABVENDORS.GET_ALL.FETCH,
    payload: { pcode }
  };
}

export function fetchAllGoogleATP() {
  return { type: NON_IABVENDORS.GET_GOOGLE_ATP.FETCH };
}

/**
 * @param {Object} vendorData  vendor information
 * @param {string} pCode - user pCode
 * @returns {{payload: Object, type: string}}
 */
export function fetchCreateNoIabVendor(vendorData, pCode, amountOfVendors) {
  return {
    type: NON_IABVENDORS.CREATE.FETCH,
    payload: { vendorData, pCode, amountOfVendors }
  };
}

export function deleteNoIABVendor({ vendor, amountOfVendors }) {
  return {
    type: NON_IABVENDORS.DELETE.FETCH,
    payload: { vendor, amountOfVendors }
  };
}

export function editNonIabVendor(nonIabId, data) {
  return {
    type: NON_IABVENDORS.EDIT.FETCH,
    payload: { nonIabId, data }
  };
}

export function getById(nonIabId) {
  return {
    type: NON_IABVENDORS.GET_BY_ID.FETCH,
    payload: { nonIabId }
  };
}

export function clearCurrent() {
  return { type: NON_IABVENDORS.GET_BY_ID.CLEANED };
}

function nonIabVendorReducer(state = defState, action) {
  switch (action.type) {
    case NON_IABVENDORS.DELETE.PENDING:
    case NON_IABVENDORS.GET_ALL.PENDING:
    case NON_IABVENDORS.CREATE.PENDING:
    case NON_IABVENDORS.EDIT.PENDING:
    case NON_IABVENDORS.GET_BY_ID.PENDING:
    case NON_IABVENDORS.GET_GOOGLE_ATP.PENDING:
    {
      return { ...state, pending: true, fetched: false, error: { status: 200, message: '' } };
    }

    case NON_IABVENDORS.GET_ALL.FULFILLED: {
      return { ...state, list: action.payload, pending: false, fetched: true };
    }

    case NON_IABVENDORS.GET_GOOGLE_ATP.FULFILLED: {
      return { ...state, googleList: action.payload, pending: false, fetched: true };
    }

    case NON_IABVENDORS.CREATE.FULFILLED: {
      return {
        ...state,
        list: [...state.list, action.payload],
        pending: false,
        fetched: true
      };
    }

    case NON_IABVENDORS.DELETE.FULFILLED: {
      const list = [...state.list].filter(item => item.nonIabId !== action.payload);
      return { ...state, pending: false, list };
    }

    case NON_IABVENDORS.EDIT.FULFILLED: {
      const list = [...state.list].filter(item => item.nonIabId !== action.payload.nonIabId);
      list.unshift(action.payload);
      return { ...state, list, pending: false, fetched: true };
    }

    case NON_IABVENDORS.GET_BY_ID.FULFILLED: {
      return { ...state, current: action.payload, pending: false, fetched: true };
    }

    case NON_IABVENDORS.DELETE.REJECTED:
    case NON_IABVENDORS.GET_ALL.REJECTED:
    case NON_IABVENDORS.CREATE.REJECTED:
    case NON_IABVENDORS.EDIT.REJECTED:
    case NON_IABVENDORS.GET_BY_ID.REJECTED:
    case NON_IABVENDORS.GET_GOOGLE_ATP.REJECTED:
    {
      return {
        ...state,
        pending: false,
        current: {},
        error: buildError(action.payload.data)
      };
    }

    // Others
    case NON_IABVENDORS.GET_BY_ID.CLEANED: {
      return { ...state, current: {} };
    }

    default: {
      return state;
    }
  }
}

export default nonIabVendorReducer;
