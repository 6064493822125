// This component checks wheather or not a user is signed in
// It will allow to view private routes or force the user to log in
// When a user signs in, they will be redirectd to their profile page

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Spin from '../components/ui/spin';
import Space from 'antd/es/space';

const protectedRoute = (Comp, route = '/login') => (props) => {
  const {isAuthenticating, isAuthenticated } = useSelector(s => s.auth);
  useEffect(() => {
    if(isAuthenticating == false && isAuthenticated == false) {
      props.history.push(route);
    }
  }, [isAuthenticating])
  if(isAuthenticating) {
    return (<div style={{display: 'flex', fontSize: '14px', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <Space direction='vertical' align='center'>
        <Spin size="large"/>
        <span>Loading...</span>
      </Space>
    </div>);
  }
  return <Comp {...props} />
}

export default protectedRoute