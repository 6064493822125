import React from "react";

import {
  TIME_FILTERS,
  GRANULARITY_FILTERS,
  CONTINENTS,
  CONTINENT_WITH_COUNTRIES,
  FILTER_TYPE,
  TIME
} from "../../../utils/constants";

import DetailsSummary from "./detailsSummary";
import { Radio, Tag, Checkbox, DatePicker, Alert } from "antd/es";
import { checkbox_style, FILTERS, REACH_PCODE } from "../constants";
import Styles from './analytics.module.scss';
import TEXT from "../text";
import { getCurrentDefaultDateRange } from "../utils";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterContent = ({ granularity, 
  selectedFilters, 
  selectedDimensions, 
  handleFiltersChange, 
  handleChange, 
  customDate, 
  setCustomDate, 
  setCustomDateString, 
  timeMissingError, 
  pCode }) => {
  return (
    <div className={Styles.filter_content}>
      {
        timeMissingError && <Alert message={timeMissingError} type="error" />
      }
      <details>
        <summary>{TEXT.TIME}</summary>
        <div className={Styles.time_filter}>
          <div className={Styles.granularity_filter}>
            <p className={Styles.time_label}>Split by</p>
            <div className={Styles.granularities}>
            {GRANULARITY_FILTERS.map(tag => (
              <Tag.CheckableTag
                key={tag.name}
                checked={granularity === tag.name}
                onChange={checked => handleChange(tag.name, checked)}
              >
                {tag.label}
              </Tag.CheckableTag>
            ))}
            </div>
          </div>


          <p className={Styles.time_label} style={{marginTop: '14px'}}>Select Time Period</p>
          <Radio.Group
            onChange={e => handleFiltersChange(e, FILTER_TYPE.TIME)}
            value={selectedFilters.find(el => el.type === FILTER_TYPE.TIME).values[0]} // Get the first selected value for FILTER_TYPE.TIME
            className={Styles.filter_radio_group}
          >
            {TIME_FILTERS.map((time, index) => {
              if (time === TIME.CUSTOM_DATE) {
                return (
                  <div key={time + index} style={{ ...checkbox_style }}>
                    <Radio value={time} style={{ ...checkbox_style }}>
                      {time}
                    </Radio>
                    {selectedFilters.find(el => el.type === FILTER_TYPE.TIME).values[0] === time && (
                      <RangePicker
                        onChange={(d, dateString) => {
                          setCustomDateString(dateString);
                          setCustomDate(d);
                        }}
                        value={customDate}
                        disabledDate={d => {
                            const currentDate = moment().utc();
                            if (pCode === REACH_PCODE) {
                              return d.isAfter(currentDate);
                            }
                            const threeMonthsAgo = moment().utc().subtract(3, 'months');
                            return d && (d.isAfter(currentDate) || d.isBefore(threeMonthsAgo));
                        }}
                        defaultValue={getCurrentDefaultDateRange()}
                        allowClear={false}
                      />
                    )}
                  </div>
                );
              }
              return (
                <Radio value={time} key={time + index} style={{ ...checkbox_style }}>
                  {time}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
      </details>

      {FILTERS.map(({ type, label, filters }) =>
        <DetailsSummary
          key={type}
          selectedFilters={selectedFilters}
          label={label}
          type={type}
          filters={filters}
          handleFiltersChange={handleFiltersChange}
        />
      )}

      <details>
        <summary>{TEXT.COUNTRIES}</summary>
        <div className={Styles.countries_filter}>
          {CONTINENTS.map((continent, index) => {
            return (
              <details key={continent + index} style={{ marginLeft: "" }}>
                <summary>{continent}</summary>

                <div className={Styles.countries_filter}>
                  {CONTINENT_WITH_COUNTRIES[continent].map((country, index) => {
                    return (
                      <Checkbox
                        style={{ ...checkbox_style }}
                        value={country.code}
                        checked={selectedFilters
                          .find(el => el.type === FILTER_TYPE.COUNTRY)
                          .values.includes(country.code)}
                        onChange={e => handleFiltersChange(e, FILTER_TYPE.COUNTRY)}
                        key={country.code + index}
                      >
                        {country.name}
                      </Checkbox>
                    );
                  })}
                </div>
              </details>
            );
          })}
        </div>
      </details>
    </div>
  );
};

export default FilterContent;
