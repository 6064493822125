// @flow
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Table from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import renderExpandIcon from '../../../../../../components/ui/table/tableExpandIcon';
import Styles from './table.module.scss';
import { GVL_VERSION_3 } from '../../../../../../utils/constants';

type Props = {
  data: Array<Object>,
  onEdit: Function,
  onDelete: Function,
}

const NonIABTable = ({
  data,
  onEdit,
  onDelete
}: Props) => {
  const [vendors, setVendors] = useState([]);
  const [pageSize, setPageSize] = useState(70);

  useEffect(() => {
    setVendors(data);
  }, []);

  useEffect(() => {
    setVendors(data);
  }, [data]);

  const buildChildInformation = ({ description, policyUrl, legitimateInterestClaimUrl }) => (
    <section className={Styles.childInformation}>
      {description && (
        <p className={Styles.description}>
          <strong>Vendor description:</strong>
          {' '}
          <span>{description}</span>
        </p>
      )}
      {policyUrl && (
        <p className={Styles.privacy}>
          <strong>Privacy policy:</strong>
          {' '}
          {policyUrl.split(';').map(policy => (
            <>
              <a target="_blank" rel="noopener noreferrer" href={policy}>
                {policy}
              </a>
              &nbsp;
            </>
          ))}
        </p>
      )}
      {legitimateInterestClaimUrl && (
        <p className={Styles.privacy}>
          <strong>Legitimate Interest Claim:</strong>
          {' '}
          {legitimateInterestClaimUrl.split(';').map(claimURL => (
            <>
              <a target="_blank" rel="noopener noreferrer" href={claimURL}>
                {claimURL}
              </a>
              &nbsp;
            </>
          ))}
        </p>
      )}
    </section>
  );

  const paginationHandler = (_, noOfRows) => setPageSize(noOfRows);

  const renderActionsColumn = (vendor) => {
    const { nonIabId } = vendor;

    return (
      <Space key={nonIabId}>
        <Tooltip title="Delete">
          <Button
            data-test={`nonIAB_vendors_delete_vendor_${nonIabId}_button`}
            onClick={() => onDelete(vendor)}
            icon={<FontAwesomeIcon icon="trash-alt" />}
            type="text"
          />
        </Tooltip>
        <Tooltip title="Edit">
          <Button
            data-test={`nonIAB_vendors_edit_vendor_${nonIabId}_button`}
            onClick={() => onEdit(vendor)}
            icon={<FontAwesomeIcon icon={['fas', 'pencil']} />}
            type="text"
          />
        </Tooltip>
      </Space>
    );
  };

  const getColumns = () => ([
    {
      title: 'Non-IAB Vendor ID',
      dataIndex: 'nonIabVendor',
      key: 'nonIabVendor',
      width: '15%'
    },
    {
      title: 'Vendor Name',
      render: name => <span>{name}</span>,
      dataIndex: 'name',
      key: 'name',
      width: '65%'
    },
    {
      title: 'Version',
      dataIndex: 'gvlVersion',
      key: 'gvlVersion',
      width: '10%'
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActionsColumn
    },
  ]);

  const getData = () => vendors.map(vendor => ({
    nonIabVendor: vendor.vendorId,
    name: vendor.name,
    actions: vendor,
    gvlVersion: vendor.gvlVersion === GVL_VERSION_3 ? 'TCF 2.2' : 'TCF 2.0',
    key: vendor.nonIabId,
    vendor,
    isExpandible: !!vendor.children
  }));

  return (
    <div className={Styles.mainContainer}>
      <Table
        columns={getColumns()}
        dataSource={getData()}
        pagination={{
          pageSize,
          position: ['bottomCenter'],
          onShowSizeChange: paginationHandler,
          hideOnSinglePage: true
        }}
        rowClassName={(record) => {
          const { name } = record;
          if (name && !name.children) {
            return Styles.childInfo;
          }
          return '';
        }}
        expandable={{
          expandedRowRender: (record) => {
            const { vendor: { children } } = record;
            return buildChildInformation(children[0]);
          },
          expandIcon: renderExpandIcon(),
          rowExpandable: record => record.isExpandible,
        }}
        className={Styles.table}
        size="middle"
      />
    </div>
  );
};

export default NonIABTable;
