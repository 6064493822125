import { useState } from 'react';

function useBulkForm(initData = {}) {
  const [fields, setFields] = useState(initData);

  const handleInputChange = (input, value, newFields) => {
    const _handled = { ...newFields };
    if (value === undefined && input in _handled) {
      delete _handled[input];
    } else {
      _handled[input] = value;
    }
    return _handled;
  };

  const handleChange = (inputs, values) => {
    let newFields = { ...fields };
    if (Array.isArray(inputs)) {
      inputs.forEach((input) => {
        newFields = handleInputChange(input, values[input], newFields);
      });
    } else {
      newFields = handleInputChange(inputs, values, newFields);
    }

    setFields(newFields);
  };

  const cleanFields = () => setFields(initData);

  return [fields, handleChange, cleanFields];
}

export default useBulkForm;
