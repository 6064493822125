/* eslint-disable import/prefer-default-export */
import axios from 'axios';

// API V1 endpoints
export const requestCustomization = axios.create(
  {
    baseURL: process.env.REACT_APP_CHOICE_2_CUSTOMIZATION_API_URL,
    withCredentials: process.env.NODE_ENV === 'development'
  }
);

export const requestAnalytics = axios.create(
  {
    baseURL: process.env.REACT_APP_CHOICE_2_ANALYTICS_API_URL,
    withCredentials: process.env.NODE_ENV === 'development'
  }
);

export const requestAuditLogs = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_AUDIT_API_URL,
  withCredentials: process.env.NODE_ENV === 'development'
});

export const requestBuild = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_BUILD_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

export const requestScan = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_SITE_SCANNING_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
 });

// API V2 endpoints
export const requestCustomizationV2 = axios.create(
  {
    baseURL: process.env.REACT_APP_CHOICE_2_V2_CUSTOMIZATION_API_URL,
    withCredentials: process.env.NODE_ENV === 'development'
  }
);

export const requestAuditLogsV2 = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V2_AUDIT_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

export const requestBuildV2 = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V2_BUILD_API_URL,
  withCredentials: process.env.NODE_ENV === 'development'
});

export const requestVersionsV2 = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V2_CMP_VERSIONS_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

export const requestGoogleATP = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V2_GOOGLE_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

// API v1 & v2 endpoints
export const requestBatchBuild = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_BATCH_BUILD_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

// API V3 endpoints
export const requestCustomizationV3 = axios.create(
  {
    baseURL: process.env.REACT_APP_CHOICE_2_V3_CUSTOMIZATION_API_URL,
    withCredentials: process.env.NODE_ENV === 'development'
  }
);

export const requestGBCPurposes = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V1_GBC_PURPOSES_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

export const requestSurveyLink = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_SURVEY_LINK_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});

export const requestMSPAPurposes = axios.create({
  baseURL: process.env.REACT_APP_CHOICE_2_V1_MSPA_PURPOSES_API_URL,
  withCredentials: process.env.NODE_ENV === 'development',
});
