/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { previewTheme } from '../requests/preview';
import { formatDataToSubmit } from '../../utils/themes';
import { PREVIEW } from '../actions';

export function* fetchPreview({ payload }) {
  yield put({ type: PREVIEW.SHOW.PENDING });

  const formData = formatDataToSubmit(payload.values);
  const { res, err } = yield call(previewTheme, [formData]);
  if (res) {
    yield put({ type: PREVIEW.SHOW.FULFILLED, payload: res.data });
  } else {
    yield put({ type: PREVIEW.SHOW.REJECTED, payload: err.response });
  }
}
