/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function downloadFile(url, fileName) {
  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      // Get the content-disposition header from the response
      const contentDisposition = response.headers['content-disposition'];

      // Extract the filename from the content-disposition header using regex
      const matches = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      let downloadedFileName = fileName; // Use the provided filename as a fallback

      if (matches && matches.length > 1) {
        downloadedFileName = matches[1];
      }

      // Create the blob URL and initiate download with the extracted filename
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = downloadedFileName; // Set the filename for download
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(err => ({ err }));
}
