// @flow
import React, { useEffect, useState } from 'react';
import { faPencil, faChartBar, faEllipsisV } from '@fortawesome/fontawesome-pro-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildTableData } from '../../../utils/sites';
import Table from '../../../components/ui/table';
import Styles from '../sites.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAll } from '../../../store/duck/analytics';

type TableSitesProps = {
  functions: Object;
  analytics: {
    list: Array<Object>;
  };
  sites: Array<iSite>;
  themes: Array<iTheme>;
  pcode: string;
  setOpenAuditForm: Function;
  setSiteId: String;
  setShowDeleteDialog: Function;
}

const TableSites = ({
  sites,
  themes,
  analytics,
  functions,
  pcode,
  setOpenAuditForm,
  setShowDeleteDialog,
  setSiteId
}: TableSitesProps) => {
  const [data, setData] = useState([]);

  const { app, apps } = useSelector(state => state);
  const dispatch = useDispatch();

  const fetchAnalyticsData = (pageData) => {
    dispatch(fetchAll(app.pCode, pageData));
  }

  useEffect(() => {
    if (sites.length && themes.length) {
      setData(buildTableData({
        sites,
        themes,
        analytics,
        functions,
        pcode,
        setShowDeleteDialog,
        setSiteId,
        actions: [
          {
            title: 'Reports',
            link: {
              to: `/protect/p-${pcode}/sites/detail`,
              'data-test': 'sites_detail',
              children: <FontAwesomeIcon icon={faChartBar} className={Styles.icon} />
            },
            icon: faChartBar,
            goToReports: functions.goToReports,
            goToPremiumReports: functions.goToPremiumReports
          },
          {
            title: 'Edit',
            link: {
              to: `/protect/p-${pcode}/sites/edit`,
              'data-test': 'sites_edit',
              children: <FontAwesomeIcon icon={faPencil} className={Styles.icon} />
            }
          },
          {
            title: 'More Options',
            popover: 'popover',
            icon: faEllipsisV,
            prepareDownloadLog: functions.prepareDownloadLog,
            download: functions.download,
            getTag: functions.getTag,
            getAmpTag: functions.getAmpTag
          }
        ],
      }, setOpenAuditForm));
    }
  }, [sites, themes, analytics, functions, pcode]);

  let content = null;

  if (data.length) {
    const headers = {
      status: '',
      url: 'property',
      regulation: 'regulation',
      themeName: 'theme',
      consentRate: 'consent rate',
      cmpVersion: 'CMP version',
      propertyTypeLabel: 'type',
      actions: ''
    };

    content = (
      <Table
        data={data}
        headers={headers}
        isSites
        widths={{
          status: '8%',
          url: '24%',
          regulation: '14%',
          themeName: '10%',
          consentRate: '14%',
          cmpVersion: '14%',
          propertyTypeLabel: '8%',
          actions: '8%',
        }}
        size="small"
        fetchAnalyticsData={fetchAnalyticsData}
      />
    );
  }

  return content;
};

export default React.memo(TableSites);
