import { call, put } from 'redux-saga/effects';
import { APP } from '../actions';
import { setAccount } from '../duck/app';

const trimPCode = pCode => (pCode.length === 15 ? pCode.substring(2) : pCode);

export function* fetchUserAndAccountInfo({ payload: { pcode } }) {
  if (trimPCode(pcode)) {
    yield put(setAccount(undefined, trimPCode(pcode)));
  }
}

export function* clearUserAndAccountInfo() {
  yield put({ type: APP.ACCOUNT.CLEAR });
}
