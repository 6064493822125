import './styles.scss';

import { Col, Row } from 'antd/es/grid';

import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import React from 'react';
import { debounce } from 'lodash';
import { HEADER_REGULATION_TEXT } from '../../../../utils/constants';
import Preview from '../../../../assets/images/preview.png';
// @flow
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import RichText from '../../../../components/ui/forms/rich-text';
import Styles from '../../../../components/forms/sites/sitesForm.module.scss';
import TEXT from './text';
import Alert from '../../../../components/ui/alert';

type Props = {
  values: Record<any>,
  handleChange: Function,
  validations: Object,
  setFields: Function,
  isApp: boolean
}

/*
TODO
- Bugs
  - Handle Body placeholder text issue
*/
function CustomizeCCPA({ values, handleChange, validations, setFields, isApp = false }: Props) {
  return (
    <Card
      className={Styles.consentCard}
      title={TEXT.CARD_HEADER_V2}
      extra={HEADER_REGULATION_TEXT.CCPA_V2}
    >
      <Row gutter={[16, 16]}>
        <Col span="24">
          <Form.Item
            label={TEXT.HEADLINE_V2}
            name="uspDnsTitleText"
            rules={validations.textInput}
          >
            <Input
              allowClear
              placeholder={TEXT.HEADLINE_PLACEHOLDER}
              value={values.uspDnsTitleText}
            />
          </Form.Item>
        </Col>
        <Col span="24">
          <label htmlFor="uspDnsText">{TEXT.BODY_V2}</label>
          {(
            <RichText
              input={{
                name: 'uspDnsText',
                value: values.uspDnsText,
                onChange: debounce(event => handleChange(preValues => ({ ...preValues, uspDnsText: event })), 100)
              }}
              setFields={setFields}
              editorClassName="input-uspDnsText"
              className="uspDnsText-container"
              placeholder="
                If you wish to opt-out of the sale, sharing to third parties, or processing of
                your personal or sensitive information for targeted advertising by us, please use
                the below opt-out section to confirm your selection. Please note that after your
                opt-out request is processed you may continue seeing interest-based ads based on
                personal information utilized by us or personal information disclosed to third
                parties prior to your opt-out. You may separately opt-out of the further disclosure
                of your personal information by third parties on the IAB’s list of
                downstream participants. This information may also be disclosed by us to third
                parties on the IAB’s List of Downstream Participants that may further disclose
                it to other third parties.
                "
            />
          )}
        </Col>
        <Col span="24">
          <Form.Item
            name="suppressCcpaLinks"
            valuePropName="checked"
          >
            <Checkbox
              className="checkbox-label"
              onChange={event => handleChange(preValues => ({ ...preValues, suppressCcpaLinks: event.target.checked }))}
            >
              {TEXT.SUPRESSLINKS_V2}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span="24">
          <label htmlFor="uspDeleteDataLinkText">{TEXT.DELETION_LINK}</label>
          <Row gutter={[16, 16]}>
            <Col span="12">
              <Form.Item
                name="uspDeleteDataLinkText"
                dependencies={['uspDeleteDataLink']}
                rules={validations.customLinksText('uspDeleteDataLink')}
              >
                <Input
                  allowClear
                  data-testid="uspDeleteDataLinkText"
                  placeholder="Link Text"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="uspDeleteDataLink"
                dependencies={['uspDeleteDataLinkText']}
                rules={validations.customLinksUrl('uspDeleteDataLinkText')}
              >
                <Input
                  allowClear
                  placeholder="Link URL"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <label htmlFor="uspAccessDataLinkText">{TEXT.ACCESS_LINK}</label>
          <Row gutter={[16, 16]}>
            <Col span="12">
              <Form.Item
                name="uspAccessDataLinkText"
                dependencies={['uspAccessDataLink']}
                rules={validations.customLinksText('uspAccessDataLink')}
              >
                <Input
                  allowClear
                  placeholder="Link Text"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="uspAccessDataLink"
                dependencies={['uspAccessDataLinkText']}
                rules={validations.customLinksUrl('uspAccessDataLinkText')}
              >
                <Input
                  allowClear
                  placeholder="Link URL"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <label htmlFor="uspPrivacyPolicyLinkText">{TEXT.PRIVACY_POLICY_LINK}</label>
          <Row gutter={[16, 16]}>
            <Col span="12">
              <Form.Item
                name="uspPrivacyPolicyLinkText"
                dependencies={['uspPrivacyPolicyLink']}
                rules={validations.customLinksText('uspPrivacyPolicyLink')}
              >
                <Input
                  allowClear
                  placeholder="Link Text"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="uspPrivacyPolicyLink"
                dependencies={['uspPrivacyPolicyLinkText']}
                rules={validations.customLinksUrl('uspPrivacyPolicyLinkText')}
              >
                <Input
                  allowClear
                  placeholder="Link URL"
                  disabled={values.suppressCcpaLinks === true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <div>
            <label>{TEXT.PREVIEW}</label>
            <img src={Preview} alt="PreviewCCPA" className="preview-image-container" />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default CustomizeCCPA;
