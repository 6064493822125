import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withPcode from '../../components/hoc/withPcode';
import withNavbar from '../../components/hoc/with-navbar';
import * as ThemesActions from '../../store/duck/themes';
import * as SitesActions from '../../store/duck/sites';
import * as AnalyticsActions from '../../store/duck/analytics';
import * as VendorActions from '../../store/duck/vendors';
import * as VersionV2Actions from '../../store/duck/versionsV2';
import SiteDetailContainer from './siteDetailContainer';

const MapStateToProps = (
  { sites, themes, app, analytics, form, vendors, cmpVersionV2 }
) => ({ sites, themes, app, analytics, form: form.sites, vendors, cmpVersionV2 });

const MapDispatchToProps = dispatch => ({
  actions: {
    themes: bindActionCreators(ThemesActions, dispatch),
    sites: bindActionCreators(SitesActions, dispatch),
    analytics: bindActionCreators(AnalyticsActions, dispatch),
    vendors: bindActionCreators(VendorActions, dispatch),
    cmpVersionV2: bindActionCreators(VersionV2Actions, dispatch)
  }
});

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(withPcode(withNavbar(SiteDetailContainer)));
