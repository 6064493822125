/* eslint-disable import/prefer-default-export */
import { call, put, all, select, take } from "redux-saga/effects";
import { formatDataToSubmit } from "../../utils/sites";
import { ALERTS, ANALYTICS, SITES, THEMES, CMP_VERSIONS_V2, APP } from "../actions";
import {
  createSite,
  editSite,
  deleteSite,
  getById,
  getInitSitesLoad,
  getSupportCustomVendors
} from "../requests/sites";
import { buildAmpTag } from "../requests/build";
import { mockEmptyAnalyticByURL } from "../../__mocks__/analytics";
import { buildError } from "../../utils/reducer";
import { CURRENT_MAJOR_VERSION } from "../../utils/constants";

export function* fetchCreateSite({ payload }) {
  yield put({ type: SITES.CREATE.PENDING });

  const { res, err } = yield call(createSite, [formatDataToSubmit(payload.siteData)]);

  if (res) {
    const { pCode, ampEnabled } = payload.siteData;

    yield put({ type: ANALYTICS.GET_BY_URL.FULFILLED, payload: { ...mockEmptyAnalyticByURL, url: res.data.url } });

    if (!ampEnabled) {
      yield put({ type: SITES.GENERATE_NON_IAB_LIST.FETCH, payload: { pCode, siteId: res.data.siteId } });
    }

    yield put({ type: SITES.CREATE.FULFILLED, payload: res.data });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: "success",
        message: `The site ${res.data.url} was created successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    yield put({ type: SITES.CREATE.REJECTED, payload: err.response });
  }
}

export function* fetchEditSite({ payload }) {
  yield put({ type: SITES.EDIT.PENDING });

  const { res, err } = yield call(editSite, [payload.siteId, formatDataToSubmit(payload.siteData)]);

  if (res) {
    const { pCode, ampEnabled } = payload.siteData;

    if (payload.urlChanges && !ampEnabled) {
      yield put({ type: SITES.GENERATE_NON_IAB_LIST.FETCH, payload: { pCode, siteId: res.data.siteId } });
    }

    yield put({ type: SITES.EDIT.FULFILLED, payload: { data: res.data, id: parseInt(payload.siteId, 10) } });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: "success",
        message: `The site ${res.data.url} was edited successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    yield put({ type: SITES.EDIT.REJECTED, payload: err.response });
  }
}

export function* fetchDeleteSite({ payload }) {
  yield put({ type: SITES.DELETE.PENDING });

  const { res, err } = yield call(deleteSite, [payload.siteId]);

  if (res) {
    yield put({ type: SITES.DELETE.FULFILLED, payload: { id: payload.siteId } });
  } else {
    yield put({ type: SITES.DELETE.REJECTED, payload: err.response });
  }
}

export function* fetchSitesById({ payload }) {
  if ("pCode" in payload) {
    yield put({ type: SITES.GET_BY_ID.PENDING });

    const { res, err } = yield call(getById, [payload.id]);

    if (res) {
      if (res.data.pCode === payload.pCode) {
        yield put({ type: SITES.GET_BY_ID.FULFILLED, payload: res.data });
      } else {
        yield put({ type: SITES.GET_BY_ID.REJECTED, payload: buildError({ status: 401, message: "Unauthorized" }) });
      }
    } else {
      yield put({
        type: SITES.GET_BY_ID.REJECTED,
        payload: err.response
      });
    }
  }
}

export function* fetchSitesInitData({ payload }) {
  let pCode = yield select(state => state.app.pCode);
  if (pCode == null) {
    yield take(APP.ACCOUNT.SET);
    pCode = yield select(state => state.app.pCode);
  }

  const actionsToCall = [
    put({ type: SITES.GET_ALL.PENDING }),
    put({ type: ANALYTICS.GET_ALL.FETCH, payload: { pCode: pCode } })
  ];

  if (payload.cmpVersions) {
    actionsToCall.push(put({ type: CMP_VERSIONS_V2.GET.FETCH, payload: { pCode: pCode } }));
  }

  yield all(actionsToCall);

  const { res, err } = yield call(getInitSitesLoad, [payload.isPremium, pCode, payload.type]);

  if (res) {
    yield all([
      put({
        type: SITES.GET_ALL.FULFILLED,
        payload: {
          sites: res.sites.filter(site => site.majorVersion === CURRENT_MAJOR_VERSION)
        }
      }),
      put({
        type: THEMES.GET_ALL.FULFILLED,
        payload: res.themes.filter(theme => theme.majorVersion === CURRENT_MAJOR_VERSION)
      })
    ]);
  } else {
    yield put({ type: SITES.GET_ALL.REJECTED, payload: err.response });
  }
}

export function* fetchAmpTag({ payload }) {
  yield put({ type: SITES.GET_AMP_TAG.PENDING });

  const { res, err } = yield call(buildAmpTag, [payload]);

  if (res) {
    yield put({ type: SITES.GET_AMP_TAG.FULFILLED, payload: res.data });
  } else {
    yield put({ type: SITES.GET_AMP_TAG.REJECTED, payload: err.response });
  }
}

export function* fetchSupportedCustomizedVendors({ payload }) {
  yield put({ type: SITES.GET_SUPPORT_CUSTOM_VENDORS.PENDING });

  const [supported, noSupported] = yield all([
    call(getSupportCustomVendors, [payload]),
    call(getSupportCustomVendors, [payload, false])
  ]);

  if (supported || noSupported) {
    yield put({
      type: SITES.GET_SUPPORT_CUSTOM_VENDORS.FULFILLED,
      payload: { supported, noSupported }
    });
  } else {
    yield put({
      type: SITES.GET_SUPPORT_CUSTOM_VENDORS.REJECTED,
      payload: { supported: [], noSupported: [] }
    });
  }
}
