import { Space, Tag } from 'antd/es';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/fontawesome-pro-solid';
import type { IComparisonStatus, IMetricValueType } from '../types';
import Style from '../premiumReports.module.scss';
import { formatNumber } from '../utils';
import { STATUS, VALUE_TYPE } from '../constants';
import TEXT from '../text';
// @flow

interface IMetricsCardItemProps {
  metricName: string;
  metricValue: number;
  comparisonPercentage: number;
  comparisonStatus: IComparisonStatus;
  metricValueType: IMetricValueType;
  iconImage: any;
}

const MetricsCardItem = ({
  metricName,
  metricValue,
  comparisonPercentage,
  comparisonStatus,
  metricValueType,
  iconImage
}: IMetricsCardItemProps) => {
  const displayMetricValue = metricValueType === VALUE_TYPE.PERCENTAGE
    ? `${formatNumber(metricValue)}%` : formatNumber(metricValue);
  const tagBgColor = comparisonStatus === STATUS.POSITIVE ? 'rgba(232, 244, 218, 1)' : 'rgba(255, 228, 225, 1)';
  const tagColor = comparisonStatus === STATUS.POSITIVE ? 'rgba(82, 119, 37, 1)' : 'rgba(188, 21, 3, 1)';
  const tagIcon = comparisonPercentage > 0 ? faArrowUp : faArrowDown;

  return (
    <Space direction="vertical" size="middle" className={Style.metricsCardItem}>
      <Space>
        <div className={Style.iconContainer}>
          <img src={iconImage} alt={metricName} />
        </div>
        <p>{metricName}</p>
      </Space>
      <p className={Style.metricValue}>{displayMetricValue}</p>
      {comparisonStatus === STATUS.NEUTRAL ? (
        <Tag color="rgba(215, 226, 250, 1)" style={{ border: 'none' }}>
          <p className={Style.comparisonPercentage} style={{ color: 'rgba(93, 107, 137, 1)' }}>
            {TEXT.NO_CHANGE}
          </p>
        </Tag>
      ) : (
        <Tag color={tagBgColor} style={{ border: 'none' }}>
          <Space>
            <FontAwesomeIcon icon={tagIcon} color={tagColor} />
            <p className={Style.comparisonPercentage} style={{ color: tagColor }}>
              {`${Math.abs(comparisonPercentage.toFixed(1))}%`}
            </p>
          </Space>
        </Tag>
      )}
    </Space>
  );
};

export default MetricsCardItem;
