import { Col, Row } from 'antd/es/grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { faDatabase, faPlusCircle } from '@fortawesome/fontawesome-pro-solid';
import Button from 'antd/es/button';
import Empty from 'antd/es/empty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import { useHistory } from 'react-router-dom';
import { ALERT_IDENTIFIERS, GVL_VERSION_2, GVL_VERSION_3, NO_PROPERTIES_TEXTS } from '../../utils/constants';
/* eslint-disable prefer-destructuring */
// @flow

import AlertsContainer from '../../components/ui/alerts-container';
import AuditLogModal from './components/audit-logs-modal';
import ContentToolbar from '../../components/ui/content-toolbar';
import FilterInput from '../../components/ui/forms/filter-input';
import Loader from '../../components/ui/loader';
import ModalAuditLogs from './components/modal-fetch-audit-logs';
import ModalDelete from './components/modal-form/ModalDelete';
import ModalTag from '../../components/ui/modal-tag';
import { NotFoundPage } from '../not-found';
import SitesFormsPage from './components/sites-form-page';
import Styles from './sites.module.scss';
import { TAG_INSTRUCTIONS_LINK } from '../../utils/tag';
import TableSites from './components/table';
import Unauthorized from '../../components/ui/unauthorized';
import type { iSitesContainerProps } from '../../interfaces/sites';
import useGBCPurposes from '../../components/forms/gbc/useGBCPurposes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSurveyLink } from '../../store/duck/survey-link';
import formatInitData from '../../components/forms/sites/formatData';
import { useOldVersionProperties } from '../../data/hooks/banner';
import { Storage } from 'aws-amplify';
import OldVersionBanner from './components/old-version-banner/oldVersionBanner';
import { csvToJSON, downloadCsv } from '../../utils/property';

// eslint-disable-next-line max-len
const SitesContainer = ({ app, apps, actions, sites, vendors, match, audit, analytics, themes, cmpVersionV2 }: iSitesContainerProps) => {
  const history = useHistory();
  const [openAuditForm, setOpenAuditForm] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [vendorsFetched, setVendorsFetched] = useState(false);
  const [isModalTagOpen, setIsModalTagOpen] = useState(false);
  const [isAmpModalTagOpen, setIsAmpModalTagOpen] = useState(false);
  const [isCreatedOrEdit, setIsCreatedOrEdit] = useState(false);
  const [siteTagUrl, setSiteTagUrl] = useState('');
  const [siteIdforAmp, setSiteIdforAmp] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [siteDeleteId, setSiteId] = useState(null);
  const [bannerOpen, setBannerOpen] = useState(true);
  const noResults = null;
  const auditLogsInterval = useRef(null);
  const gbcPurposes = useGBCPurposes();

  const { surveyLink } = useSelector(state => state);
  const dispatch = useDispatch();

  const {data: oldVersionProperties, isLoading} = useOldVersionProperties(app.pCode);

  const jsonContent = oldVersionProperties ? csvToJSON(oldVersionProperties) : '';
  const showVersionChangeBanner = oldVersionProperties && match.params.action !== 'new' && match.params.action !== 'edit';

  useEffect(() => {
    actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_2);
    actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_3);
  }, [app.pcode]);

  const handleToggleBanner = () => {
    setBannerOpen(!bannerOpen);
  }

  const fetchAuditLogsStatus = (shouldFetch) => {
    clearInterval(auditLogsInterval.current);
    if (shouldFetch) {
      auditLogsInterval.current = setInterval(() => actions.auditLogs.fetchStatus(app.pCode), 60000);
    }
  };

  const withFilteredResults = (data: Array<any>): void => {
    actions.sites.useFilterData();
    setFilteredData(data);
  };

  const prepareDownloadLog = (siteObj) => {
    const newSiteObj = {
      ...siteObj,
      url: /^(?:http(s)?:\/\/)/i.test(siteObj.url) ? siteObj.url : `http://${siteObj.url}`
    };

    actions.auditLogs.fetchPrepareDownload(app.pCode, newSiteObj);
  };

  const goToReports = (propertyType, siteId) => {
    history.push(`/protect/p-${app.pCode}/${propertyType}/detail/${siteId}`);
  }

  const goToPremiumReports = (propertyType, siteId) => {
    history.push(`/protect/p-${app.pCode}/${propertyType}/reports/${siteId}`);
  }

  const getTag = (siteUrl) => {
    setIsModalTagOpen(true);
    setSiteTagUrl(siteUrl);
  };

  const getAmpTag = (siteUrl, siteId) => {
    setIsAmpModalTagOpen(true);
    setSiteTagUrl(siteUrl);
    setSiteIdforAmp(siteId);
  };

  const onSubmit = (e: SyntheticEvent, values) => {
    const { current: { url } } = sites;

    e.preventDefault();

    if (match.params.action === 'new') actions.sites.createSite(values, history);
    if (match.params.action === 'edit' && 'id' in match.params) {
      const urlChanges = values.url !== url;
      actions.sites.editSite(match.params.id, values, history, urlChanges);
    }
  };

  const onDeleteSite = () => {
    const { current: { pCode, url, propertyType, packageId } } = sites;
    if (propertyType === 'SITE') {
      actions.sites.deleteSite(siteDeleteId, pCode, url);
    } else {
      actions.apps.deleteApp(siteDeleteId, pCode, packageId);
    }
    setShowDeleteDialog(false);
    setSiteId(null);
  };

  const setSurveyLinkBanner = () => {
    if (surveyLink.link) {
      actions.alerts.add({
        id: ALERT_IDENTIFIERS.SURVEY_LINK,
        type: 'success',
        message: 'Help Us Improve Our Documentation!',
        description: (
          <span>
            Your feedback matters! Take a quick survey to let us know how effective our product documentation is.
          </span>
        ),
        closable: true,
        autoClose: false,
        buttons: [{
          text: 'QUICK SURVEY',
          size: 'small',
          type: 'link',
          href: surveyLink.link,
          target: '_blank'
        }]
      });
    }
  };

  const setTotalConsentAcceptedAlert = () => {
    if (filteredData.filter(row => !(row.totalConsentAccepted) && row.themeId).length) {
      actions.alerts.add({
        id: ALERT_IDENTIFIERS.UNIVERSAL_TAG_ALERT,
        type: 'warning',
        message: 'Congrats on adding your property! Next, retrieve the Universal Tag.',
        description: (
          <span>
            Please make sure your tag is correctly inserted into the header of your site.
            Once your first visitor comes, it may take up to 24 hours for the alert on your property to turn green.
          </span>
        ),
        closable: true,
        autoClose: false,
        buttons: [{
          text: 'TAG INSTRUCTIONS',
          size: 'small',
          type: 'link',
          href: TAG_INSTRUCTIONS_LINK,
          target: '_blank'
        }]
      });
    }
  };

  useEffect(() => {
    setSurveyLinkBanner();
  }, [surveyLink]);

  const hideAuditLogResults = () => {
    actions.auditLogs.hideAuditModals(
      audit.showAuditLogsFailModal,
      audit.auditLogs.filter(log => log.queryState === 'FAILED' || log.queryState === 'CANCELLED'),
      app.pCode
    );
  };

  const callAuditLogModalAction = () => {
    if (audit.showAuditLogsSuccessModal) {
      actions.auditLogs.fetchDownloadAll(audit.auditLogs, app.pCode);
    }
  };

  const title = match.params.action === 'new' ? 'Create a site' : 'Edit a site';

  useEffect(() => {
    setIsCreatedOrEdit(match.params.action === 'edit' || match.params.action === 'new');
  }, [match]);

  const content = useMemo(() => {
    let table = null;
    if (sites.error.status === 401 || sites.error.status === 403) {
      return (
        <div className={Styles.wrapper}>
          <Unauthorized />
        </div>
      );
    } if (
      [undefined, 'new', 'edit', 'delete', 'newuser'].indexOf(match.params.action) === -1
      || sites.error.status === 404 || (match.params.action === 'edit' && !(match.params.id))
    ) {
      return (<NotFoundPage />);
    } if (match.params.action === 'edit' || match.params.action === 'new') {
      setIsCreatedOrEdit(true);
      return (
        sites.pending || gbcPurposes.length === 0 ? <Loader /> : (
          <SitesFormsPage
            onSubmit={onSubmit}
            themes={themes.list}
            sites={sites.list}
            versionsV2={cmpVersionV2.list}
            title={title}
            toolTipTitle={
              `Learn more about how to ${match.params.action === 'new' ? 'create' : 'edit'} a site in our help article.`
            }
            initData={formatInitData(app.accountId, app.pCode, sites.current, gbcPurposes)}
            pending={sites.pending}
            history={history}
            isPremium={app.isPremium}
            pcode={app.pCode}
            vendors={vendors}
          />
        )
      );
    }

    if (sites.list.length && themes.list.length && !cmpVersionV2.pending && !isCreatedOrEdit) {
      if (!vendorsFetched) {
        actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_2);
        actions.vendors.fetchAllVendors(app.pCode, GVL_VERSION_3);
        setVendorsFetched(true);
      }
      table = (
        <TableSites
          sites={filteredData}
          themes={themes.list}
          analytics={analytics}
          audit={audit}
          cmpVersionV2={cmpVersionV2}
          isPremium={app.isPremium}
          setShowDeleteDialog={setShowDeleteDialog}
          setSiteId={setSiteId}
          functions={{
            setAlerts: () => {},
            prepareDownloadLog,
            goToPremiumReports,
            goToReports,
            download: actions.auditLogs.fetchDownload,
            getTag,
            getAmpTag
          }}
          pcode={app.pCode}
          setOpenAuditForm={setOpenAuditForm}
        />
      );
    }

    if (!table && !isCreatedOrEdit) {
      table = (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={(
            <>
              <Typography.Title level={4}>{NO_PROPERTIES_TEXTS.title}</Typography.Title>
              <p>{NO_PROPERTIES_TEXTS.description}</p>
            </>
          )}
        >
          <Space>
            <Button
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              type="primary"
              onClick={() => history.push(`/protect/p-${app.pCode}/apps/new`)}
            >
              PROTECT AN APP
            </Button>
            <Button
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              type="primary"
              onClick={() => history.push(`/protect/p-${app.pCode}/sites/new`)}
            >
              PROTECT A SITE
            </Button>
          </Space>
        </Empty>
      );
    }

    if ((sites.fetched && !cmpVersionV2.pending && !isCreatedOrEdit) || showDeleteDialog) {
      return (
        <div className={Styles.wrapper}>
          <div className={Styles.alerts}>
            <AlertsContainer customActions={actions} />
          </div>
          <div className={Styles.header}>
            <Row gutter={[16, 12]}>
              <Col span="24">
                <ContentToolbar>
                  <ContentToolbar.Column>
                    <p>Properties protected by InMobi CMP</p>
                  </ContentToolbar.Column>
                  <ContentToolbar.Column align="right">
                    <Space>
                      <FilterInput
                        filterBy={['url', 'packageId']}
                        onFilter={withFilteredResults}
                        baseData={sites.list}
                        className={Styles.filterInput}
                      />
                      <Button
                        href={`/protect/p-${app.pCode}/apps/new`}
                        data-testid="sites_protect_an_app_btn"
                        type="primary"
                        icon={<FontAwesomeIcon icon={faPlusCircle} />}
                        className={Styles.buttonLink}
                      >
                        PROTECT AN APP
                      </Button>
                      <Button
                        href={`/protect/p-${app.pCode}/sites/new`}
                        data-testid="sites_protect_a_site_btn"
                        type="primary"
                        icon={<FontAwesomeIcon icon={faPlusCircle} />}
                        className={Styles.buttonLink}
                      >
                        PROTECT A SITE
                      </Button>
                    </Space>
                  </ContentToolbar.Column>
                </ContentToolbar>
              </Col>
              <Col span="24">
                <ContentToolbar>
                  <ContentToolbar.Column align="right">
                    <Button
                      href={`/protect/p-${app.pCode}/bulk-update`}
                      data-testid="sites_bulk_update"
                      type="primary"
                      icon={<FontAwesomeIcon icon={faDatabase} />}
                      className={Styles.buttonLink}
                    >
                      BULK UPDATE
                    </Button>
                  </ContentToolbar.Column>
                </ContentToolbar>
              </Col>
            </Row>
          </div>
          <div className={Styles.content}>
            {noResults
              ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Results Found"
                />
              ) : table}
          </div>
          {showDeleteDialog && (
            <ModalDelete
              onSubmit={onDeleteSite}
              open={showDeleteDialog}
              toggleOpen={() => setShowDeleteDialog(false)}
              editable={sites.current.editable}
              disabled={sites.pending}
              propertyType={sites.current.propertyType}
            />
          )}
        </div>
      );
    }
    return <Loader />;
  }, [
    app,
    analytics,
    sites,
    sites.list,
    sites.current,
    themes,
    themes.list,
    match,
    cmpVersionV2,
    isCreatedOrEdit,
    showDeleteDialog,
    siteDeleteId
  ]);

  useEffect(() => {
    actions.sites.permissionsGrant();

    if (sites.error.status !== 401) {
      if ([undefined, 'delete', 'newuser'].indexOf(match.params.action) !== -1) {
        actions.sites.getSitesInitData(app.pCode, undefined, true, false);
      } else if (['new', 'edit'].indexOf(match.params.action) !== -1) {
        actions.sites.getSitesInitData(app.pCode);
      }
    }

    actions.auditLogs.fetchStatus(app.pCode);
    actions.bulkUpdate.fetchBulkUpdateStatus({ pCode: app.pCode, page: 'sites' });

    dispatch(fetchSurveyLink(app.pCode));
  }, []);

  useEffect(() => {
    setTotalConsentAcceptedAlert();
    if (audit.pendingLogs) {
      fetchAuditLogsStatus(audit.pendingLogs);
    } else {
      clearInterval(auditLogsInterval.current);
    }

    if (sites.current.finished || apps.current.finished) {
      // eslint-disable-next-line no-param-reassign
      apps.current = {};
      history.push(`/protect/p-${app.pCode}/properties`);
    }
  }, [filteredData, audit, sites, apps]);

  useEffect(() => {
    if (
      sites
      && sites.list.length
      && !sites.pending
      && !sites.useFilterData) {
      setFilteredData(sites.list);
    }
    if (sites.list.length) {
      if (match.params.action === 'edit' || showDeleteDialog) {
        if (
          !Object.keys(sites.current).length
          && !sites.pending
          && sites.error.status !== 401
          && sites.error.status !== 404
        ) {
          const _idSite = match.params.action === 'edit' ? match.params.id : siteDeleteId;
          actions.sites.getById(_idSite, app.pCode);
        }
      }

      if (isAmpModalTagOpen && siteTagUrl && !sites.ampConfig
        && siteIdforAmp && !sites.pending && sites.error.status !== 401
        && sites.error.status !== 404) {
        actions.sites.fetchAmpTag(siteIdforAmp);
      }
    }

    if (match.params.action === 'new') {
      if (Object.keys(sites.current).length && !sites.pending) {
        actions.sites.clearCurrent();
      }
    }

    if (Object.keys(sites.current).length && match.params.action === undefined && !showDeleteDialog) {
      actions.sites.clearCurrent();
    }

    if (Object.keys(vendors.v2.data).length) {
      setVendorsFetched(true);
    }
  }, [sites, match, vendors, siteIdforAmp, isAmpModalTagOpen, siteTagUrl, vendors.v2.data, showDeleteDialog]);

  useEffect(() => () => clearInterval(auditLogsInterval.current), []);

  return (
    <>

      {content}
      {openAuditForm.show && (
        <ModalAuditLogs
          infoAuditSite={openAuditForm}
          toggleOpen={() => setOpenAuditForm(prev => ({ show: !prev.show }))}
        />
      )}
      {
        showVersionChangeBanner && (
          <OldVersionBanner isOpen={bannerOpen} data = {jsonContent} download={() => downloadCsv(oldVersionProperties, app.pCode)} onClose={handleToggleBanner}/>
        )
      }
      <ModalTag
        pCode={app.pCode}
        isPremium={false}
        isOpen={isModalTagOpen}
        handleClose={() => setIsModalTagOpen(false)}
        isSiteSpecific
        siteUrl={siteTagUrl}
      />
      <ModalTag
        pCode={app.pCode}
        isPremium={false}
        isOpen={isAmpModalTagOpen}
        handleClose={() => {
          setIsAmpModalTagOpen(false);
          actions.sites.clearAmpTag();
        }}
        isAmp
        siteUrl={siteTagUrl}
        ampConfig={sites.ampConfig}
        loading={sites.pending}
      />
      <AuditLogModal
        audit={audit}
        OkAction={hideAuditLogResults}
        CancelAction={callAuditLogModalAction}
        actions={actions}
      />
    </>
  );
};

export default SitesContainer;
