import { buildError } from '../../utils/reducer';
import { ANALYTICS } from '../actions';

const defState = {
  current: { idProperty: '' },
  currentAbTest: {},
  error: {
    code: 200,
    message: ''
  },
  list: [],
  pending: false,
};

export function fetchAll(pcode, pageSiteIds) {
  return { type: ANALYTICS.GET_ALL.FETCH, payload: { pcode, pageSiteIds } };
}

export function fetchByURL(url, pCode, pageSiteIds) {
  return { type: ANALYTICS.GET_BY_URL.FETCH, payload: { url, pCode,  pageSiteIds} };
}

export function fetchByApp(appId, pCode, pageSiteIds) {
  return { type: ANALYTICS.GET_BY_APP.FETCH, payload: { appId, pCode, pageSiteIds } };
}

export function fetchAbTestBySite(siteId, pCode) {
  return { type: ANALYTICS.GET_AB_TEST_BY_SITE.FETCH, payload: { siteId, pCode } };
}

export function clearCurrent() {
  return { type: ANALYTICS.GET_BY_URL.CLEANED };
}

function analyticsReducer(state = defState, action) {
  switch (action.type) {
    // Pending
    case ANALYTICS.GET_ALL.PENDING:
    case ANALYTICS.GET_BY_URL.PENDING:
    case ANALYTICS.GET_AB_TEST_BY_SITE.PENDING:
    case ANALYTICS.GET_BY_APP.PENDING: {
      return { ...state, pending: true };
    }
    // Fulfilled
    case ANALYTICS.GET_ALL.FULFILLED: {
      return { ...state, list: [...action.payload], pending: false };
    }
    case ANALYTICS.GET_BY_URL.FULFILLED:
    case ANALYTICS.GET_BY_APP.FULFILLED: {
      return {
        ...state,
        current: { ...action.payload.current, idProperty: action.payload.idProperty, url: action.payload.url },
        pending: false
      };
    }
    case ANALYTICS.GET_AB_TEST_BY_SITE.FULFILLED: {
      return { ...state, currentAbTest: action.payload[0], pending: false };
    }
    // Rejected
    case ANALYTICS.GET_ALL.REJECTED:
    case ANALYTICS.GET_BY_URL.REJECTED:
    case ANALYTICS.GET_AB_TEST_BY_SITE.REJECTED: {
      return {
        ...state,
        pending: false,
        error: buildError(action.payload)
      };
    }
    case ANALYTICS.GET_BY_APP.REJECTED: {
      return {
        ...state,
        current: { ...action.payload.current, idProperty: action.payload.idProperty },
        pending: false,
        error: buildError(action.payload)
      };
    }
    // Others
    case ANALYTICS.GET_BY_URL.CLEANED: {
      return { ...state, current: {}, currentAbTest: {} };
    }
    default: {
      return state;
    }
  }
}

export default analyticsReducer;
