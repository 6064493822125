import { call, put } from 'redux-saga/effects';
import { BUILD } from '../actions';
import { batchUpdateAllChoiceJsFiles } from '../requests/build';

export default function* patchAllChoiceJsUpdate({ payload }) {
  yield put({ type: BUILD.BATCH_UPDATE_CHOICEJS.PENDING });

  const { res, err } = yield call(batchUpdateAllChoiceJsFiles, [payload.pCode]);

  if (res) {
    yield put({ type: BUILD.BATCH_UPDATE_CHOICEJS.FULFILLED, payload: res.data });
  } else {
    yield put({ type: BUILD.BATCH_UPDATE_CHOICEJS.REJECTED, payload: err.response });
  }
}
