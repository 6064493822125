/* eslint-disable import/prefer-default-export */
import { URL_IMAGE_INVALID, IMAGE_EXCEED_RATIO } from './constants';

export const setImageSizeParameters = (url, isApp) => {
  if (!url.includes('qc-size')) {
    const img = new Image();
    img.src = url;
    const querySymbol = url.includes('?') ? '&' : '?';
    return img.decode()
      .then(() => {
        const imgRatio = img.width / img.height;
        if ((isApp && imgRatio > 3.5) || imgRatio < 1) {
          throw IMAGE_EXCEED_RATIO;
        }
        return `${querySymbol}qc-size=${img.width},${img.height}`;
      })
      .catch((err) => {
        if (typeof err === 'string') {
          throw err;
        }
        throw URL_IMAGE_INVALID;
      });
  }
  return Promise.resolve('');
};
