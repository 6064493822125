// @flow
import React from 'react';

type Props = {
  title: string;
  list: Array;
  slice?: boolean;
}

function List({ title, list, slice }: Props) {
  if (list && list.length > 0) {
    return (
      <div>
        <br />
        <p><strong>{slice ? title.slice(0, 1) : title}</strong></p>
        {list.map(item => (
          <p key={item.id}>{slice && item.name ? item.name.slice(0, 1) : item.name}</p>
        ))}
      </div>
    );
  }

  return null;
}

List.defaultProps = { slice: false };

export default List;
