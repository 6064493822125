import { BULK_UPDATES } from '../actions';

const defState = {
  status: {
    bulkUpdateStatus: { done: false },
    pending: false,
    response: {
      code: 200,
      message: ''
    }
  },
  update: { pending: false, successed: false, error: false }
};

export function fetchBulkUpdateStatus({ pCode, page }) {
  return {
    type: BULK_UPDATES.STATUS.FETCH,
    payload: { pCode, page }
  };
}

export function fetchBulkUpdate({ pCode, sites, fields }) {
  return {
    type: BULK_UPDATES.UPDATE.FETCH,
    payload: { pCode, sites, fields }
  };
}

export function resetBulkUpdate() {
  return { type: BULK_UPDATES.UPDATE.RESET };
}

function bulkUpdatesReducer(state = defState, action) {
  switch (action.type) {
    case BULK_UPDATES.UPDATE.RESET: {
      return {
        ...state,
        update: { ...defState.update }
      };
    }
    case BULK_UPDATES.UPDATE.PENDING: {
      return {
        ...state,
        update: {
          ...state.update,
          pending: true,
          error: false,
          successed: false,
        }
      };
    }
    case BULK_UPDATES.UPDATE.FULFILLED: {
      return {
        ...state,
        update: {
          ...state.update,
          successed: true,
          error: false,
          pending: false,
        }
      };
    }
    case BULK_UPDATES.UPDATE.REJECTED: {
      return {
        ...state,
        update: {
          ...state.update,
          error: true,
          successed: false,
          pending: false,
        }
      };
    }
    case BULK_UPDATES.STATUS.PENDING: {
      return {
        ...state,
        status: {
          ...state.status,
          bulkUpdateStatus: { done: false },
          pending: true
        }
      };
    }
    case BULK_UPDATES.STATUS.FULFILLED: {
      return {
        ...state,
        status: {
          ...state.status,
          bulkUpdateStatus: { ...action.payload, done: true },
          pending: false,
          response: { code: 200, message: '' }
        }
      };
    }
    case BULK_UPDATES.STATUS.REJECTED: {
      return {
        ...state,
        status: {
          ...state.status,
          bulkUpdateStatus: { done: true },
          pending: false,
          response: { code: action.payload.status, message: action.payload.message }
        }
      };
    }
    default: {
      return state;
    }
  }
}

export default bulkUpdatesReducer;
