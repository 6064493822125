// @flow
import React from 'react';
import Loader from '../../../../components/ui/loader';
import Modal from '../../../../components/ui/modal';

type Props = {
  open: boolean;
  toggleOpen: Function;
  onSubmit: Function;
  editable: boolean;
  disabled: boolean;
  propertyType: string;
}

function ModalDelete({
  open,
  toggleOpen,
  editable,
  propertyType,
  disabled,
  onSubmit
}: Props) {
  const displayMsg = propertyType === 'APP' ? 'an App' : 'a Site';
  const header = editable
    ? `Delete ${displayMsg}`
    : `Not able to delete ${displayMsg}`;
  const description = editable
    ? `Are you sure you want to delete this ${propertyType.toLowerCase()}? This is irreversible.`
    : 'The property has pending updates and therefore cannot be removed.';

  return (
    <Modal
      title={header}
      onCancel={toggleOpen}
      open={open}
      closable={false}
      cancelText="CANCEL"
      okText="CONFIRM"
      okButtonProps={{
        datatestid: 'confirm_protect_a_site_modal_delete',
        disabled: disabled || !editable
      }}
      onOk={onSubmit}
      centered
    >
      <React.Suspense
        fallback={(
          <div style={{ position: 'relative', padding: '2em' }}>
            <Loader />
          </div>
        )}
      >
        <p>{description}</p>
      </React.Suspense>
    </Modal>
  );
}

export default ModalDelete;
