import React from "react";
import { Checkbox } from "antd/es";
import { checkbox_style } from "../constants";
import Styles from "./analytics.module.scss";

const DimensionMetric = ({ data, selectedValues, handleChange, type }) => {
  const sortedData = data.sort((a, b) => a.displayName.localeCompare(b.displayName));
  const groupedData = sortedData.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  let content = <></>;

  if (type === "dimension") {
    content = (
      <>
        {sortedData
          .filter(el => el.visible)
          .map(el => {
            return (
              <Checkbox
                key={el.name}
                style={{ ...checkbox_style }}
                onChange={handleChange}
                value={el.fieldName}
                checked={selectedValues.includes(el.fieldName)}
              >
                {el.displayName}
              </Checkbox>
            );
          })}
      </>
    );
  } else if (type === "metric") {
    content = (
      <>
        {Object.keys(groupedData).map(category => (
          <details key={category}>
            <summary>{category}</summary>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: '10px'
              }}
            >
              {groupedData[category].map(item =>
                item.visible ? (
                  <Checkbox
                    key={item.fieldName}
                    style={{ ...checkbox_style }}
                    onChange={handleChange}
                    value={item.fieldName}
                    checked={selectedValues.includes(item.fieldName)}
                  >
                    {item.displayName}
                  </Checkbox>
                ) : null
              )}
            </ul>
          </details>
        ))}
      </>
    );
  }

  return <div className={Styles.metrics_popover}>{content}</div>;
};

export default DimensionMetric;
