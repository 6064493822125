import React from 'react'

import commonStyles from './common.module.css'

function errorMessage(error) {
  return error.message || error.log || error.code;
}

function ConfirmSignUp({confirmSignUp, error, updateFormState}) {
  return (
    <>
      <div className={commonStyles.heading}>
        Confirm Your Email
      </div>
      {error && (<span className={commonStyles.error}>{errorMessage(error)}</span>)}
        <form className={commonStyles.formContainer} onSubmit={(e)=>{e.preventDefault(); confirmSignUp(); return false;}}>
          <input
            name='confirmationCode'
            placeholder='Confirmation Code'
            onChange={e => {e.persist(); updateFormState(e)}}
          />
          <button type='submit' className={commonStyles.primaryButton}>Confirm Sign Up</button>
      </form>
    </>
  )
}

export default ConfirmSignUp;