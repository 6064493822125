import './styles.scss';

import { Col, Row } from 'antd/es/grid';
// @flow
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Radio from 'antd/es/radio';
import Space from 'antd/es/space';
import Tooltip from 'antd/es/tooltip';
import classNames from 'classnames';
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-light';
import { useHistory } from 'react-router-dom';
import Select from '../../../../components/ui/select';
import { SUPPORTED_LANGUAGES } from '../../../../utils/language';
import GeneralSettings from '../general-settings';
import CustomizePurposes from '../../../apps/components/customize-purposes';
import CustomSettings from '../custom-settings';
import ContentHeader from '../../../../components/ui/content-header';
import Card from '../../../../components/ui/card';
import Alert from '../../../../components/ui/alert';
import {
  FEATURES_INFO,
  PURPOSES_INFO,
  REGULATION_MODES,
  SENSITIVE_PURPOSE_ID,
  SPECIAL_FEATURES_INFO,
  SPECIAL_PURPOSES_INFO,
  URL_REGEX
} from '../../../../utils/constants';
import { BACK_TO_FILTER_LABEL, PAGE_TITLE, encodingOptions } from '../../constants';
import ConsentScopeUSP from '../../../../components/forms/consent_scope_usp';
import MSPARegulationSettings from '../../../../components/forms/mspa_settings';
import SITETOOLTIPS from '../../../../utils/toolTipsMessages';
import InfoIconTooltip from '../../../../components/ui/infoIconTooltip';
import { getGBCPurposesDefaultData } from '../../../../components/forms/gbc/utils';
import { isGBCSupported, isGPPSupported } from '../../../../utils/property';

type Props = {
  propertyType: string;
  regulationType: String;
  selectedSites: number;
  fields: Record<any>;
  handleChange: Function;
  handleSubmit: Function;
  gbcPurposesData: Object;
};

function BulkUpdateForm({
  propertyType, regulationType, selectedSites,
  fields, handleChange, handleSubmit, gbcPurposesData
}: Props) {
  const { CardHeader, CardContent } = Card;
  const { Option } = Select;
  const history = useHistory();
  const [form] = Form.useForm();

  const { pCode } = useSelector(state => state.app);

  const [showCustomSettings, setShowCustomSettings] = useState(false);
  const [allowOverview, setAllowOverview] = useState(false);
  const [errors, setErrors] = useState({});

  const getInitData = (fieldKeys) => {
    const initData = {};
    fieldKeys.forEach((key) => {
      if (fields[key] !== undefined) {
        initData[key] = fields[key];
      }
    });
    return initData;
  };

  const INIT_DATA = {
    gbcApplicablePurposes: fields.gbcApplicablePurposes || getGBCPurposesDefaultData(gbcPurposesData),
    ...getInitData(['gbcEnabled', 'gbcLocations', 'isCoveredTransaction',
      'mspaJurisdiction', 'ccpaViaUsp', 'mspaPurposeIds', 'mspaSensitiveDataPurposeIds', 'mspaAutoPopUp'])
  };

  useEffect(() => {
    if (propertyType === '' || propertyType === undefined || selectedSites === 0) {
      history.push(`/protect/p-${pCode}/bulk-update`);
    }
  }, [propertyType, selectedSites]);

  const isMSPAIncludesRequiredPurposes = () => {
    if (!fields.mspaPurposeIds || fields.mspaPurposeIds.length === 0) return true;
    return [1, 2, 3, 4].some(id => fields.mspaPurposeIds.includes(id));
  };

  useEffect(() => {
    if (Object.keys(fields).length === 0
    || Object.keys(errors).length > 0 || !isMSPAIncludesRequiredPurposes()
    || (!fields.hasGDPR && !fields.hasCCPA && !fields.gbcEnabled)) {
      setAllowOverview(false);
    } else {
      setAllowOverview(true);
    }
  }, [fields]);

  useEffect(() => {
    let privacyModes = {};
    if (isGPPSupported(fields.cmpVersion) && fields.consentLocations !== undefined) {
      handleChange(['hasGDPR', 'hasCCPA', 'consentLocations'], {
        hasGDPR: true,
        hasCCPA: true,
        consentLocations: ['WORLDWIDE']
      });
    } else {
      switch (regulationType) {
        case REGULATION_MODES[0].value:
          privacyModes = {
            hasGDPR: true,
            hasCCPA: undefined,
            ccpaUS: undefined,
            uspLspact: undefined,
          };
          break;
        case REGULATION_MODES[1].value:
          privacyModes = {
            hasGDPR: undefined,
            hasCCPA: true,
            ccpaUS: undefined,
            uspLspact: undefined,
          };
          break;
        case REGULATION_MODES[2].value:
          privacyModes = {
            hasGDPR: true,
            hasCCPA: true,
            ccpaUS: undefined,
            uspLspact: undefined,
          };
          break;
        default:
          break;
      }
      handleChange(['hasGDPR', 'hasCCPA', 'ccpaUS', 'uspLspact'], privacyModes);
    }
  }, []);

  useEffect(() => {
    if (fields.legalBasis === 'applyRecommended') {
      handleChange('customizedFilters', undefined);
    }
  }, [fields.legalBasis]);

  const handleConsentScope = (value) => {
    const labelsToUpdate = [];
    let valuesToUpdate = {};
    if (value.includes('group')) {
      labelsToUpdate.push(...['consentScopeGroupURL', 'thirdPartyStorageType']);
      valuesToUpdate = { consentScopeGroupURL: '', thirdPartyStorageType: undefined };
    }
    labelsToUpdate.push('consentScope');
    valuesToUpdate = { ...valuesToUpdate, consentScope: value };
    handleChange(['consentScope'], value);
    handleChange(labelsToUpdate, valuesToUpdate);
  };

  const applyCustomizedFilters = (filters) => {
    if (filters) {
      const hasData = Object.keys(filters).some(key => filters[key].length > 0);
      if (hasData) {
        handleChange('customizedFilters', filters);
      } else {
        handleChange('customizedFilters', undefined);
      }
    }
    setShowCustomSettings(false);
  };

  const handleCustomizePurposes = (field, data) => {
    if (field === 'restrictions') {
      handleChange('publisherRestrictions', data);
    }
    if (field === 'stacks') {
      handleChange('stacks', data);
    }
  };

  const handleCancelCustomSetting = (isEmpty) => {
    setShowCustomSettings(false);
    if (isEmpty) {
      handleChange('legalBasis', 'applyRecommended');
    }
  };

  const buildUseInput = () => ({
    name: 'use',
    value: fields.usePurposesScreen,
    onChange: event => handleChange('usePurposesScreen', event.target.value),
    'data-testid': 'bulk-app-use-input'
  });

  const manageErrors = (field) => {
    const newErrors = { ...errors };
    delete newErrors[field];
    setErrors(newErrors);
  };

  const goToOverview = () => {
    const dataToSubmit = { ...fields };
    const formErrors = {};

    if (dataToSubmit.hasGDPR !== undefined || dataToSubmit.hasCCPA !== undefined) {
      const privacyModes = [];

      if (dataToSubmit.hasGDPR) {
        privacyModes.push('GDPR');
      }
      if (dataToSubmit.hasCCPA) {
        privacyModes.push('USP');
      }
      dataToSubmit.privacyModes = privacyModes;
    }

    if (!isGBCSupported(dataToSubmit.cmpVersion)) {
      delete dataToSubmit.gbcEnabled;
    }
    if (dataToSubmit.gbcEnabled) {
      if (dataToSubmit.gbcLocations) dataToSubmit.gbcLocations = [dataToSubmit.gbcLocations];
      else delete dataToSubmit.gbcLocations;

      const gbcPurposes = [];
      dataToSubmit.gbcApplicablePurposes.forEach((purpose) => {
        if (purpose.enabled) {
          gbcPurposes.push({ id: purpose.id, defaultValue: purpose.defaultValue });
        }
      });
      dataToSubmit.gbcApplicablePurposes = gbcPurposes;
    } else {
      delete dataToSubmit.gbcLocations;
      delete dataToSubmit.gbcApplicablePurposes;
    }

    if (dataToSubmit.hasCCPA === true && dataToSubmit.ccpaUS !== undefined) {
      if (dataToSubmit.ccpaUS) {
        dataToSubmit.uspJurisdiction = ['US'];
      } else {
        dataToSubmit.uspJurisdiction = ['CA'];
      }
    }

    if (dataToSubmit.hasCCPA === true && dataToSubmit.uspLspact !== undefined) {
      if (dataToSubmit.uspLspact) {
        dataToSubmit.uspLspact = 'Y';
      } else {
        dataToSubmit.uspLspact = 'N';
      }
    }

    if (!isGPPSupported(dataToSubmit.cmpVersion)) {
      delete dataToSubmit.gdprEncodingMode;
      delete dataToSubmit.isCoveredTransaction;
      delete dataToSubmit.mspaJurisdiction;
      delete dataToSubmit.ccpaViaUsp;
      delete dataToSubmit.mspaSensitiveDataPurposeIds;
      delete dataToSubmit.mspaAutoPopUp;
      delete dataToSubmit.consentLocations;
    }

    if (isGPPSupported(dataToSubmit.cmpVersion) && dataToSubmit.hasCCPA) {
      const purposeIds = dataToSubmit.mspaPurposeIds;
      if (purposeIds && purposeIds.length > 0) {
        dataToSubmit.mspaOptOutPurposeIds = purposeIds;

        if (!purposeIds.includes(SENSITIVE_PURPOSE_ID)) {
          delete dataToSubmit.mspaSensitiveDataPurposeIds;
        }
      } else {
        delete dataToSubmit.mspaSensitiveDataPurposeIds;
      }
    }

    if (dataToSubmit.hasGDPR === true) {
      if (dataToSubmit.legalBasis !== undefined) {
        if (dataToSubmit.legalBasis === 'applyRecommended') {
          dataToSubmit.noLegalBasis = true;
          dataToSubmit.specialFeatures = [];
          dataToSubmit.specialPurposes = [];
          dataToSubmit.features = [];
          dataToSubmit.purposeIds = [];
          dataToSubmit.purposeLegitimateIds = [];
        } else {
          dataToSubmit.noLegalBasis = false;
        }
      }

      if (
        (dataToSubmit.legalBasis === 'customized' || dataToSubmit.legalBasis === undefined)
        && dataToSubmit.customizedFilters !== undefined
      ) {
        dataToSubmit.specialFeatures = dataToSubmit.customizedFilters.specialFeatures;
        dataToSubmit.specialPurposes = dataToSubmit.customizedFilters.specialPurposes;
        dataToSubmit.features = dataToSubmit.customizedFilters.features;
        dataToSubmit.purposeIds = dataToSubmit.customizedFilters.purposesConsents;
        dataToSubmit.purposeLegitimateIds = dataToSubmit.customizedFilters.legitimateInterests;
      }

      if (dataToSubmit.consentScopeGroupURL === '') {
        delete dataToSubmit.consentScopeGroupURL;
      }

      if (
        'consentScopeGroupURL' in dataToSubmit
        && (dataToSubmit.consentScopeGroupURL !== ''
          || dataToSubmit.consentScopeGroupURL !== undefined)
          && !(URL_REGEX.test(dataToSubmit.consentScopeGroupURL))
      ) {
        formErrors.consentScopeGroupURL = 'Please input a valid URL';
      }

      delete dataToSubmit.customizedFilters;
    } else {
      delete dataToSubmit.customizedFilters;
      delete dataToSubmit.legalBasis;
      delete dataToSubmit.repromtDaysAfterGvlUpdate;
      delete dataToSubmit.thirdPartyStorageType;
      delete dataToSubmit.consentScopeGroupURL;
      delete dataToSubmit.consentScope;
      delete dataToSubmit.requireConsentFrom;
      delete dataToSubmit.gdprEncodingMode;
    }

    if (!dataToSubmit.hasCCPA) {
      delete dataToSubmit.uspLspact;
      delete dataToSubmit.uspJurisdiction;
      delete dataToSubmit.isCoveredTransaction;
      delete dataToSubmit.mspaJurisdiction;
      delete dataToSubmit.ccpaViaUsp;
      delete dataToSubmit.mspaSensitiveDataPurposeIds;
      delete dataToSubmit.consentLocations;
    }

    delete dataToSubmit.ccpaUS;
    delete dataToSubmit.hasCCPA;
    delete dataToSubmit.hasGDPR;
    delete dataToSubmit.mspaPurposeIds;
    // NOT SUPPORTED AT THIS TIME..
    if (propertyType === 'App') {
      if (fields.publisherRestrictions !== undefined) {
        dataToSubmit.publisherConsentRestrictionIds = fields.publisherRestrictions.publisherConsentRestrictionIds;
        dataToSubmit.publisherLIRestrictionIds = fields.publisherRestrictions.publisherLIRestrictionIds;
      }

      if (fields.stacks !== undefined) {
        dataToSubmit.stacks = fields.stacks;
      }

      if (
        fields.usePurposesScreen === 'purposes'
        && Object.prototype.hasOwnProperty.call(dataToSubmit, 'stacks')
      ) {
        delete dataToSubmit.stacks;
      }

      const links = [];
      const linkErrors = {};
      const TEXT_ERROR_MESSAGE = 'Link text is required';
      const URL_ERROR_MESSAGE = 'Link URL is not valid';

      if (dataToSubmit.uxInitialScreenCustomLinksText0 !== undefined
        || dataToSubmit.uxInitialScreenCustomLinksUrl0 !== undefined
      ) {
        if (
          dataToSubmit.uxInitialScreenCustomLinksText0 === ''
            || dataToSubmit.uxInitialScreenCustomLinksText0 === undefined
        ) {
          linkErrors['0'] = { text: TEXT_ERROR_MESSAGE };
        } else if (!URL_REGEX.test(dataToSubmit.uxInitialScreenCustomLinksUrl0)) {
          linkErrors['0'] = { url: URL_ERROR_MESSAGE };
        } else {
          links.push({
            text: dataToSubmit.uxInitialScreenCustomLinksText0,
            url: dataToSubmit.uxInitialScreenCustomLinksUrl0
          });
        }
      }

      if (dataToSubmit.uxInitialScreenCustomLinksText1 !== undefined
        || dataToSubmit.uxInitialScreenCustomLinksUrl1 !== undefined
      ) {
        if (
          dataToSubmit.uxInitialScreenCustomLinksText1 === ''
            || dataToSubmit.uxInitialScreenCustomLinksText1 === undefined
        ) {
          linkErrors['1'] = { text: TEXT_ERROR_MESSAGE };
        } else if (!URL_REGEX.test(dataToSubmit.uxInitialScreenCustomLinksUrl1)) {
          linkErrors['1'] = { url: URL_ERROR_MESSAGE };
        } else {
          links.push({
            text: dataToSubmit.uxInitialScreenCustomLinksText1,
            url: dataToSubmit.uxInitialScreenCustomLinksUrl1
          });
        }
      }

      if (dataToSubmit.uxInitialScreenCustomLinksText2 !== undefined
        || dataToSubmit.uxInitialScreenCustomLinksUrl2 !== undefined
      ) {
        if (
          dataToSubmit.uxInitialScreenCustomLinksText2 === ''
            || dataToSubmit.uxInitialScreenCustomLinksText2 === undefined
        ) {
          linkErrors['2'] = { text: TEXT_ERROR_MESSAGE };
        } else if (!URL_REGEX.test(dataToSubmit.uxInitialScreenCustomLinksUrl2)) {
          linkErrors['2'] = { url: URL_ERROR_MESSAGE };
        } else {
          links.push({
            text: dataToSubmit.uxInitialScreenCustomLinksText2,
            url: dataToSubmit.uxInitialScreenCustomLinksUrl2
          });
        }
      }

      if (Object.keys(linkErrors).length > 0) {
        formErrors.links = linkErrors;
      }

      if (links.length > 0) {
        const customLinks = links.map(link => (
          `[${link.text}](${link.url})`
        )).join();
        dataToSubmit.uxInitialScreenCustomLinks = customLinks;
      }
    }
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      Object.keys(dataToSubmit).forEach(key => (dataToSubmit[key] === undefined) && delete dataToSubmit[key]);
      handleSubmit(dataToSubmit);
    }
  };

  return (
    <>
      <ContentHeader
        title={PAGE_TITLE}
        backBtn={{
          goTo: `p-${pCode}/bulk-update`,
          text: BACK_TO_FILTER_LABEL
        }}
        actions={[
          {
            component: Button,
            props: {
              type: 'primary',
              disabled: !allowOverview,
              'data-testid': 'bulk_next_to_overview',
              onClick: goToOverview,
            },
            children: ['NEXT']
          }
        ]}
      />
      <Form layout="vertical" form={form} initialValues={INIT_DATA}>
        <div id="details-container" className="details-container">
          <Row gutter={[16, 16]}>
            <Col span="24">
              <Alert
                message="Step 2"
                description={
                  `Choose the bulk update(s) you wish to perform on the selected ${selectedSites} properties`}
                type="info"
                showIcon
              />
            </Col>
            <Col span="24">
              <GeneralSettings
                propertyType={propertyType}
                filteredRegulation={regulationType}
                fields={fields}
                handleChange={handleChange}
                gbcPurposesData={gbcPurposesData}
                form={form}
              />
            </Col>
            {(fields.hasGDPR) && (
              <Col span="24">
                <Card>
                  <CardHeader title="Consent scope">
                    <span className="small">What purposes should be notified to end-users</span>
                    <span className="regulation">GDPR</span>
                  </CardHeader>
                  <CardContent>
                    <Row gutter={[16, 16]}>
                      {!isGPPSupported(fields.cmpVersion) && (
                        <Col span="24">
                          <label htmlFor="requireConsentFrom">
                            WHICH USERS SHOULD BE ASKED FOR CONSENT?
                            <Tooltip title={(
                              <>
                                Publishers established within the EEA and the UK are required to apply GDPR
                                and ePrivacy Directive to any user (option &quot;Everyone&quot;) regardless
                                of the user&apos;s location.
                              </>
                            )}
                            >
                              <span>
                            &nbsp;
                                <FontAwesomeIcon icon={faQuestionCircle} />
                              </span>
                            </Tooltip>
                          </label>
                          <Form.Item>
                            <Select
                              allowClear
                              id="requireConsentFrom"
                              value={fields.requireConsentFrom}
                              onChange={value => handleChange('requireConsentFrom', value)}
                            >
                              <Option value="inEU">Only users in the EEA or UK</Option>
                              <Option value="always">Everyone</Option>
                              <Option value="never">Never</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      )}

                      {propertyType !== 'App' && (
                        <Col span="24">
                          <label htmlFor="consentScope">CONSENT SCOPE: ASK USER FOR CONSENT FOR...</label>
                          <Form.Item>
                            <Select
                              allowClear
                              id="consentScope"
                              value={fields.consentScope}
                              onChange={handleConsentScope}
                            >
                              <Option value="service">Your site only</Option>
                              <Option
                                disabled={propertyType === 'Site AMP'}
                                value="service group"
                              >
                                A group of your sites only
                              </Option>
                            </Select>
                          </Form.Item>
                          {fields.consentScope === 'service' && (
                            <p className="consent-scope-details">
                              Service-specific scope means a user’s preferences are applicable only to the publisher
                              property on which the preference was set.
                            </p>
                          )}
                          {fields.consentScope === 'service group' && (
                            <p className="consent-scope-details">
                              Group-specific scope means a user’s preference is applicable only to a pre-defined group
                              of publisher properties, which allows users to share a single set of preferences across
                              all the publisher properties of your group.
                            </p>
                          )}
                        </Col>
                      )}
                      {(propertyType === 'Site' || propertyType === 'Site & Subdomains')
                        && fields.consentScope && fields.consentScope.includes('group') && (
                        <>
                          <Col span="24">
                            <p>
                              <strong>NOTE:</strong>
                              &nbsp;By choosing a Group option, you must provide a Group Domain. This requires a
                              deployment of a JSON API to read/set cookies. See&nbsp;
                              <a
                                target="technical_impletation"
                                // eslint-disable-next-line max-len
                                href="https://help.quantcast.com/hc/en-us/articles/13422563777435-Sharing-Consent-Between-Multiple-Sites-"
                              >
                                Technical Implementation Guide.
                              </a>
                            </p>
                            <label htmlFor="consentScopeGroupURL">CONSENT SCOPE GROUP URL</label>
                            <Form.Item>
                              <Input
                                allowClear
                                id="consentScopeGroupURL"
                                value={fields.consentScopeGroupURL}
                                className={classNames({ 'field-on-error': errors.consentScopeGroupURL })}
                                onChange={(event) => {
                                  handleChange('consentScopeGroupURL', event.target.value);
                                  manageErrors('consentScopeGroupURL');
                                }}
                              />
                            </Form.Item>
                            {errors.consentScopeGroupURL && (
                              <div className="field-error" role="alert">{errors.consentScopeGroupURL}</div>
                            )}
                          </Col>
                          <Col span="24">
                            <label htmlFor="thirdPartyStorageType">STORAGE MODE</label>
                            <div className="align-itemsCenter">
                              <Form.Item>
                                <Radio.Group
                                  id="thirdPartyStorageType"
                                  value={fields.thirdPartyStorageType}
                                  onChange={event => handleChange('thirdPartyStorageType', event.target.value)}
                                >
                                  <Radio value="iframe">Iframe</Radio>
                                  <Radio value="api">API</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                            {
                              isGPPSupported(fields.cmpVersion) && (
                                <Alert
                                  type="warning"
                                  message={
                                      `Group consent will be applicable under both GDPR and U.S
                                      regulations.`
                                  }
                                  className="alert"
                                  showIcon
                                />
                              )
                            }
                          </Col>
                        </>
                      )}
                      <Col span="24">
                        <label htmlFor="repromtDaysAfterGvlUpdate">SHOW NEW VENDORS EVERY...</label>
                        <Form.Item>
                          <InputNumber
                            id="repromtDaysAfterGvlUpdate"
                            value={fields.repromtDaysAfterGvlUpdate}
                            min={6}
                            onChange={value => handleChange('repromtDaysAfterGvlUpdate', value)}
                          />
                          &nbsp;Days
                        </Form.Item>
                      </Col>
                      {
                        isGPPSupported(fields.cmpVersion) && (
                          <Col>
                            <Form.Item
                              label={(
                                <Space direction="vertical">
                                  <Space>
                                    <span>What is the string format for encoding GDPR consent?</span>
                                    <Tooltip
                                      title={SITETOOLTIPS.gdprEncodingMode}
                                      placement="top"
                                    >
                                      <span><InfoIconTooltip /></span>
                                    </Tooltip>
                                  </Space>
                                  <Alert
                                    type="info"
                                    message={`Leaving this field blank would set this to "Both" as
                                    default value for properties migrating from previous versions.`}
                                    className="alert"
                                  />
                                </Space>
                              )}
                            >
                              <Radio.Group
                                onChange={e => handleChange('gdprEncodingMode', e.target.value)}
                                value={fields.gdprEncodingMode}
                              >
                                {encodingOptions.map(option => <Radio value={option.value}>{option.label}</Radio>)}
                              </Radio.Group>
                              {
                                fields.gdprEncodingMode === 'TCF' && (
                                  <Alert
                                    type="warning"
                                    message={
                    `Some of your ad networks might not currently (or in future) be compliant with
                    TCF but instead support GPP, hence its recommended to select both GPP and TCF.`
                                    }
                                    className="alert"
                                    showIcon
                                  />
                                )
                              }
                              {
                                fields.gdprEncodingMode === 'GPP' && (
                                  <Alert
                                    type="warning"
                                    className="alert"
                                    message={
                    `Some of your ad networks might not be compliant with GPP yet, hence
                    its recommended to select both GPP and TCF`
                                    }
                                    showIcon
                                  />
                                )
                              }
                            </Form.Item>
                          </Col>
                        )
                      }
                    </Row>
                  </CardContent>
                </Card>
              </Col>
            )}
            {(fields.hasGDPR) && (
              <Col span="24">
                <Card>
                  <CardHeader title="Your consent configuration">
                    <span className="small">
                      {propertyType === 'App'
                        ? 'Collect consent for data you use'
                        : `Declare which legal basis you (the site host) operate under (require consent or
                        claim legitimate interest).`
                      }
                    </span>
                    <span className="regulation">GDPR</span>
                  </CardHeader>
                  <CardContent>
                    <Row gutter={[16, 16]}>
                      <Col span="24">
                        <Form.Item>
                          <Radio.Group
                            id="legalBasis"
                            value={fields.legalBasis}
                            onChange={(event) => {
                              handleChange('legalBasis', event.target.value);
                              if (event.target.value === 'customized') {
                                setShowCustomSettings(true);
                              }
                            }}
                          >
                            <Row gutter={[16, 16]}>
                              <Col span="24">
                                <Radio value="applyRecommended">
                                  {"I don't  declare a legal basis for my property"}
                                </Radio>
                              </Col>
                              <Col span="24">
                                <div className="align-items-center">
                                  <Radio
                                    value="customized"
                                  >
                                    I define my legal basis
                                  </Radio>
                                  <Button
                                    type="link"
                                    disabled={fields.legalBasis !== 'customized'}
                                    onClick={() => setShowCustomSettings(true)}
                                  >
                                    CUSTOMIZE SETTINGS
                                  </Button>
                                </div>
                              </Col>
                              {(fields.legalBasis === 'customized' && fields.customizedFilters) && (
                                <Col span="24">
                                  <p>Customised purposes configuration:</p>
                                  <ul>
                                    {
                                      fields.customizedFilters.purposesConsents.length > 0
                                        && fields.customizedFilters.purposesConsents.map(id => (
                                          <li key={id}>
                                            Purpose
                                            {' '}
                                            {id}
                                            {': '}
                                            {PURPOSES_INFO.find(purpose => purpose.id === id).name}
                                          </li>
                                        ))}
                                    {
                                      fields.customizedFilters.legitimateInterests.length > 0
                                        && fields.customizedFilters.legitimateInterests.map(id => (
                                          <li key={id}>
                                            Legitimate Purpose
                                            {' '}
                                            {id}
                                            {': '}
                                            {PURPOSES_INFO.find(purpose => purpose.id === id).name}
                                          </li>
                                        ))}
                                    {
                                      fields.customizedFilters.specialPurposes.length > 0
                                        && fields.customizedFilters.specialPurposes.map(id => (
                                          <li key={id}>
                                            Special Purpose
                                            {' '}
                                            {id}
                                            {': '}
                                            {SPECIAL_PURPOSES_INFO.find(purpose => purpose.id === id).name}
                                          </li>
                                        ))}
                                    {
                                      fields.customizedFilters.features.length > 0
                                        && fields.customizedFilters.features.map(id => (
                                          <li key={id}>
                                            Feature
                                            {' '}
                                            {id}
                                            {': '}
                                            {FEATURES_INFO.find(purpose => purpose.id === id).name}
                                          </li>
                                        ))}
                                    {
                                      fields.customizedFilters.specialFeatures.length > 0
                                        && fields.customizedFilters.specialFeatures.map(id => (
                                          <li key={id}>
                                            Special feature
                                            {' '}
                                            {id}
                                            {': '}
                                            {SPECIAL_FEATURES_INFO.find(purpose => purpose.id === id).name}
                                          </li>
                                        ))}
                                  </ul>
                                </Col>
                              )}
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Col>
            )}
            {(fields.hasCCPA && isGPPSupported(fields.cmpVersion)) && (
              <>
                <Col span="24">
                  <MSPARegulationSettings
                    setFieldsValue={form.setFieldsValue}
                    getFieldValue={form.getFieldValue}
                    handleChange={handleChange}
                    isBulkUpdate
                  />
                </Col>
                <Col>
                  <ConsentScopeUSP
                    setFieldsValue={form.setFieldsValue}
                    getFieldValue={form.getFieldValue}
                    validateFields={form.validateFields}
                    isBulkUpdate
                    handleChange={handleChange}
                  />
                </Col>
              </>
            )}
            {(propertyType === 'App' && fields.hasGDPR) && (
              <Col span="24">
                <CustomizePurposes
                  onChange={handleCustomizePurposes}
                  values={{ vendorConfig: { ...fields.publisherRestrictions } }}
                  initData={{
                    vendorConfig: {
                      publisherConsentRestrictionIds: [],
                      publisherLIRestrictionIds: [],
                      vendorFeaturesIds: [],
                      vendorPurposeIds: [],
                      vendorPurposeLegitimateInterestIds: [],
                      vendorSpecialFeaturesIds: [],
                      vendorSpecialPurposesIds: []
                    }
                  }}
                  buildInput={buildUseInput}
                  use={fields.usePurposesScreen}
                  userSelectedStack={fields.userSelectedStack}
                  stackIds={fields.stacks}
                  setUserSelectedStack={value => handleChange('userSelectedStack', value)}
                  pCode={pCode}
                  handleCustomization={() => { /* Not needed for this form */ }}
                />
              </Col>
            )}
            {(propertyType === 'App' && fields.hasGDPR) && (
              <Col span="24">
                <Card>
                  <CardHeader title="UI settings" />
                  <CardContent>
                    <Row gutter={[16, 16]}>
                      <Col span="24">
                        <label htmlFor="language">LANGUAGE</label>
                        <Form.Item>
                          <Select
                            allowClear
                            id="language"
                            value={fields.language}
                            onChange={value => handleChange('language', value)}
                          >
                            {SUPPORTED_LANGUAGES.map(
                              lang => <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span="24">
                        <Form.Item>
                          <Checkbox
                            indeterminate={fields.initScreenRejectButtonShowing === undefined}
                            id="initScreenRejectButtonShowing"
                            value={fields.initScreenRejectButtonShowing}
                            onChange={event => handleChange(
                              'initScreenRejectButtonShowing',
                              event.target.checked
                            )}
                            checked={fields.initScreenRejectButtonShowing}
                          >
                            SHOW DISAGREE BUTTON ON THE SUMMARY VIEW
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span="24">
                        <label htmlFor="initScreenRejectButton">DISAGREE BUTTON TEXT</label>
                        <Form.Item>
                          <Input
                            allowClear
                            id="initScreenRejectButton"
                            value={fields.initScreenRejectButton}
                            onChange={event => handleChange('initScreenRejectButton', event.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span="24">
                        <label htmlFor="uxInitialScreenCustomLinksText0">
                          CUSTOM FOOTER LINKS
                        </label>
                        <Row gutter={[16, 16]}>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksText0"
                                value={fields.uxInitialScreenCustomLinksText0}
                                placeholder="Link Text"
                                className={classNames(
                                  { 'field-on-error': errors.links && errors.links['0'] && errors.links['0'].text }
                                )}
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksText0', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['0']
                              && errors.links['0'].text && (
                              <div className="field-error" role="alert">{errors.links['0'].text}</div>
                            )}
                          </Col>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksUrl0"
                                value={fields.uxInitialScreenCustomLinksUrl0}
                                placeholder="Link URL"
                                className={
                                  classNames(
                                    { 'field-on-error': errors.links && errors.links['0'] && errors.links['0'].url }
                                  )
                                }
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksUrl0', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['0']
                              && errors.links['0'].url && (
                              <div className="field-error" role="alert">{errors.links['0'].url}</div>
                            )}
                          </Col>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksText1"
                                value={fields.uxInitialScreenCustomLinksText1}
                                placeholder="Link Text"
                                className={
                                  classNames(
                                    { 'field-on-error': errors.links && errors.links['1'] && errors.links['1'].text }
                                  )
                                }
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksText1', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['1']
                              && errors.links['1'].text && (
                              <div className="field-error" role="alert">{errors.links['1'].text}</div>
                            )}
                          </Col>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksUrl1"
                                value={fields.uxInitialScreenCustomLinksUrl1}
                                placeholder="Link URL"
                                className={
                                  classNames(
                                    { 'field-on-error': errors.links && errors.links['1'] && errors.links['1'].url }
                                  )
                                }
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksUrl1', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['1']
                              && errors.links['1'].url && (
                              <div className="field-error" role="alert">{errors.links['1'].url}</div>
                            )}
                          </Col>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksText2"
                                value={fields.uxInitialScreenCustomLinksText2}
                                placeholder="Link Text"
                                className={
                                  classNames(
                                    { 'field-on-error': errors.links && errors.links['2'] && errors.links['2'].text }
                                  )
                                }
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksText2', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['2']
                              && errors.links['2'].text && (
                              <div className="field-error" role="alert">{errors.links['2'].text}</div>
                            )}
                          </Col>
                          <Col span="12">
                            <Form.Item>
                              <Input
                                allowClear
                                id="uxInitialScreenCustomLinksUrl2"
                                value={fields.uxInitialScreenCustomLinksUrl2}
                                placeholder="Link URL"
                                className={
                                  classNames(
                                    { 'field-on-error': errors.links && errors.links['2'] && errors.links['2'].url }
                                  )
                                }
                                onChange={(event) => {
                                  handleChange('uxInitialScreenCustomLinksUrl2', event.target.value);
                                  manageErrors('links');
                                }}
                              />
                            </Form.Item>
                            {errors.links
                              && errors.links['2']
                              && errors.links['2'].url && (
                              <div className="field-error" role="alert">{errors.links['2'].url}</div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </Col>
            )}
          </Row>
        </div>
        <CustomSettings
          applyChanges={applyCustomizedFilters}
          cancelChanges={handleCancelCustomSetting}
          showModal={showCustomSettings}
          customizedFilters={fields.customizedFilters}
        />
      </Form>
    </>
  );
}

export default BulkUpdateForm;
