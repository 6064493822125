import { buildError } from '../../utils/reducer';
import { APPS } from '../actions';

const initState = {
  current: {},
  error: {
    status: 200,
    message: ''
  },
  list: [],
  pending: false,
  fetched: false
};

export function createApp(appData) {
  return {
    type: APPS.CREATE.FETCH,
    payload: { appData }
  };
}

export function editApp(appId, appData) {
  return {
    type: APPS.EDIT.FETCH,
    payload: { appId, appData }
  };
}

export function deleteApp(appId, pCode, packageId) {
  return {
    type: APPS.DELETE.FETCH,
    payload: { siteId: appId, pCode, siteUrl: packageId }
  };
}

export function cleanApp() {
  return {
    type: APPS.CREATE.CLEAN,
    payload: {}
  };
}

export function getById(id, pCode) {
  return {
    type: APPS.GET_BY_ID.FETCH,
    payload: { id, pCode }
  };
}

function appsReducer(state = initState, action) {
  switch (action.type) {
    case APPS.CREATE.PENDING:
    case APPS.EDIT.PENDING:
    case APPS.DELETE.PENDING:
    case APPS.GET_BY_ID.PENDING:
      return { ...state, pending: true, fetched: false, error: { status: 200, message: '' } };
    case APPS.CREATE.FULFILLED:
      return { ...state, list: [...state.list, action.payload], pending: false, fetched: true };
    case APPS.GET_BY_ID.FULFILLED:
      return { ...state, current: action.payload, pending: false, fetched: true };
    case APPS.DELETE.FULFILLED: {
      const newList = state.list.filter(item => item.siteId !== parseInt(action.payload.id, 10));
      return {
        ...state,
        list: newList,
        pending: false,
        fetched: true,
        useFilterData: false,
        current: { finished: true },
      };
    }
    case APPS.CREATE.REJECTED:
    case APPS.EDIT.REJECTED:
    case APPS.DELETE.REJECTED:
    case APPS.GET_BY_ID.REJECTED:
      return { ...state, pending: false, current: { finished: true }, error: buildError(action.payload) };
    case APPS.CREATE.CLEAN:
      return { ...state, current: {}, pending: false, fetched: false, error: { status: 200, message: '' } };
    case APPS.EDIT.FULFILLED: {
      const list = [...state.list].filter(item => item.siteId !== action.payload.siteId);
      list.unshift(action.payload.data);
      return { ...state, pending: false, list, current: {}, fetched: true };
    }
    case APPS.GET_BY_ID.CLEAN:
    case APPS.EDIT.CLEAN: {
      return { ...state, current: {} };
    }
    default:
      return state;
  }
}

export default appsReducer;
