/* eslint-disable import/prefer-default-export */
import { requestAnalytics } from '../../utils/requests';

const reportsUrlV2 = '/v2/metric/pcode';
const abTestingUrl = '/abTesting/pcode';

function getAnalyticsV2(pCode, pageSiteIds) {
  if (!pCode) {
    return Promise.resolve({ res: undefined });
  }
    return requestAnalytics
    .post(`${reportsUrlV2}/${pCode}/siteIds`, pageSiteIds)
      .then(res => ({ res }))
      .catch(err => ({ err }));
}

export function getByUrl([byUrl, pCode, pageSiteIds]) {
  return getAnalyticsV2(pCode, pageSiteIds);
}

export function getByApp([appId, pCode, pageSiteIds]) {
  return getAnalyticsV2(pCode, pageSiteIds);
}

export function getAbTestBySite([siteId, pCode]) {
  return requestAnalytics
    .get(`${abTestingUrl}/${pCode}/site/${siteId}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getAnalytics([pCode, pageSiteIds]) {
  return getAnalyticsV2(pCode, pageSiteIds);
}
