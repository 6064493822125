/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React from 'react';
import Button from 'antd/es/button';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import uid from 'uid';
import classNames from 'classnames';
import Modal from '../../../../components/ui/modal';
import './index.scss';

type Props = {
  audit: Object;
  OkAction: Function;
  CancelAction: Function;
  actions: Array<Object>;
};

const AuditLogModal = ({
  audit: { showAuditLogsSuccessModal, showAuditLogsFailModal, auditLogs, pendingMassDownload },
  OkAction,
  CancelAction, actions
}: Props) => {
  const [textCopied, setTextCopied] = React.useState(false);
  const [successLogs, setSuccessLogs] = React.useState(false);
  const [failedLogs, setFailedLogs] = React.useState(false);
  const [totalLogsCount, setTotalLogsCount] = React.useState();
  const copyInput = React.useRef(null);

  const getHostname = (log) => {
    const logUrl = new URL(log.url.includes('http') ? log.url : `https://${log.url}`);
    return logUrl.hostname;
  };

  const copyProps = () => {
    copyInput.current.value = failedLogs.map(log => getHostname(log)).join(', ');
    copyInput.current.select();
    document.execCommand('copy');
    setTextCopied(true);
  };

  const setLogs = () => {
    setTotalLogsCount(auditLogs.length);
    setSuccessLogs(auditLogs.filter(({ queryState, isDownloaded }) => queryState === 'SUCCEEDED' && !isDownloaded));
    setFailedLogs(auditLogs.filter(({ queryState }) => queryState === 'FAILED' || queryState === 'CANCELLED'));
  };

  const getCancelButton = () => {
    if (showAuditLogsSuccessModal) {
      return successLogs.length > 1 && (
        <Button
          key="confirm"
          onClick={CancelAction}
          loading={pendingMassDownload}
        >
          {'Download All'}
        </Button>
      );
    }
    return (
      <Button
        key="confirm"
        onClick={copyProps}
      >
        Copy Properties
      </Button>
    );
  };

  React.useEffect(() => {
    if (auditLogs.length !== totalLogsCount) {
      setTextCopied(false);
    }
  }, [showAuditLogsSuccessModal, showAuditLogsFailModal]);

  React.useEffect(() => {
    setLogs();
  }, [auditLogs]);

  return (
    <Modal
      open={showAuditLogsSuccessModal || showAuditLogsFailModal}
      title={`Audit Log(s) - ${showAuditLogsSuccessModal ? 'Completed' : 'Failed'}`}
      footer={[getCancelButton(), <Button type="primary" key="cancel" onClick={OkAction}>Ok</Button>]}
      closable={false}
    >
      <ul className={classNames('audit-logs-prop-list',
        { success: showAuditLogsSuccessModal })}
      >
        {
        showAuditLogsSuccessModal
        && successLogs.map((log) => {
          const download = () => actions.auditLogs.fetchDownload({
            queryId: log.queryId,
            logId: log.id,
            domain: getHostname(log),
            auditLogs: successLogs
          });
          return (
            <li key={uid()} onClick={download}>
              <span>{getHostname(log)}</span>
              <span>{log.isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}</span>
            </li>
          );
        })
        }
        {
          showAuditLogsFailModal && failedLogs.map(log => (
            <li key={uid()}>
              <span>{getHostname(log)}</span>
            </li>
          ))
        }
      </ul>
      <input className="audit-logs-copy-input" ref={copyInput} type="text" />
      <span className="audit-logs-copy-message">{textCopied && 'The properties names were copied!'}</span>
    </Modal>
  );
};

export default AuditLogModal;
